export default function (Router, Permissions) {
  Router.abstract('content');

  Router.vueIndex('content');

  Router.vueCrud('content', 'course', true);

  Router.vue('content.course.qa', '/:id?progressId', Permissions.content.read, 'content.course.edit', 'content.course');

  Router.vue('content.course.profiler', '/:id', Permissions.content.read, 'content.course.edit', 'content.course');

  Router.vueCrud('content', 'level', true);

  Router.vueCrud('content', 'chapter', true);

  Router.vueCrud('content', 'addendum', true);

  Router.vue('content.chapter.focus', '/:id?parent&nodeid', Permissions.content.read, 'content.chapter.list', 'content.chapter');

  Router.vueCrud('content', 'page', true);

  Router.vueCrud('content', 'approval', true);

  return Router;
}
