<template>
  <VDialog v-model="visible" width="500" @click:outside="cancel">
    <VCard>
      <VToolbar color="primary" dark>
        <VToolbarTitle>
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <div v-if="html" class="pa-5" v-html="html"></div>
      <div v-else class="pa-5">{{ text }}</div>
      <div class="px-5">
        <VTextField v-model="promptResponse" :label="placeholder" />
      </div>
      <VCardActions>
        <VSpacer xs9 />
        <VBtn
          color="warning"
          text
          @click.stop="
            submit({
              isSuccess: true,
              promptResponse,
            })
          "
        >
          {{ confirmText }}
        </VBtn>
        <VBtn color="readonly" text @click.stop="cancel">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Please Confirm' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Do you approve?' },
    placeholder: { type: String, default: '' },
    confirmText: { type: String, default: 'Yes' },
    cancelText: { type: String, default: 'No, Cancel' },
    callback: { type: Function, default: (s) => s },
  },
  data() {
    return {
      promptResponse: '',
    };
  },
  methods: {
    submit(res) {
      this.callback(res);
      this.visible = false;
      this.$destroy();
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped></style>
