export default {
  all: '*',
  root: 'root',
  user: {
    read: 'user.read',
    write: 'user.write',
  },
  settings: {
    read: 'settings.read',
    write: 'settings.write',
  },
  adminRole: {
    read: 'adminRole.read',
    write: 'adminRole.write',
  },
  report: {
    read: 'report.read',
    write: 'report.write',
  },
  company: {
    read: 'company.read',
    write: 'company.write',
  },
  store: {
    read: 'store.read',
    write: 'store.write',
  },
  content: {
    read: 'content.read',
    write: 'content.write',
    delete: 'content.delete',
  },
  learning: {
    read: 'content.read',
    write: 'content.write',
    delete: 'content.delete',
  },
  platform: {
    read: 'platform.read',
    write: 'platform.write',
    delete: 'platform.delete',
  },
  class: {
    read: 'class.read',
    write: 'class.write',
    delete: 'class.delete',
  },
  ops: {
    read: 'ops.read',
    write: 'ops.write',
    delete: 'ops.delete',
  },
  instructor: {
    read: 'instructor.read',
    write: 'instructor.write',
  },
};
