import ModelFactory from 'app/model/_factory';

/**
 * Override the default Abstract.search() function
 * Perform an intelligent search to match against full emails and phone numbers
 * If CX has pasted a full version of either, ask API for an anchored search
 * This allows copy/paste searches to be 10 times faster
 *
 * @param params
 */
class UserModel extends ModelFactory {
  constructor() {
    super({
      type: 'user',
      isAgnostic: true,
      defaultList: {
        highSchoolAddress: {},
      },
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      so: {
        def: 'username',
        keys: {
          _id: ['i', 'id'],
          progressId: ['pid', 'progressid'],
          username: ['e', 'email', 'username'],
          firstName: ['f', 'first', 'firstname'],
          lastName: ['l', 'last', 'lastname'],
          phone: ['p', 'phone'],
          driversLicense: ['dl', 'license'],
          addressCity: ['c', 'city'],
          addressState: ['s', 'state'],
          addressPostal: ['z', 'zip', 'postal'],
          parentEmail: ['pe', 'parentemail'],
          highSchool: ['h', 'school', 'highschool'],
          accountType: ['at', 'accounttype'],
          defCourseId: ['dci'],
          courseId: ['ci'],
          memberId: ['m', 'memberid'],
        },
        hints: {
          username:
            'A user search will search for the first characters in a username. To search for a username that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their email address.',
          firstName:
            'A user search will search for the first characters in a first name. To search for a first name that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their first name.',
          lastName:
            'A user search will search for the first characters in a last name. To search for a last name that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their last name.',
          phone:
            'A user search will search for the first characters in a phone number. To search for a phone number that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *7563 will ' +
            'return all users with "7563" anywhere in their phone number.',
          driversLicense:
            "A user search will search for the first characters in a driver's license. To search for a driver's license that " +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *7563 will ' +
            'return all users with "7563" anywhere in their driver\'s license.',
        },
        cols: {
          username: 'Email',
          firstName: 'First',
          lastName: 'Last',
          brandId: 'Brand',
          phone: 'Phone',
          courseKey: 'Course',
        },
      },
    });
  }

  search(params) {
    if (params.criteria.power) {
      const check = params.criteria.power;
      params.criteria.power = undefined;
      const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regPhone = /1?[( ./_-]{0,2}\d{3}[) ./_-]{0,2}\d{3}[) ./_-]?\d{4}/;
      if (regEmail.test(check)) {
        params.criteria.username = `^${check}`;
      } else if (regPhone.test(check)) {
        params.criteria.phone = `^${check.replace(/\D/g, '')}`;
      } else {
        params.criteria.username = check;
      }
    }
    return this.query(params);
  }

  searchProgress(query, limit) {
    return this.subquery('progress', null, { query, limit });
  }

  getDash(_id, itemsPerPageOnActionLog) {
    return this.subquery('dash', _id, { itemsPerPageOnActionLog });
  }

  getStudents(_id) {
    return this.subquery('students', _id);
  }

  getStatus(_id, progressId) {
    return this.subquery('status', _id, { progressId });
  }

  getReceipts(_id) {
    return this.subquery('receipts', _id);
  }

  getRefundEligibility(_id, progressObject) {
    return this.subquery('refund-eligible', _id, { progressObject });
  }

  productPurchase(_id, token, productIdList, couponCodeList, manualPriceOverrideList, requestAmount, isPriceValidationBypassed, brandId, adminPurchaseReason) {
    return this.execute('purchase', _id, { token, productIdList, couponCodeList, manualPriceOverrideList, requestAmount, isPriceValidationBypassed, brandId, adminPurchaseReason });
  }

  requestRefund(_id, refundProducts, refundDetails, receiptId) {
    return this.execute('refund', _id, { refundProducts, refundDetails, receiptId });
  }

  requestPassword(_id, username, brandId) {
    return this.subquery('request-password', _id, { username, brandId });
  }

  setPassword(_id, password) {
    return this.execute('set-password', _id, { password });
  }

  setDebug(_id, isDebug) {
    return this.execute('set-debug', _id, { isDebug });
  }

  viewPII(_id) {
    return this.execute('view-p-i-i', _id);
  }

  getProgressSumary(_id, progressId) {
    return this.subquery('get-summary', _id, { userId: _id, progressId });
  }

  setDefaultCourse(_id, userProgressId) {
    return this.execute('set-default-course', _id, { userProgressId });
  }

  addStudent(_id, username) {
    return this.execute('add-student', _id, {
      username,
    });
  }

  addUserSession(id) {
    return this.updateAction('add-user-session', id);
  }

  deleteUserSession(id, token) {
    return this.updateAction('delete-user-session', id, { token });
  }

  deleteAllUserSessions(id) {
    return this.updateAction('delete-all-user-sessions', id);
  }

  subscriptionUnenrollment(id, data) {
    return this.execute('unenrollment', id, { ...data });
  }

  flagUserForSubscriptionEnrollment(id, data) {
    return this.updateAction('flag-enrollment', id, data);
  }

  updateUserForSubscriptionEnrollment(id, data) {
    return this.updateAction('update-enrollment', id, data);
  }

  getSubscriptionPlans() {
    return this.subquery('plans');
  }

  removeStudent(_id, studentId) {
    return this.execute('remove-student', _id, {
      studentId,
    });
  }

  getPriceTotals(productIdList, couponCodeList) {
    return this.subquery('price', null, { productIdList, couponCodeList });
  }

  actionLog(id, skip = 0, itemsPerPage = undefined) {
    return this.subquery('action-log', id, { skip, itemsPerPage });
  }

  actionLogCount(id) {
    return this.subquery('action-log-count', id);
  }
}

export default new UserModel();
