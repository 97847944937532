<template>
  <VCard class="ace-card elevation-3" :data-test="qaCardTitle">
    <VCardTitle>
      <VLayout d-flex align-center>
        <VFlex d-flex align-center>
          <div v-if="!hideBrandIcon" class="d-flex align-center">
            <img class="brandLogo mr-2" :src="brandLogo" />
          </div>
          <label v-bind="labelProps" data-test="cardTitle">{{ title }}</label>
        </VFlex>
        <VFlex class="text-right">
          <slot name="card-actions"> </slot>
        </VFlex>
      </VLayout>
    </VCardTitle>
    <VLayout>
      <VFlex class="content">
        <slot name="card-content"></slot>
      </VFlex>
    </VLayout>
  </VCard>
</template>

<script lang="ts">
import BrandLib from 'app/lib/brand';

export default {
  name: 'AceCard',
  props: {
    title: { type: String, default: '' },
    hideBrandIcon: { type: Boolean, default: false },
    /** Optional props for card label element .*/
    labelProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    defBrandId() {
      return BrandLib.getCurrentTabBrandId();
    },
    brandLogo() {
      return `${import.meta.env.BASE_URL}${this.defBrandId.toLowerCase()}_logo.svg`;
    },
    qaCardTitle() {
      return `${this.title.replace(/ /g, '')}Card`;
    },
  },
};
</script>

<style lang="less" scoped>
.ace-card {
  position: relative;
  border-top: 3px solid var(--v-primary-base);

  .v-card__title {
    color: var(--v-textBright-base);
    font-size: 19px;
    font-weight: 700;
    padding: 0.5em 0.5em 0 1em;
  }
}

.content {
  min-height: 10px;
}

// AceCard Global Spacing
div.layout.row.wrap div.flex div.ace-card {
  margin: 15px 10px;
}

.brandLogo {
  height: 18px;
}
</style>
