<template>
  <VDialog v-model="visible" max-width="500" @click:outside="cancel">
    <VCard>
      <VToolbar dark color="primary">
        <VToolbarTitle data-test="classEventTimeTitle"> Bulk Adjust Time </VToolbarTitle>
        <VSpacer />
        <VToolbarItems>
          <VBtn icon dark @click.stop="cancel">
            <VIcon medium> fa-times </VIcon>
          </VBtn>
        </VToolbarItems>
      </VToolbar>

      <VContainer grid-list-lg fluid>
        <VForm ref="form" lazy-validation>
          <VLayout row fluid>
            <VFlex>
              <VAlert type="warning" class="ma-0" color="orange" icon="fa-light fa-triangle-exclamation">
                <span> Adjusting the start date/time will adjust all staff availability. Changes will not take effect until you Save.</span>
              </VAlert>
            </VFlex>
            <VFlex xs12>
              <Datepicker id="startDateTime" v-model="obj.startDateTime" inline-time time-selection label="Start Date/Time" @input="startDateChange"></Datepicker>
            </VFlex>
            <VFlex v-if="offset" xs12>
              <span>Will update all dates to "{{ offset }}" by {{ offsetMinutes }} minutes</span>
            </VFlex>
          </VLayout>

          <VLayout row>
            <VFlex>
              <VBtn color="warning" block @click="submit" :dark="isChanged()" :disabled="!isChanged()">
                <VIcon left> fa fa-copy </VIcon>
                Adjust All Times
              </VBtn>
            </VFlex>
          </VLayout>
        </VForm>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
import moment from 'moment';
import Datepicker from 'app/components/forms/datepicker.vue';
export default {
  name: 'ClassEventBulkAdjustTime',
  components: {
    Datepicker,
  },
  props: {
    visible: { type: Boolean, default: false },
    model: { type: Object, default: () => undefined },
    obj: { type: Object, default: () => undefined },
    callback: { type: Function, default: () => undefined },
  },
  data() {
    return {
      originalDateTime: this.obj.startDateTime,
      offset: '',
      offsetMinutes: 0,
    };
  },
  methods: {
    cancel() {
      this.$destroy();
    },
    isChanged() {
      return this.obj.startDateTime !== this.originalDateTime;
    },
    startDateChange() {
      // get difference between startDateTime and originalDateTime
      const newDate = moment(new Date(this.obj.startDateTime));
      this.offset = newDate.from(new Date(this.originalDateTime));
      this.offsetMinutes = newDate.diff(new Date(this.originalDateTime), 'minutes');
    },

    submit() {
      this.startDateChange();
      this.callback(this.offsetMinutes);
      this.cancel();
    },
  },
};
</script>
<style scoped lang="less">
.v-input--selection-controls {
  padding-top: 0;
}
.v-card {
  overflow: auto;
}
</style>
