import AceError from 'app/lib/aceError';
import Timeout from 'app/lib/timeout';
import axios from 'axios';
import { isArray } from 'lodash-es';

const EVENT = {
  start: 'aceApiStart',
  success: 'aceApiSuccess',
  fail: 'aceApiFail',
};

const REASON = {
  sessionTimeout: 'Session Timeout',
  notAuthenticated: 'Not Authenticated',
  notAuthorized: 'Not Authorized',
  invalidResponse: 'Invalid Response',
  notFound: 'Call Not Found',
  timeout: 'Timeout',
};

class Api {
  constructor() {
    this.isLoading = 0;
    this.error = null;
    this.optSession = null;
    this.baseUrl = '';
    this.dngCrtPr;
    this.dngRptPr;
    this.dngRptPr;
    this.authHeader = '';
    this.buildnum = 0;
    this.brandId = '';
  }

  init({ baseUrl = 'http://localhost:3032/api/', authHeader = 'UNITTEST' } = {}) {
    this.baseUrl = baseUrl;
    this.authHeader = authHeader;
  }

  /**
   * Allow for debug to move the api end point for further testing
   * Only works for Localhost, Sandbox, and Staging
   * Hijack occurs in app.js and on signin.js
   *
   * @param baseUrl
   * @param authHeader
   */
  hijack({ baseUrl, dngCrtPr, dngRptPr, dngPrtPr }) {
    this.baseUrl = baseUrl || this.baseUrl;
    this.dngCrtPr = dngCrtPr;
    this.dngRptPr = dngRptPr;
    this.dngPrtPr = dngPrtPr;
  }

  setBrandId(brandId = '') {
    this.brandId = brandId.toUpperCase().trim();
    return this;
  }

  setSession(thisSession) {
    this.optSession = thisSession;
  }

  get(type, _id, env) {
    if (env) {
      return this.call(`${type}/${_id}?env=${env}`, 'GET');
    }
    return this.call(`${type}/${_id}`, 'GET');
  }

  query(type, params) {
    return this.call(type, 'GET', params);
  }

  update(type, _id, data) {
    return this.call(`${type}/${_id}`, 'PUT', data);
  }

  updateAction(type, action, _id, data) {
    return this.call(`${type}/${_id ? `${_id}/` : ''}${action}`, 'PUT', data);
  }

  save(type, data) {
    return this.call(type, 'POST', data);
  }

  delete(type, _id) {
    return this.call(`${type}/${_id}`, 'DELETE');
  }

  /**
   * Perform a query on an API Object to return a subset
   * Assumes a GET and action
   *
   * @param type
   * @param action
   * @param _id
   * @param data
   * @returns {*}
   */
  subquery(type, action, _id, data) {
    return this.call(`${type}/${_id ? `${_id}/` : ''}${action}`, 'GET', data);
  }

  /**
   * Perform an action on an API Object
   * Assumes a POST and action
   *
   * @param type
   * @param action
   * @param _id
   * @param data
   * @returns {*}
   */
  execute(type, action, _id, data) {
    return this.call(`${type}/${_id ? `${_id}/` : ''}${action}`, 'POST', data);
  }

  count(type, params) {
    return this.call(`${type}/count`, 'GET', params);
  }

  print(type, _id, data) {
    return this.call(`${type}/${_id}/print`, 'PUT', data);
  }

  async call(url, method, data = {}, session = undefined, timeout = 90000) {
    this.isLoading += 1;
    const req = {
      method: method || 'GET',
      url: this.baseUrl + url,
      timeout,
    };

    Object.assign(data, {
      aceauth: this.authHeader,
      acesess: session || this.optSession || null,
    });

    if (req.method === 'GET') {
      req.params = {
        aceauth: this.authHeader,
        acesess: session || this.optSession || null,
        platform: 'JRV',
        buildnum: this.buildnum,
        brandId: this.brandId,
        dngCrtPr: this.dngCrtPr,
        dngRptPr: this.dngRptPr,
        dngPrtPr: this.dngPrtPr,
        _json: JSON.stringify(data),
      };
    } else {
      Object.assign(data, {
        aceauth: this.authHeader,
        acesess: session || this.optSession || null,
        platform: 'JRV',
        buildnum: this.buildnum,
        brandId: this.brandId,
        dngCrtPr: this.dngCrtPr,
        dngRptPr: this.dngRptPr,
        dngPrtPr: this.dngPrtPr,
      });
      req.data = data;
    }

    const self = this;
    let res;

    try {
      res = await axios(req);
    } catch (error) {
      res = error.response;
      // Smooth out the timer flickers in case of multiple api calls, let them settle down together
      Timeout.set(() => {
        self.isLoading -= 1;
      }, 100);

      if (!res?.status) {
        // API-CX could not be reached
        this.error = { ...res, reason: REASON.timeout };

        throw new AceError('Cannot Connect to Server', 404);
      } else {
        // Track specific API-CX failures to broadcast issues
        const code = Number(res.data ? res.data.errorList[0].code : 40000);
        const message = res.data ? res.data.errorList[0].message : 'Unknown Error';

        if ([40100, 40102, 40103].includes(code)) {
          // Specific failure reasons occurred on API side
          Object.assign(res, {
            message,
            reason:
              {
                40100: REASON.notAuthenticated,
                40102: REASON.sessionTimeout,
                40103: REASON.notAuthorized,
                40400: REASON.notFound,
              }[code] ?? 'Unknown Code ' + code,
          });
          this.error = {
            ...res,
          };
        } else {
          // API-CX returned a response status we do not handle
          this.error = { ...res, reason: REASON.invalidResponse };
        }
      }

      if (!res.data) {
        throw new AceError('Unknown Error', 500);
      }

      const errorList = isArray(res.data.errorList) ? res.data.errorList[0] : res.data.errorList;
      throw new AceError(errorList.message, errorList.code);
    }

    // Smooth out the timer flickers in case of multiple api calls, let them settle down together
    Timeout.set(() => {
      self.isLoading -= 1;
    }, 100);

    if (res.data.isSuccess) {
      return res.data.body;
    }

    // Invalid package response
    this.error = { ...res, reason: REASON.invalidResponse };
    throw new AceError('Invalid Server Response', 404);
  }
}

export default {
  EVENT,
  REASON,
  Service: new Api(),
};
