import ModelFactory from 'app/model/_factory';
import { get } from 'lodash-es';

class TestObjectiveModel extends ModelFactory {
  constructor() {
    super({
      type: 'testObjective',
      keys: {
        parent: 'test',
        child: 'question',
        childList: 'questionList',
      },
      isAgnostic: true,
      defaultList: {
        title: '',
        questionList: [],
        attemptCount: 0,
        drawnCount: 0,
        is: {
          proctored: false,
        },
        proctor: {
          type: 'DIGITAL',
        },
      },
      so: {
        def: 'title',
        keys: {
          title: ['t', 'title'],
          key: ['k', 'key'],
          _id: ['i', 'id'],
        },
        cols: {
          _id: 'ID',
          title: 'Title',
          attemptCount: '🔁',
          drawnCount: '🎟',
          questionCount: '❓',
        },
      },
    });
  }

  async query(params) {
    const res = await super.query(params);
    return (res || []).map((row) => ({ ...row, questionCount: get(row, 'questionList.length', 0) }));
  }

  async audit(testObjectiveId, questionList) {
    // questionList sent to check against the unsaved changes on the current testObjective
    return this.execute('audit', testObjectiveId, { questionList });
  }
}

export default new TestObjectiveModel();
