import ModelFactory from './_factory';

class PageModel extends ModelFactory {
  constructor() {
    super({
      type: 'page',
      keys: {
        parent: 'chapter',
        child: 'question',
        childList: 'questionList',
      },
      isAgnostic: true,
      defaultList: {
        key: '',
        title: 'New Page',
        template: 'SCREEN_TEMPLATE_4',
        html: '',
        markdown: '',
        imageUrl: '',
        image2xUrl: '',
        questionList: [],
        screenSeconds: 0,
        shareUrl: '',
        videoUrl: '',
        voiceOverUrl: '',
        fileUrl: '',
        isRequireVerify: false,
        tagList: [],
        note: '',
        voiceoverUrl: '',
        needVo: false,
        offsetWordCount: 0,
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          title: ['t', 'title'],
          html: ['b', 'body'],
          tagList: ['l', 'tags', 'taglist'],
          imageUrl: ['m', 'img', 'imageurl'],
          videoUrl: ['v', 'video', 'videourl'],
          subtitleUrl: ['u', 'subtitle', 'subtitleurl'],
          streamUrl: ['s', 'stream', 'streamurl'],
          voiceoverUrl: ['vo', 'voiceoverurl', 'voiceoverUrl'],
          isOrphaned: ['o', 'orphan'],
          courseIdList: ['c', 'courseid'],
        },
        defValues: {
          isOrphaned: '0',
        },
        cols: {
          title: 'Title',
          createdAt: 'Created At',
          markdown: 'Body',
        },
      },
    });
  }

  get templateList() {
    return {
      SCREEN_TEMPLATE_1: {
        title: 'Background',
      },
      SCREEN_TEMPLATE_2: {
        title: 'Right Image',
      },
      SCREEN_TEMPLATE_3: {
        title: 'Header',
      },
      SCREEN_TEMPLATE_4: {
        title: 'Full Text',
      },
      SCREEN_TEMPLATE_5: {
        title: 'Video',
      },
      SCREEN_TEMPLATE_AUDIO: {
        title: 'Audio',
      },
      SCREEN_TEMPLATE_6: {
        title: 'Clickable Image (Chart)',
      },
    };
  }
}

export default new PageModel();
