import { DESIGN_URL_PREFIX } from 'app/lib/mediaConfig';

export function updateFavicon(brandId = '', env = '') {
  const favicon = document.getElementById('favicon');
  const favenv = ['prd', 'gvt', 'stg'].includes(env) ? env : 'stg';
  if (favicon) {
    if (brandId) {
      favicon.href = `/favicons/${brandId.toLowerCase()}/${favenv}/favicon.ico`;
    } else {
      favicon.href = `${DESIGN_URL_PREFIX}jarvis/${favenv}.png`;
    }
  } else {
    favicon.href = '';
  }
}

export default { updateFavicon };
