<template>
  <VDialog v-model="visible" max-width="35%" @click:outside="cancel">
    <VCard>
      <VToolbar dark color="primary">
        <VToolbarTitle> Search like a boss </VToolbarTitle>
        <VSpacer />
        <VToolbarItems>
          <VBtn icon @click.stop="cancel">
            <VIcon>fa-times</VIcon>
          </VBtn>
        </VToolbarItems>
      </VToolbar>

      <VContainer grid-list-lg fluid>
        <VLayout row fluid>
          <VFlex>
            <div style="border: 5px">
              <p>Your searches are important to us. Here are some tips:</p>

              <ul>
                <li>All searches are partial, you do not need to complete wor<span style="opacity: 0.5"> ds </span>.</li>
                <li>Emails are searched by default. See prefixes for other fields.</li>
                <li>Use multiple prefixes to further filter searches.</li>
                <li>To search a phrase with spaces, wrap it in quotes (see "San Antonio").</li>
              </ul>

              <p>For example, to search San Antonio for a Charles or Chris Smith using Gmail:</p>

              <pre>c:"san antonio" l:smith f:ch gmail</pre>

              <p>See below for your complete list of prefixes. Oh, and happy searching!</p>
            </div>

            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th align="left">Search By</th>
                  <th align="left">Prefixes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prefixes, keyName) in list" :key="keyName">
                  <td>
                    {{ keyName }}
                    <aside v-if="getSoHint(keyName)" class="warning--text" v-html="getSoHint(keyName)"></aside>
                  </td>
                  <td width="33%">
                    <span v-for="(value, itemIdx) in prefixes" :key="itemIdx">{{ value }}:</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </VFlex>
        </VLayout>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
import { get } from 'lodash-es';

export default {
  name: 'SearchHelp',
  props: {
    visible: { type: Boolean, default: false },
    model: { type: Object, default: () => undefined },
  },
  data() {
    return {
      headers: [
        { text: 'Search By', value: 'search by', sortable: false },
        { text: 'Prefixes', value: 'prefixes', sortable: false },
      ],
    };
  },
  computed: {
    list() {
      return this.model.so.keys;
    },
  },
  methods: {
    getSoHint(key) {
      return get(this.model, `so.hints[${key}]`);
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>

<style lang="less" scoped>
.v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

table {
  width: 100%;
  tr {
    th,
    td {
      margin: 0;
      border-bottom: 1px solid #ddd;
      padding: 0.5em 1em 0.5em 0;
      vertical-align: top;

      span {
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }
}
</style>
