import ActivityCategory from './activity-category';
import Addendum from './addendum';
import Admin from './admin';
import AdminPermission from './admin-permission';
import AdminRole from './admin-role';
import Answerability from './answerability';
import Approval from './approval';
import Blankitivity from './blankitivity';
import Brand from './brand';
import Breadcrumb from './breadcrumb';
import Bucketivity from './bucketivity';
import Casestudy from './casestudy';
import Certificate from './certificate';
import CertificatesMonitor from './certificatesMonitor';
import Chapter from './chapter';
import ClassAttendance from './classAttendance';
import ClassDetail from './classDetail';
import ClassEvent from './classEvent';
import ClassLocation from './classLocation';
import ClassStaff from './classStaff';
import Company from './company';
import ControlCode from './controlCode';
import County from './county';
import CountyCourt from './county-court';
import Coupon from './coupon';
import Course from './course';
import CourseTest from './course-test';
import Credentials from './credentials';
import Datalog from './datalog';
import Email from './email';
import Exam from './exam';
import Journey from './journey';
import KeyTerm from './keyTerm';
import Level from './level';
import LevelTest from './level-test';
import Page from './page';
import Permissions from './permissions';
import PrintManifest from './printManifest';
import Product from './product';
import ProductLine from './productLine';
import ProductTag from './productTag';
import Progress from './progress';
import ProgressBlankitivity from './progress-blankitivity';
import ProgressCasestudy from './progress-casestudy';
import ProgressCertificate from './progress-certificate';
import ProgressClassEvent from './progress-class-event';
import ProgressCourse from './progress-course';
import ProgressTest from './progress-test';
import ProgressVerify from './progress-verify';
import Purchase from './purchase';
import Question from './question';
import Report from './report';
import StateReport from './stateReport';
import StateReportRecord from './stateReportRecord';
import Store from './store';
import StudentCertificate from './student-certificate';
import StudyTest from './study-test';
import Styleguide from './styleguide';
import Template from './template';
import Test from './test';
import TestObjective from './test-objective';
import User from './user';
import UserDocument from './userDocument';
import UserLegacy from './userLegacy';
import Util from './util';
import Voucher from './voucher';
import VoucherPartner from './voucherPartner';
import VoucherRequest from './voucherRequest';

export default {
  ActivityCategory,
  Addendum,
  Admin,
  AdminPermission,
  AdminRole,
  Answerability,
  Approval,
  Blankitivity,
  Brand,
  Breadcrumb,
  Bucketivity,
  Casestudy,
  Certificate,
  CertificatesMonitor,
  Chapter,
  ClassAttendance,
  ClassDetail,
  ClassEvent,
  ClassLocation,
  ClassStaff,
  Company,
  ControlCode,
  County,
  CountyCourt,
  Coupon,
  Course,
  CourseTest,
  Credentials,
  Datalog,
  Email,
  Exam,
  Journey,
  KeyTerm,
  Level,
  LevelTest,
  Page,
  Permissions,
  PrintManifest,
  Product,
  ProductLine,
  ProductTag,
  Progress,
  ProgressBlankitivity,
  ProgressCasestudy,
  ProgressCertificate,
  ProgressClassEvent,
  ProgressCourse,
  ProgressTest,
  ProgressVerify,
  Purchase,
  Question,
  Report,
  StateReport,
  StateReportRecord,
  Store,
  StudentCertificate,
  StudyTest,
  Styleguide,
  Template,
  Test,
  TestObjective,
  User,
  UserDocument,
  UserLegacy,
  Util,
  Voucher,
  VoucherPartner,
  VoucherRequest,
};
