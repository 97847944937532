export default {
  namespaced: true,
  state: {
    code: '',
  },
  mutations: {
    keyup(state, code) {
      state.code = code;
    },
  },
  getters: {
    keyup: (state) => state.code,
  },
};
