<template>
  <VOverlay opacity="0.1" :value="overlayValue">
    <VProgressCircular indeterminate :size="70" :color="progressColor" />
  </VOverlay>
</template>

<script lang="ts">
import Api from 'app/lib/api';

export default {
  name: 'Loading',
  props: {
    value: { type: Boolean, default: null },
    message: { type: String, default: 'Loading...' },
    progressColor: { type: String, default: 'primary' },
  },
  data() {
    return {
      Api,
    };
  },
  computed: {
    overlayValue() {
      if (this.value) {
        return !this.value;
      }
      return this.Api.Service.isLoading;
    },
  },
};
</script>

<style lang="less" scoped></style>
