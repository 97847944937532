import showdown from 'showdown';
import syllableLib from 'syllable';

const converter = new showdown.Converter({ tables: true, strikethrough: true, smoothLivePreview: false });

const Html = {
  converter,

  /**
   * If we don't have markdown, or markdown did not historically have the h1 header, load from a lossy html conversion
   */
  conditionallyLoadMarkdown({ markdown = '', html = '' }) {
    return markdown || Html.toMarkdown(html);
  },

  toMarkdown: (html = '') => {
    const mapObj = {
      '<p>': '',
      '</p>': '\n\n',
      '<strong>': '**',
      '</strong>': '**',
      '<del>': '~~',
      '</del>': '~~',
      '<b>': '**',
      '</b>': '**',
      '<em>': '*',
      '</em>': '*',
      '<i>': '*',
      '</i>': '*',
      '</ol>': '\n',
      '</ul>': '\n',
      '</table>': '\n',
      '<h1>': '#',
      '<h2>': '##',
      '<h3>': '###',
      '<h4>': '####',
      '<h5>': '#####',
      '</h1>': '\n',
      '</h2>': '\n',
      '</h3>': '\n',
      '</h4>': '\n',
      '</h5>': '\n',
      '<li>': '- ',
      '</li>': '\n',
      '</tr>': '\n',
      '<th>': '|',
      '</th>': '|',
      '<td>': '|',
      '</td>': '|',
    };

    let markdown = html;
    markdown = markdown.replace(/<h1><\/h1>/g, '');
    markdown = markdown.replace(/ id=".*?">/g, '>');
    markdown = markdown.replace(/<th><\/th>/g, '|');
    markdown = markdown.replace(/<td><\/td>/g, '|');
    markdown = markdown.replace(/<(br|p)> *- */g, '\n- ');
    markdown = markdown.replace(/<br> */g, '\n\n');
    markdown = markdown.replace(/(<\/?([a-z0-9]+)>)/gi, (match) => mapObj[match] || '');
    markdown = markdown.replace(/^\s+|\s+$/g, '');
    markdown = markdown.replace(/^-/gi, '- ');

    return markdown;
  },

  fromMarkdown: (markdown = '') => {
    let html = markdown;
    html = html.replace('¯\\', '¯\\\\\\');
    html = converter.makeHtml(html);
    html = html.replace('&amp;', '&');
    html = html.replace(/\n/g, '');
    html = html.replace(/ id="(.*?)"/gi, ' id="acemd-$1"');
    html = `<body>${html}</body>`;
    return html;
  },

  strip(htmlOrMarkdown = '') {
    // lists may or may not end in punctuation, and we need to add one
    return htmlOrMarkdown
      .replace(/([^.!?:;>])<\/(p|li|h1|h2|h3)>/g, '$1.') // deal with properly ending sentences
      .replace(/([^.!?:;>])$/g, '$1.')
      .replace(/<.*?>/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/&nbsp|&apos;/g, ' ');
  },

  getGradeLevel({ word, sentence, syllable }) {
    return word ? Math.round(10 * (0.39 * (word / sentence) + 11.8 * (syllable / word) - 15.59)) / 10 : 0;
  },

  getReadingEase({ word, sentence, syllable }) {
    return word ? Math.round(10 * (206.835 - 1.015 * (word / sentence) - 84.6 * (syllable / word))) / 10 : 0;
  },

  getCounts(htmlOrMarkdown = '') {
    const text = Html.strip(htmlOrMarkdown);
    const res = {
      text,
      char: text.length,
      word: (text.match(/\b[a-z0-9',.-]+\b/gi) || []).length,
      sentence: (text.match(/[.!?:;]+/g) || []).length,
      syllable: syllableLib(text),
    };

    // Flesch Kincaid Grade Level
    res.gradeLevel = Html.getGradeLevel(res);
    res.readingEase = Html.getReadingEase(res);
    return res;
  },

  getWordCountByHtml(html = '') {
    const { word } = Html.getCounts(html);
    return word;
  },

  toPlainText(html) {
    return html.replace(/<\/?[^>]+>/gi, ' ');
  },
};

export default Html;
