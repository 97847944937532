import ModelFactory from 'app/model/_factory';

class CouponModel extends ModelFactory {
  constructor() {
    const expireAt = new Date();
    expireAt.setFullYear(expireAt.getFullYear() + 100);

    super({
      type: 'coupon',
      isAgnostic: true,
      keys: {
        parent: '',
        child: 'Product',
        childList: 'productIdList',
      },
      defaultList: {
        code: '',
        offPercent: 0,
        expireAt: expireAt.toISOString(),
        startAt: new Date().toISOString(),
        description: '',
        discountType: 'PERCENT',
        stateTypeList: ['ALL'],
        courseTypeList: ['ALL'],
        appTypeList: ['DD', 'DE', 'RE', 'MTG', 'INS'],
        is: {
          _safe: true,
          hidden: false,
        },
        min: 1,
        max: 1,
        version: 2,
      },
      so: {
        def: 'code',
        keys: {
          _id: ['i', 'id'],
          code: ['c', 'code'],
          stateTypeList: ['s', 'state'],
          courseTypeList: ['o', 'course'],
          appTypeList: ['a', 'app'],
          productIdList: ['pid', 'productId'],
        },
        cols: {
          code: 'Code',
          expireAt: 'Expired At',
          offPercent: 'Discount',
          startAt: 'Starts At',
          'is.hidden': { header: 'Visibility', formatValue: (value) => (value ? 'Hidden' : 'Visible') },
        },
      },
    });
  }
}

export default new CouponModel();
