import ModelFactory from 'app/model/_factory';

class UtilModel extends ModelFactory {
  constructor() {
    super({
      type: 'util',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }

  flushCache(key) {
    return this.execute('flush', null, { key: key || 'COURSE' });
  }

  getLists(group) {
    return this.subquery('enum', null, { group });
  }

  getUpload(contentType) {
    return this.subquery('upload', null, { contentType });
  }
}

export default new UtilModel();
