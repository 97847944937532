import ModelFactory from 'app/model/_factory';

class CourseModel extends ModelFactory {
  constructor() {
    super({
      type: 'course',
      keys: {
        parent: '',
        child: 'level',
        childList: 'levelList',
      },
      isAgnostic: true,
      defaultList: {
        // Primary
        key: '',
        brandId: '',
        state: '',
        type: '',
        appTypeList: [],
        shortTitle: '',
        title: '',
        shortDescription: '',
        description: '',
        schoolInformation: 'Approved',
        value: 0,
        order: 1000,
        reasonAttendList: [],
        howFoundList: [],
        screenList: [],
        resourceList: [],
        assetTag: '',

        // Features
        isJourneyOn: false,
        isBookmark: false,
        isGlossary: false,
        isOpenBook: false,
        isParentTools: false,
        isRandomAccess: false,
        isRequireTermsAndConditions: false,
        isShowCourseInfo: false,
        minAndroid: 1,
        isReReporting: false,
        isNativeMobileRestricted: false,
        isAntiCheatDetection: false,
        version: new Date().getFullYear(),
        blockingCourseId: '',

        // Timers
        activeDailySeconds: 0,
        expirationTimeInDays: -1,
        expirationTriggerIdx: '',
        expirationPenalty: 'WARN',
        idleLogoutSeconds: 0,
        requiredBreakInMinutes: 0,

        // Certification
        isPermitShippingRequired: false,
        isRequireShipping: false,
        isCertEfiled: false,
        isCertAutomated: false,

        // Testing
        courseGradePercent: 0.7,
        levelGradePercent: 0.7,
        courseFailureResetCount: -1,
        levelFailureResetCount: -1,
        testMinutes: 0,
        lockMinutes: 0,
        questionCount: 0,
        bankCount: 1,
        permitTestLevelIndex: -1,
        permitTitle: '',
        isHideCorrectAnswer: false,
        showTestResult: 'YES',
        showCorrectAnswer: 'YES',
        showLevelTestResult: 'YES',
        showLevelCorrectAnswer: 'YES',
        isLockedOnFailure: false,
        testTemplateIdList: [],

        // Purchasing
        paywallLevel: -1,
        paywallChapter: -1,
        isStudyTestFree: false,
        appProductId: '',
        appStudyProductId: '',
        addProductTypeList: [],
        purchaseOptions: {
          voiceOver: 'OFF',
          studyTest: 'OFF',
          creditAssist: 'OFF',
          instructor: 'OFF',
        },

        // Verification
        verifyType: '',
        minAgeRequirementCount: 0,
        allowedVerificationFailureCount: -1,
        verifyEnrollMax: 0,
        verifyLockSeconds: 0,
        verifyTimeoutSeconds: 60,
        verifyQuestionCount: 20,
        isVerifyContract: false,
        isContractComplete: false,
        isVerifySignIn: false,
        isUserViewingRestrictedToConus: false,
        isVerifyParent: false,
        isContractParent: false,
        isBlockedCoursePointers: false,
        isBlockedOnVerificationFailure: false,
        affidavitUrl: '',
        contractUrl: '',
        contract: {
          type: 'SIGN',
        },
        helpCenterUrl: '',
        specsUrl: '',
        verifyPenaltyList: [],

        // Support
        supportPhone: '',
        supportEmail: 'support@aceable.com',
        supportAddress: '610 W 5TH ST #603, Austin, TX 78701',

        // Search
        context: '',
        tagList: [],
        vertical: '',
        status: '',

        // Activity Log
        isActivityLog: false,
        activityLogInformation: {
          activityCategoryIdList: [],
          instructor: {
            name: {
              isDisplayed: true,
              label: '',
            },
            license: {
              isDisplayed: true,
              label: '',
            },
          },
          pdfGenerationInfo: {
            instructions: '',
            isSupervisorInitialsDisplayed: true,
            isSupervisorDriversLicenseDisplayed: true,
          },
        },

        metadata: { nodeCount: 0, wordCount: 0, fixedEducationalSeconds: 0, updatedAt: null, goalSeconds: 0, isMetadataCourseTestExcluded: false, isMetadataLevelChapterTestExcluded: false },
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          title: ['t', 'title'],
          context: ['c', 'context'],
          tagList: ['l', 'tags', 'taglist'],
          vertical: ['v', 'vertical'],
          status: ['s', 'status'],
        },
        cols: {
          key: 'Key',
          shortTitle: 'Title',
        },
      },
    });
  }

  get appTypeList() {
    return {
      DD: 'DD',
      DE: 'DE',
      RE: 'RE',
      MTG: 'MTG',
      INS: 'INS',
      HC: 'HC',
    };
  }

  get verifyTypeList() {
    return [
      { text: 'None', value: '' },
      { text: 'Profile', value: 'PRFL' },
      { text: 'Voice', value: 'VOIC' },
      { text: 'Knowledge', value: 'KNLD' },
      { text: 'SMS Verification', value: 'PSMS' },
      { text: 'Biometrics', value: 'BIOM' },
      { text: 'Profile + Biometrics', value: 'PBIO' },
      { text: 'BioSig', value: 'BIOSIG' },
    ];
  }

  getEnum(criteria = {}) {
    return this.subquery('enum', null, criteria);
  }

  getProfileFields(_id) {
    return this.subquery('profiler-fields', _id, {});
  }

  clone(id, isBatch, key) {
    return this.execute('clone', id, { key, isBatch });
  }

  converge(id) {
    return this.execute('converge', id, {});
  }

  uploadCourse(courseId) {
    return this.execute('uploadCourse', courseId, {});
  }
  syncBrandfolder(id, tag) {
    return this.execute('brandfolder', id, { tag });
  }
}

export default new CourseModel();
