<template>
  <VCard class="content" data-test="preview">
    <div id="course" class="template-vue">
      <div v-if="!previewMobile" class="web">
        <div>
          <div>
            <div class="question-container">
              <div class="template-content">
                <h2 v-html="obj.question" />
              </div>
              <div class="layout-row layout-wrap">
                <div class="flex-60">
                  <div>
                    <div v-if="obj.template === 'QUESTION_TEMPLATE_4'" class="layout-row layout-wrap">
                      <div v-for="(row, index) in obj.choiceList" :key="index" class="flex-100">
                        <span class="question btn btn-block btn-default">
                          <i class="pull-left" /><span>{{ row.text }}</span>
                        </span>
                      </div>
                    </div>
                    <div v-if="obj.template === 'QUESTION_TEMPLATE_6'" class="layout-row layout-wrap">
                      <div v-for="(row, index) in obj.choiceList.slice(0, 3)" :key="index" class="flex-100">
                        <span class="question btn btn-block btn-default">
                          <i class="pull-left" /><span>{{ row.text }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <a target="_blank" class="template-bg-vue">
                    <img :src="formatImage(obj.imageUrl)" alt="chart" class="image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script lang="ts">
export default {
  name: 'LiveQuestion',

  props: {
    obj: { type: Object, default: () => undefined },
  },
  methods: {
    formatImage(imageUrl) {
      return imageUrl ? imageUrl.replace('http://', 'https://') : null;
    },
  },
};
</script>

<style lang="less" scoped>
.template-content {
  padding-bottom: 20px;
}
.question-container {
  padding: 30px 45px 0 45px;
}
.question {
  width: 100%;
  font-size: 1rem;
  font-family:
    'museolocal',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  background-color: var(--v-white-base) !important;
  border: 2px solid #d8e2e8 !important;
  font-weight: 500 !important;
  color: #333;
  cursor: pointer;
  margin-bottom: 1em !important;
  box-shadow: none !important;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e9f8fa !important;
  }
}
</style>
