import ModelFactory from 'app/model/_factory';
import Auth from 'app/lib/auth';
import Api from 'app/lib/api';

class StateReportRecordModel extends ModelFactory {
  constructor() {
    super({
      type: 'state-report-record',
      permType: 'stateReportRecord',
      isAgnostic: true,
      defaultList: {
        _id: '',
        brandId: '',
        reportId: '',
        startDate: '',
        endDate: '',
        adminName: '',
        data: [],
        isSuccess: false,
        note: '',
        name: '',
        _created_at: '',
        _updated_at: '',
        documents: [],
        sendByEmail: {
          _to: [],
          _cc: [],
          _bcc: [],
        },
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'name',
        keys: {
          name: ['n', 'name'],
        },
        cols: {
          name: 'Name',
        },
      },
    });
  }

  getRecordsNotVerified(reportId) {
    return this.query({ criteria: { reportId, verified: { $exists: false }, isSuccess: true, count: { $gt: 0 } }, sort: [['_created_at', -1]] });
  }

  async markAsVerified(record) {
    const jrvUser = Auth.get();
    await this.update(record._id, { verified: { _at: new Date(), _by: { _id: jrvUser._id, email: jrvUser.email } } });
  }

  async downloadFile(id) {
    const self = this;
    return Api.Service.call(`state-report-record/${id}/download/`, 'GET').then((res) => self.transform(res));
  }

  async sendByEmail(_id, sendByEmail) {
    const self = this;
    const pkg = this.wrapData(sendByEmail);
    // call(url, method, data = {}, session)
    return Api.Service.call(`state-report-record/${_id}/send-email`, 'PUT', pkg).then((res) => self.transform(res));
  }

  async executeReport(_id, data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.call(`state-report/${_id}/execute`, 'POST', pkg).then((res) => self.transform(res));
  }

  async getReportedByUserProgress(progressId) {
    const self = this;
    return Api.Service.call(`state-report/by-progress/${progressId}`, 'GET').then((res) => self.transform(res));
  }

  getDocumentsList(_id) {
    return this.query({ criteria: { _id } });
  }

  async uploadDoc(_id, tempKey, name, fileType, fileName) {
    //  const self = this;
    const data = { tempKey, name, fileType, fileName };
    const pkg = this.wrapData(data);
    const resp = await Api.Service.call(`state-report-record/${_id}/setupload`, 'POST', pkg);
    return resp.key;
  }

  async downloadDoc(idRec, idDoc) {
    const self = this;
    return Api.Service.call(`state-report-record/${idRec}/download/${idDoc}/document`, 'GET').then((res) => self.transform(res));
  }

  async removeDocument(idRec, idDoc) {
    const self = this;
    return Api.Service.call(`state-report-record/${idRec}/remove/${idDoc}/document`, 'GET').then((res) => self.transform(res));
  }
}
export default new StateReportRecordModel();
