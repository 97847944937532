import ModelFactory from 'app/model/_factory';

/**
 * @deprecated
 */
class CourseTestModel extends ModelFactory {
  constructor() {
    super({
      type: 'course-test',
      permType: 'courseTest',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }

  getListByUser(userId) {
    return this.query({ userId });
  }

  getListByUserProgress(userProgressId) {
    return this.query({ userProgressId });
  }

  override(userId, courseTestId) {
    return this.execute('override', null, { userId, courseTestId });
  }
}

export default new CourseTestModel();
