import ModelFactory from 'app/model/_factory';

class DatalogModel extends ModelFactory {
  constructor() {
    super({
      type: 'datalog',
      isAgnostic: true,
      defaultList: {},
      so: {
        def: 'adminId',
        keys: {
          _id: ['i', 'id'],
          adminId: ['aid', 'adminId'],
          docId: ['did', 'docId'],
          adminName: ['a', 'admin'],
          collection: ['c', 'collection'],
          type: ['t', 'type'],
        },
        cols: {
          adminName: 'Admin',
          collection: 'Collection',
          createdAt: 'Date',
        },
      },
    });
  }
}

export default new DatalogModel();
