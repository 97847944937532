import ModelFactory from 'app/model/_factory';

class ControlCodeModel extends ModelFactory {
  constructor() {
    super({
      type: 'control-code',
      permType: 'controlCode',
      isAgnostic: true,
      defaultList: {
        _id: '',
        courseType: '',
        state: '',
        currentControlCode: 0,
        maximumControlCode: 0,
        availableCodes: 0,
        availableCodesMin: 0,
        unusedSetsAvailable: 0,
        threshold: 0,
        courseNames: [],
        controlCodeSetList: [],
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: '_id',
        keys: {
          _id: '_id',
        },
      },
    });
  }
}
export default new ControlCodeModel();
