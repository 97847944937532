import ModelFactory from 'app/model/_factory';

class ProductModel extends ModelFactory {
  constructor() {
    super({
      type: 'product',
      keys: {
        child: 'Product',
        childList: 'productList',
      },
      isAgnostic: true,
      defaultList: {
        asset: {},
        type: 'UPSELL',
        option: '',
        key: '',
        pricing: {
          min: 0,
          current: 25,
          max: 100,
        },
        content: {
          title: '',
          description: '',
          disclaimer: '',
          parentInfo: '',
          prereq: '',
        },
        variantList: [],
        platform: {
          // Do not default mobile values on creation, require someone enter them
          // ios: 1,
          // android: 1,
          web: 1,
        },
        productList: [],
        infoList: [],
        order: 0,
        filter: {
          state: 'FL',
          course: 'DE',
          app: 'DE',
        },
        is: {
          active: true,
          upsell: true,
          shipped: false,
          inApp: false,
          free: false,
        },
        transferProductId: '',
        certificateId: '',
        deliveryType: '',
        subscription: {
          planId: '',
          program: '',
          vendor: '',
          billingDelayDays: -1,
        },
        tagList: [],
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          type: ['t', 'type'],
          'filter.app': ['a', 'app'],
          'filter.state': ['s', 'state'],
          'filter.type': ['c', 'course'],
          'content.title': ['l', 'title'],
          'is.active': ['ia', 'active'],
        },
        cols: {
          key: 'Key',
          type: 'Type',
          filter: { header: 'Course', formatValue: (v) => `${v.app}.${v.state}.${v.course}` },
          'content.title': 'Title',
          'is.active': 'Active?',
        },
      },
    });
  }
}

export default new ProductModel();
