import Api from 'app/lib/api';
import ModelFactory from 'app/model/_factory';

class TestModel extends ModelFactory {
  constructor() {
    super({
      type: 'test',
      isAgnostic: true,
      keys: {
        parent: 'course',
        child: 'testObjective',
        childList: 'objectiveList',
      },
      defaultList: {
        title: '',
        testObjectiveList: [],
        is: {
          _safe: true,
          seqeuential: false,
          proctored: false,
          submitOnTimeout: false,
          template: false,
        },
        resetType: 'NONE',
        resetToId: '',
        proctor: {
          setupUrl: '', // deprecate when V4 tests are all moved to V5
          infoUrl: '', // deprecate when V4 tests are all moved to V5
          password: '', // deprecate when V4 tests are all moved to V5
          provider: '',
          campaignId: '', // deprecate when V4 tests are all moved to V5
          providerDataList: [],
        },
        deadlineMinutes: 0,
        timeoutMinutes: 0,
        postTestLockDelaySeconds: 0,
        startDelaySeconds: 0,
        lockMinutes: 0,
        verifyMatrix: [],
        penaltyList: [],
        htmlDict: {
          fail: '',
          pass: '',
          finalFail: '',
        },
      },
      so: {
        def: 'title',
        keys: {
          title: ['t', 'title'],
          key: ['k', 'key'],
          _id: ['i', 'id'],
        },
        cols: {
          title: 'Title',
          key: 'Key',
          mode: 'Mode',
        },
      },
    });
  }

  async audit(_id) {
    return this.subquery('audit', _id);
  }

  createExamityTest({ testId, examMinutes, examLevel, allowedAttemptCount, examName, xgritCourseId, type, examUrl, examPassword }) {
    const self = this;
    return Api.Service.call(`examity/create-test`, 'POST', {
      xgritCourseId,
      testId,
      examMinutes,
      examLevel,
      examName,
      allowedAttemptCount,
      type,
      examUrl,
      examPassword,
    }).then((res) => self.transform(res));
  }

  createExamityCourse({ xgritCourseId, xgritCourseName, primaryInstructorId, type }) {
    const self = this;
    return Api.Service.call(`examity/create-course`, 'POST', {
      xgritCourseId,
      xgritCourseName,
      primaryInstructorId,
      type,
    }).then((res) => self.transform(res));
  }

  updateExamity(testId, document) {
    const self = this;
    return Api.Service.call(`examity/update-test`, 'PUT', {
      testId,
      document,
    }).then((res) => self.transform(res));
  }
}

export default new TestModel();
