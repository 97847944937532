<template>
  <VDialog v-model="visible" width="960" @click:outside="cancel">
    <VCard>
      <VToolbar color="warning" dark>
        <VToolbarTitle>
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <VBtn v-if="data.program === 'AARP' && data.isActive && !isEditMode" text class="float-right primary--text" @click="edit">
        <VIcon medium> fa fa-pencil-square </VIcon>
        Edit
      </VBtn>
      <div v-if="isEditMode && data.program === 'AARP'">
        <br />
        <VTextField id="memberId" v-model="memberId" data-test="memberId" label="Member ID" class="pa-5" />
        <div class="pa-5">
          <input id="isVerified" v-model="isVerified" type="checkbox" :value="isVerified" :checked="isVerified" />
          <label for="isVerified"> Verified </label>
        </div>
      </div>
      <div v-else-if="data.program === 'AARP'" class="pa-5">
        <table width="100%">
          <tr>
            <td>Member ID</td>
            <td>{{ data.vendorData.memberId || '-' }}</td>
          </tr>
          <tr>
            <td>Verified</td>
            <td>{{ !!data.vendorData.isVerified }}</td>
          </tr>
        </table>
      </div>
      <div v-else>
        <div v-if="html" class="subscribe-html pa-5" v-html="html"></div>
        <div v-else class="pa-5">{{ text }}</div>
      </div>
      <VCardActions v-if="isEditMode && data.program === 'AARP'">
        <VSpacer xs9 />
        <VBtn color="warning" text data-test="confirmButton" @click="save"> Save </VBtn>
        <VBtn color="readonly" text @click="cancelSave"> Cancel </VBtn>
      </VCardActions>
      <VCardActions v-else>
        <VSpacer xs9 />
        <VBtn data-test="acknowledgeButon" color="warning" text @click.stop="cancel">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Alert' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Alert!' },
    cancelText: { type: String, default: 'Understood' },
    data: { type: Object, default: () => undefined },
    isEditMode: { type: Boolean, default: false },
    callback: { type: Function, default: () => undefined },
    memberId: { type: String, default: '' },
    isVerified: { type: Boolean, default: false },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
    edit() {
      this.memberId = this.data.vendorData.memberId;
      this.isVerified = this.data.vendorData.isVerified;
      this.isEditMode = true;
    },
    save() {
      this.data.vendorData.memberId = this.memberId;
      this.data.vendorData.isVerified = this.isVerified;
      this.callback({ memberId: this.memberId, isVerified: this.isVerified });
      this.isEditMode = false;
    },
    cancelSave() {
      this.memberId = this.data.vendorData.memberId;
      this.isVerified = this.data.vendorData.isVerified;
      this.isEditMode = false;
    },
  },
};
</script>
<style lang="less" scoped>
.subscribe-html ::v-deep(table) {
  width: 100%;
  th,
  td {
    padding: 0.25rem 1rem;
    text-align: left;

    &.sub-data {
      font-family: monospace;
    }
  }
}
</style>
