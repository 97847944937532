import ModelFactory from 'app/model/_factory';

class CountyModel extends ModelFactory {
  constructor() {
    super({
      type: 'county',
      isAgnostic: true,
      defaultList: {
        isActive: true,
      },
      so: {},
    });
  }
}

export default new CountyModel();
