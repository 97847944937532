<template>
  <AceCard :id="'component-breadcrumbs-' + obj._type" title="Breadcrumbs">
    <div slot="card-content">
      <VContainer class="breadcrumbs-container">
        <table>
          <tr v-for="item in bcObjects" :key="item.id">
            <!--course-->
            <td v-if="item.courseId">
              <VBtn text exact color="primary" :to="goto(item, 'course')" data-test="breadcrumbCourseKey">
                {{ item.courseKey }}
              </VBtn>
            </td>
            <td v-else class="inactive">
              <VBtn text exact disabled>Orphan</VBtn>
            </td>
            <!--level-->
            <td v-if="item.levelId">
              <VBtn text exact color="primary" :to="goto(item, 'level')" data-test="breadcrumbLevelKey">
                {{ item.levelKey }}
              </VBtn>
            </td>
            <td v-else-if="!item.testId" class="inactive">
              <!-- Tests don't require levels, but everything else will be an orphan without a level -->
              <VBtn text exact disabled>Orphan</VBtn>
            </td>
            <td v-else></td>
            <!--chapter-->
            <td v-if="item.chapterKey">
              <VBtn text exact color="primary" :to="goto(item, 'chapter')" data-test="breadcrumbChapterKey">
                {{ item.chapterKey }}
              </VBtn>
            </td>
            <td v-else></td>
            <!--node-->
            <td v-if="item.nodeKey">
              <VBtn text exact color="primary" :to="goto(item, 'node')" data-test="breadcrumbNodeKey">
                {{ item.nodeKey }}
              </VBtn>
            </td>
            <td v-else></td>
            <!--test-->
            <td v-if="item.testKey">
              <VBtn text exact color="primary" :to="goto(item, 'test')" data-test="breadcrumbTestKey">
                {{ item.testKey }}
              </VBtn>
            </td>
            <td v-else></td>
            <!--testObjective-->
            <td v-if="item.objectiveKey">
              <VBtn text exact color="primary" :to="goto(item, 'objective')" data-test="breadcrumbObjectiveKey">
                {{ item.objectiveKey }}
              </VBtn>
            </td>
            <td v-else></td>
            <!--question-->
            <td v-if="item.questionKey">
              <VBtn text exact color="primary" :to="goto(item, 'question')" data-test="breadcrumbQuestionKey">
                {{ item.questionKey }}
              </VBtn>
            </td>
            <td v-else></td>
          </tr>
        </table>
      </VContainer>
    </div>

    <div slot="card-actions"><Wikilink link="4170252291/Breadcrumbs" /></div>
  </AceCard>
</template>

<script lang="ts">
import AceCard from 'app/components/layout/ace-card.vue';
import Nav from 'app/lib/nav';
import Router from 'app/lib/router';
import Util from 'app/lib/util';
import BreadcrumbModel from 'app/model/breadcrumb';
import Wikilink from 'app/components/wikilink.vue';

export default {
  components: {
    AceCard,
    Wikilink,
  },

  props: {
    obj: { type: Object, default: () => undefined },
  },

  data() {
    return {
      bcList: {},
      keySplits: [],
      bcObjects: [],
      type: undefined,
    };
  },

  async mounted() {
    if (Router.state.params.id !== '-1') {
      this.type = this.obj._type === 'testObjective' ? 'objective' : this.obj._type;
      let mode;
      const route = Router.state.current.name;
      // added exclude for chapter, since breadcrumbs will appear on focus mode for chapters
      if (route.includes('focus') && this.obj._type !== 'chapter') {
        mode = 'NODE';
      } else {
        mode = (this.type || '').toUpperCase();
      }
      const res = await BreadcrumbModel.realtime({ linkId: this.obj._id, mode });
      this.organizeBreadcrumbsInfo(res);
      const courseIds = [];
      res.forEach((breadcrumb) => {
        if (breadcrumb.courseId) courseIds.push(breadcrumb.courseId);
      });
      if (courseIds.length) this.$emit('set-course-ids', courseIds);
    }
  },

  methods: {
    organizeBreadcrumbsInfo(res) {
      this.bcList = res;
      // split up the key(s) for breadcrumb display
      this.bcObjects = res.map((thisRes) => {
        // store result in a subarray within keySplits array
        this.keySplits.push(thisRes.key.split('.'));
        const keySplits = thisRes.key.split('.');
        let levelKey;
        let chapterKey;
        let nodeKey;
        let testKey;
        let objectiveKey;
        let questionKey;
        for (let k = 0; k < keySplits.length; k += 1) {
          if (thisRes.levelId) {
            levelKey = `L${thisRes.levelIndex + 1}`;
          }
          if (thisRes.chapterId) {
            chapterKey = `C${thisRes.chapterIndex + 1}`;
          }
          if (thisRes.nodeId) {
            nodeKey = `N${thisRes.nodeIndex + 1}`;
          }
          if (thisRes.testId) {
            testKey = thisRes.testType === 'T' ? 'EXAM' : `${Util.getOrdinal(thisRes.testType[1])} ${thisRes.testType[2] === 'F' ? 'Free' : 'Upsell'}`;
          }
          if (thisRes.objectiveId) {
            objectiveKey = `O${thisRes.objectiveIndex + 1}`;
          }
          if (thisRes.questionId) {
            questionKey = `Q${thisRes.questionIndex + 1}`;
          }
        }

        return {
          _id: thisRes._id,
          type: thisRes.type,
          courseKey: thisRes.courseKey,
          levelKey,
          chapterKey,
          nodeKey,
          testKey,
          objectiveKey,
          questionKey,
          courseId: thisRes.courseId,
          levelId: thisRes.levelId,
          chapterId: thisRes.chapterId,
          nodeId: thisRes.nodeId,
          testId: thisRes.testId,
          objectiveId: thisRes.objectiveId,
          questionId: thisRes.questionId,
        };
      });
    },

    goto(item, type) {
      let breadcrumbParent = null;
      let breadcrumbGrandparent = null;
      let nodeId = null;
      let parentType = '';
      // depending on parameter 'type', grab that type's parent's id from current breadcrumb
      switch (type) {
        case 'level':
          breadcrumbParent = item.courseId || '';
          parentType = item.courseId ? 'course' : '';
          break;
        case 'chapter':
          breadcrumbParent = item.levelId ? `${item.levelId}.${item.courseId}` : '';
          parentType = item.levelId ? 'level.course' : '';
          break;
        case 'page':
          breadcrumbParent = item.chapterId || '';
          parentType = item.chapterId ? 'focus' : '';
          break;
        case 'objective':
          breadcrumbParent = item.testId;
          parentType = 'test';
          break;
        case 'test':
          breadcrumbParent = item.levelKey ? item.levelId : item.courseId;
          parentType = item.levelKey ? 'level' : 'course';
          break;
        // Questions can also be nodes, but this is specifically handled by logic above, so it will be clear which is which
        case 'question':
          breadcrumbParent = item.objectiveId;
          parentType = item.objectiveKey ? 'testObjective' : 'chapter';
          break;
        case 'node':
          breadcrumbParent = item.chapterId || '';
          breadcrumbGrandparent = `${item.levelId}.${item.courseId}`;
          parentType = 'level.course';
          nodeId = item.nodeId;
          break;
        default:
          breadcrumbParent = null;
      }
      const base = Nav.getRouteBase(type);
      const typeId = `${type === 'testObjective' ? 'objective' : type}Id`;

      // then send that parent id and the index into the $state.go() call
      let name;
      let params;
      let queryObj;
      if (type === 'chapter') {
        name = 'content.chapter.focus';
        params = { id: item[typeId] };
        queryObj = { parent: breadcrumbParent, parenttype: parentType };
      } else if (type === 'node') {
        name = 'content.chapter.focus';
        params = { id: breadcrumbParent };
        queryObj = { parent: breadcrumbGrandparent, parenttype: parentType, nodeid: nodeId };
      } else if (type === 'test') {
        name = 'learning.test.edit';
        params = { id: item[typeId] };
        queryObj = { parent: breadcrumbParent, parenttype: parentType };
      } else if (type === 'objective') {
        name = 'learning.testObjective.edit';
        params = { id: item[typeId] };
        queryObj = { parent: breadcrumbParent, parenttype: parentType };
      } else if (type === 'question') {
        name = 'learning.question.edit';
        params = { id: item[typeId] };
        queryObj = { parent: breadcrumbParent, parenttype: parentType };
      } else {
        name = `${base}.${type}.edit`;
        params = { id: item[typeId] };
        queryObj = { parent: breadcrumbParent, parenttype: breadcrumbParent ? parentType : '' };
      }

      if (!name) {
        return '';
      }

      return { name, params, query: queryObj };
    },
  },
};
</script>
<style lang="less" scoped>
table td a {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.breadcrumbs-container {
  overflow-x: auto;
}
</style>
