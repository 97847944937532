import ModelFactory from 'app/model/_factory';

class CompanyModel extends ModelFactory {
  constructor() {
    super({
      type: 'company',
      isAgnostic: true,
      defaultList: {
        address: {},
        contact: {},
      },

      so: {},
    });
  }
}

export default new CompanyModel();
