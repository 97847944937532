import ModelFactory from 'app/model/_factory';

class CountyCourtModel extends ModelFactory {
  constructor() {
    super({
      type: 'county-court',
      permType: 'county',
      isAgnostic: true,
      defaultList: {
        isActive: true,
      },
      so: {
        keys: {
          countyId: ['id', 'countyId'],
          state: ['s', 'state'],
        },
      },
    });
  }

  search(params) {
    return this.query(params);
  }
}

export default new CountyCourtModel();
