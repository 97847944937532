<template>
  <VDialog v-model="visible" width="500" @click:outside="cancel">
    <VCard>
      <VToolbar :color="color" dark>
        <VToolbarTitle data-test="modalTitle">
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="html" class="pa-5" data-test="modalBodyText1" v-html="html"></div>
      <div v-else class="pa-5" data-test="modalBodyText">{{ text }}</div>
      <VCardActions>
        <VSpacer xs9 />
        <VBtn color="warning" text data-test="modalConfirmButton" @click.stop="confirm">
          {{ confirmText }}
        </VBtn>
        <VBtn color="readonly" text data-test="modalCancelButton" @click.stop="cancel">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Please Confirm' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Do you approve?' },
    confirmText: { type: String, default: 'Yes' },
    cancelText: { type: String, default: 'No, Cancel' },
    color: { type: String, default: 'warning' },
    callback: { type: Function, default: () => undefined },
    onCancel: { type: Function, default: () => undefined },
  },
  methods: {
    confirm() {
      this.callback();
      this.visible = false;
      this.$destroy();
    },
    cancel() {
      this.onCancel();
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped></style>
