import ModelFactory from 'app/model/_factory';

class BlankitivityModel extends ModelFactory {
  constructor() {
    super({
      type: 'blankitivity',
      keys: {
        parent: 'chapter',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {
        title: '',
        questionHtml: '',
        minimumAttemptCount: 3,
        answerList: [],
        internal: {
          tagList: [],
        },
      },
      so: {
        def: 'title',
        keys: {
          title: ['t', 'title'],
          questionHtml: ['q', 'question'],
          'answerList.answerText': ['a', 'answertext'],
          _id: ['i', 'id'],
          'answerList.answerTextVariantList': ['v', 'variantlist'],
          'internal.tagList': ['l', 'taglist'],
        },
        cols: {
          title: 'Title',
          questionHtml: 'Question',
          'answerList.answerText': 'Answer Text',
        },
      },
    });
  }

  searchProgress(query, limit) {
    return this.subquery('progress', null, { query, limit });
  }
}

export default new BlankitivityModel();
