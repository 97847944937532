import ModelFactory from 'app/model/_factory';

class ProductTagModel extends ModelFactory {
  constructor() {
    super({
      type: 'product-tag',
      permType: 'productTag',
      keys: {
        child: 'ProductTag',
        childList: 'productTagList',
      },
      isAgnostic: true,
      defaultList: {
        label: '',
        description: '',
      },
      so: {
        def: 'label',
        keys: {
          _id: ['i', 'id'],
          label: ['l', 'label'],
          description: ['d', 'description'],
        },
        cols: {
          label: 'Label',
          description: 'Description',
          isActive: 'Active',
        },
      },
    });
  }
}

export default new ProductTagModel();
