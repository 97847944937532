import Api from 'app/lib/api';
import Auth from 'app/lib/auth';
import { has } from 'lodash-es';
import { jsDialog } from '../plugins/dialog';

const App = {
  isOutOfSync: false,

  alertApiFailure(res) {
    if (!res?.reason) return false;

    const contentList = {};
    contentList[Api.REASON.sessionTimeout] = 'We have lost track of your session; you will need to sign in again.';
    contentList[Api.REASON.notAuthenticated] = "Oh jeez, something is up. Please let us know what's going on in the #help-jarvis channel immediately.";
    contentList[Api.REASON.notAuthorized] = `${res.message} If signing out doesn't fix it, share your current URL and missing permission with #jarvis-and-student-help Slack to resolve.`;
    contentList[Api.REASON.notFound] = 'We could not find the action you are trying to perform. Please speak to your manager or raise a flag in #help-jarvis to check your allowed permissions.';
    contentList[Api.REASON.timeout] = 'We could not finish your action; please try again.';

    if (has(contentList, res.reason)) {
      return jsDialog
        .alert({
          title: res.reason,
          text: contentList[res.reason],
        })
        .then(() => {
          // API calls have lost user authentication, signout and go to signin page.
          switch (res.reason) {
            case Api.REASON.sessionTimeout:
            case Api.REASON.notAuthenticated:
              // For a session timeout and authentication failure, we force a user signout
              Auth.signOut();
              return { path: '_home.signin' };
            default:
          }

          return null;
        });
    }
    return jsDialog.toast({ text: res.data ? res.data.errorList[0].message : res.reason });
  },

  async authorize(permission) {
    // User is signed in
    // Check _routes.data.permission to know what is allowed
    // Public routes have no permission set, so if logged in, don't allow those pages to show
    if (!permission || !Auth.isAuthorized(permission)) {
      // Appending dialog to document.body to cover sidenav in docs app
      // User on a non-user specific page already authenticated, send to dashboard
      // Alec got rid of the confirm dialog here during Vue conversion since it wasn't working and this flow seems to be an edge case
      return '_home.dashboard';
    }
    if (!Auth.isAuthorized(permission)) {
      // User is not allowed to access page, prevent route change
      return '_home.dashboard';
    }

    return null;
  },

  syncVersion() {
    // after check to see if running build is out of date (in aceSubnav directive),
    // this function will run on next nav and reload the site if need be
    if (App.isOutOfSync) {
      App.setOutOfSync(false);
      window.location.reload();
    }
  },

  // TODO-VUE: NS 2019/3 This is the current way to handle needsRefresh for Vue (this get called from Vue subnav component)
  //  TODO-VUE: When Vue Router happens, this will change
  setOutOfSync(val = false) {
    App.isOutOfSync = val;
    return val;
  },
};

export default App;
