import ModelFactory from 'app/model/_factory';
import BrandLib from 'app/lib/brand';

class VoucherRequestModel extends ModelFactory {
  constructor() {
    const expireAt = new Date();
    expireAt.setFullYear(expireAt.getFullYear() + 10);

    super({
      type: 'voucherRequest',
      isAgnostic: true,
      keys: {
        parent: '',
        child: 'Product',
        childList: 'productIdList',
      },
      defaultList: {
        brandId: BrandLib.getCurrentTabBrandId(),
        count: 100,
        description: '',
        expireAt: expireAt.toString(),
        offPercent: 1.0,
        partnerId: '',
        productIdList: [],
        productList: [],
        startAt: new Date().toString(),
        useLimit: 1,
      },
      so: {
        def: 'title',
        keys: {
          _id: ['i', 'id'],
          title: ['t', 'title'],
          'admin.email': ['e', 'email'],
          'admin.firstName': ['f', 'first', 'firstname'],
          'admin.lastName': ['l', 'last', 'lastname'],
          productIdList: ['pid', 'productId', 'productIdList'],
        },
        cols: {
          title: 'Title',
        },
      },
    });
  }

  getVoucherEmail(_id, title) {
    return this.subquery('email', _id, { title });
  }
}

export default new VoucherRequestModel();
