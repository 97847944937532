import ModelFactory from 'app/model/_factory';

class AnswerabilityModel extends ModelFactory {
  constructor() {
    super({
      type: 'answerability',
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {},
      _id: '',
      pageId: '',
      courseId: '',
      _created_at: '',
      _updated_at: '',
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
        },
      },
    });
  }

  get({ nodeId, nodeType = 'PAGE' }) {
    return super.query({ nodeId, nodeType });
  }
}

export default new AnswerabilityModel();
