import ModelFactory from 'app/model/_factory';

class ProgressClassEventModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-class-event',
      permType: 'progressClassEvent',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  cancel(id) {
    return this.execute('cancel', null, { id });
  }
}

export default new ProgressClassEventModel();
