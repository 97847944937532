<template>
  <VDialog v-model="visible" max-width="90%" @click:outside="cancel">
    <VCard>
      <VToolbar :data-test="'linkTestTemplateToolbar'" color="primary" dark>
        <VToolbarTitle>Link Test Template</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="cancel">
          <VIcon>fa-times</VIcon>
        </VBtn>
      </VToolbar>
      <VFlex id="templates-table">
        <VDataTable
          v-if="testTemplates.length"
          v-model="selected"
          :headers="headers"
          :items="testTemplates"
          item-key="_id"
          single-select
          show-select
          :options.sync="options"
          :headers-length="headers.length"
          hide-default-footer
          class="results-table"
        >
          <VSimpleCheckbox primary hide-details />
        </VDataTable>
      </VFlex>
      <VFlex id="footer-container" class="align-center">
        <VLayout id="footer" align-center>
          <div>
            <VBtn data-test="useTemplateBtn" depressed :disabled="Boolean(!selected.length)" color="primary" class="link-button" @click="onUseTemplate()"> Use Template </VBtn>
          </div>
          <div id="cancel-container">
            <h3 @click="cancel">Cancel</h3>
          </div>
          <div>
            <VBtn data-test="searchTemplateBtn" depressed color="primary" class="link-button" @click="onSearchForAnExistingTest()"> Search For An Existing Test </VBtn>
          </div>
        </VLayout>
      </VFlex>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  components: {},
  props: {
    visible: { type: Boolean, default: false },
    testTemplates: { type: Array, default: () => [] },
    onClone: { type: Function, default: () => undefined },
    onSearch: { type: Function, default: () => undefined },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      selected: [],
      headers: [
        { value: '_id', text: 'ID' },
        { value: 'title', text: 'Title' },
        { value: 'passingPercent', text: 'Passing %' },
        { value: 'is.regulatory', text: 'Regulatory' },
        { value: 'modeType', text: 'Mode' },
      ],
    };
  },
  computed: {
    objSelected() {
      return this.selected.length ? this.selected[0] : '';
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
    onUseTemplate() {
      this.onClone({ selected: this.objSelected });
      this.cancel();
    },
    onSearchForAnExistingTest() {
      this.onSearch();
      this.cancel();
    },
  },
};
</script>
<style scoped type="less">
#templates-table {
  overflow-y: scroll;
  width: 100%;
}
.results-table {
  width: 95%;
  margin: 0 auto;
}
#footer-container {
  position: sticky;
  z-index: 11;
  bottom: 0;
  width: 100%;
}
#footer {
  width: 90%;
  margin: 0 auto;
  padding: 25px 0;
}
#cancel-container {
  padding-right: 3em;
  margin-right: 0px;
  margin-left: auto;
  cursor: pointer;
}
.link-button.v-btn.theme--light {
  margin: 0;
  border-radius: 2px;
}
.v-input--selection-controls {
  margin-top: 0;
}
</style>
