import ModelFactory from 'app/model/_factory';

class StoreModel extends ModelFactory {
  constructor() {
    super({
      type: 'store',
      isAgnostic: false,
      defaultList: {
        address: {},
        contact: {},
        company: {},
      },

      so: {
        def: 'name',
        keys: {
          name: ['n', 'name'],
          _id: ['i', 'id'],
        },
        cols: {
          name: 'Name',
          'company.name': 'Company',
        },
      },
    });
  }
}
export default new StoreModel();
