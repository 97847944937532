<template>
  <VBtn title="Learn More" icon class="v-button v-icon-button ace-wiki" color="primary" aria-label="Learn More" :href="wikiLink" target="_blank">
    <VIcon small>fa fa-question-circle</VIcon>
  </VBtn>
</template>

<script lang="ts">
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    wikiLink() {
      return `https://aceable.atlassian.net/wiki/spaces/JAR/pages/${this.link}`;
    },
  },
};
</script>
