import Session from 'app/lib/session';
import Api from 'app/lib/api';
import AUTHPERMS from 'app/lib/permissions';
import AdminModel from 'app/model/admin';
import BrandLib from 'app/lib/brand';

const Auth = {
  admin: null,
  api: null,

  /**
   *
   * @returns {*}
   */
  get() {
    return Auth.admin || Auth.sync();
  },

  /**
   *
   * @returns {*}
   */
  sync() {
    Auth.admin = Session.get('auth');
    if (Auth.admin) {
      // Set the API session key for all follow up calls
      Api.Service.setSession(Auth.admin.sessionKey);
    }

    // BrandModule.commit('setBrandId');
    return Auth.admin;
  },

  isValidBrand(brandId) {
    return BrandLib.getCurrentTabBrandId() === brandId;
  },

  /**
   * @param email
   * @param password
   * @param api
   * @param {Number} [dngCrtPr]
   * @param {Number} [dngRptPr]
   * @param {Number} [dngPrtPr]
   * @return {Promise<any>}
   */
  async signIn(email, password, api = {}, dngCrtPr, dngRptPr, dngPrtPr) {
    Api.Service.hijack({ baseUrl: (api || {}).url, dngCrtPr, dngRptPr, dngPrtPr });

    return AdminModel.signIn(email, password);
  },

  setAdmin(admin, api, dngCrtPr, dngRptPr, dngPrtPr) {
    // Cache the authorized admin into local storage
    Session.set('auth', admin);
    Session.set('api', api);
    Session.set('dngCrtPr', dngCrtPr);
    Session.set('dngRptPr', dngRptPr);
    Session.set('dngPrtPr', dngPrtPr);
  },

  requestPassword(email, api) {
    Api.Service.hijack({ baseUrl: (api || {}).url });
    return AdminModel.requestPassword(email);
  },

  setPassword(_id, code, password, api) {
    Api.Service.hijack({ baseUrl: (api || {}).url });
    return AdminModel.setPassword(_id, code, password);
  },

  /**
   *
   * @returns {boolean}
   */
  signOut() {
    Auth.admin = null;
    Session.destroyAll();
    return true;
  },

  /**
   *
   * @returns {*}
   */
  isAuthenticated() {
    if (!Auth.admin) {
      this.sync();
    }
    return Boolean(Auth.admin && Auth.admin._id);
  },

  /**
   *
   * @param permission
   * @returns {*|boolean}
   */
  isAuthorized(permission) {
    if (Auth.admin) {
      let list = [];
      if (Auth.admin.adminRole) {
        list = Auth.admin.adminRole.permissionList || [];
      }
      return this.isAuthenticated() && (permission === AUTHPERMS.all || Auth.admin.adminRole.name === AUTHPERMS.root || list.indexOf(permission) !== -1);
    }

    return false;
  },
};

export default Auth;
