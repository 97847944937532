import Api from 'app/lib/api';
import ModelFactory from 'app/model/_factory';

class LevelModel extends ModelFactory {
  constructor() {
    super({
      type: 'level',
      keys: {
        parent: 'course',
        child: 'chapter',
        childList: 'chapterList',
      },
      isAgnostic: true,
      defaultList: {
        key: '',
        title: '',
        questionCount: 0,
        bankCount: 0,
        lockMinutes: 0,
        testMinutes: 0,
        isLockedOnFailure: false,

        testPassHtml: '',
        testFailHtml: '',
        testId: '',
        navigatePassHtml: '',
        navigateFailHtml: '',
        test: {
          verifyMatrix: '',
          pseudoId: '',
          isSort: false,
        },
        topicList: [],

        // Search
        context: '',
        tagList: [],
        metadata: { nodeCount: 0, wordCount: 0, fixedEducationalSeconds: 0, updatedAt: null, goalSeconds: 0 },
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          title: ['t', 'title'],
          context: ['c', 'context'],
          tagList: ['l', 'tags', 'taglist'],
          isOrphaned: ['o', 'orphaned'],
        },
        defValues: {
          isOrphaned: '0',
        },
        cols: {
          '#': '#',
          key: 'Key',
          title: 'Title',
        },
      },
    });
  }

  promote(id, target, isBatch, optionalPromotions, parentId) {
    return Api.Service.execute(this._type, 'promote', id, { target, isBatch, optionalPromotions, parentId });
  }
}

export default new LevelModel();
