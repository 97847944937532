export default function (Router) {
  Router.abstract('settings');

  Router.vueIndex('settings');

  Router.vueCrud('settings', 'admin', true);

  Router.vueCrud('settings', 'adminRole', true);

  Router.vueCrud('settings', 'brand', true);

  Router.vueCrud('settings', 'datalog', true);

  Router.vueCrud('settings', 'styleguide', true, 'admin');

  return Router;
}
