import ModelFactory from 'app/model/_factory';

class ApprovalModel extends ModelFactory {
  constructor() {
    super({
      type: 'approval',
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {
        note: '',
        fixNote: '',
      },
      so: {
        def: 'note',
        keys: {
          adminId: ['aid', 'adminid'],
          adminName: ['ad', 'adminName'],
          courseId: ['cid', 'courseid'],
          name: ['ap', 'approver'],
          note: ['n', 'note'],
          pageId: ['pid', 'pageid'],
          pageKey: ['p', 'pagekey'],
          userId: ['uid', 'userid'],
          resolution: ['res', 'resolution'],
          'is.resolved': ['r', 'resolved'],
        },
        cols: {
          pageKey: 'Page',
          name: 'Approver',
          adminName: 'Admin',
          note: 'Note',
          'is.fixed': 'Fixed?',
        },
      },
    });
  }
}

export default new ApprovalModel();
