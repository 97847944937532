import ModelFactory from 'app/model/_factory';
import { IMAGE_URL_PREFIX } from 'app/lib/mediaConfig';

class KeyTermModel extends ModelFactory {
  constructor() {
    super({
      type: 'keyTerm',
      keys: {
        parent: '',
        child: 'Chapter',
        childList: 'chapterIdList',
      },
      isAgnostic: true,
      defaultList: {
        imageUrl: IMAGE_URL_PREFIX,
        linkUrl: 'https://',
      },
      so: {
        def: 'term',
        keys: {
          _id: ['i', 'id'],
          term: ['t', 'term'],
          definition: ['d', 'definition'],
          chapterIdList: ['c', 'chapterid'],
        },
        cols: {
          term: 'Term',
          _id: 'Id',
          definition: 'Definition',
        },
      },
    });
  }
}

export default new KeyTermModel();
