import Auth from 'app/lib/auth';
import BrandLib from 'app/lib/brand';
import { updateFavicon } from 'app/lib/favicon';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Api from './lib/api';
import ApiEndpoint from './lib/api/endpoint';
import Filters from './lib/filters';
import Router from './lib/router';
import Session from './lib/session';
import { Dialog } from './plugins/dialog';
import Vuetify from './plugins/vuetify';
import routes from './route';
import store from './store';

import 'video.js/dist/video-js.css';
import './less/index.less';

function semverToDecimal(version = '1.0.0') {
  const list = version.split('.');
  return Number(list[0]) * 1000 + Number(list[1]) + Number(list[2]) / 1000;
}

Vue.use(VueRouter);
Vue.use(VeeValidate);

// On every reload, we need to immediately sync with Local and Session data
// This lets us get current admin and brandId settings to set initial vue-router.base
// And set the vuetify.theme to the current brand
const brandId = BrandLib.build(Auth);
BrandLib.setCurrentTabBrandId(brandId);
Api.Service.setBrandId(brandId);
const router = Router.build(brandId, routes, VueRouter);
const vuetify = Vuetify.build(brandId);
updateFavicon(brandId, ApiEndpoint.getEnv(window.location.host));
Vue.use(Dialog, { vuetify, router });
const app = new Vue({
  store,
  vuetify,
  beforeCreate() {
    const domApi = document.getElementById('ace-app');
    const semver = domApi && domApi.getAttribute('ace-version');
    const sessionApi = Session.get('api') || { url: '' };
    const dngCrtPr = Session.get('dngCrtPr');
    const dngRptPr = Session.get('dngRptPr');
    Api.Service.init({ authHeader: ApiEndpoint.CONFIG.basicAuth });
    Api.Service.hijack({ baseUrl: sessionApi.url, dngCrtPr, dngRptPr });
    Api.Service.buildnum = semverToDecimal(semver || undefined);
    // 🐈 FILTERS
    Object.keys(Filters).forEach((key) => Vue.filter(key, Filters[key]));

    // Globally capture CMD+S to override native browser saving to instead let Jrvis save
    window.addEventListener('keydown', (event) => {
      // Vue deprecated $broadcast and recommends a Vuex store to have shared events
      if ((event.key === 's' || event.key === 'S') && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        this.$store.commit('keyboard/keyup', event.code);
      }
    });
  },
  router,
  render: (h) => h('router-view'),
});

router.onReady(() => app.$mount('#ace-vue'));
