import ModelFactory from 'app/model/_factory';

class CasestudyModel extends ModelFactory {
  constructor() {
    super({
      type: 'casestudy',
      keys: {
        parent: 'chapter',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {
        title: '',
        questionHtml: '',
        explanation: '',
      },
      so: {
        def: 'title',
        keys: {
          title: ['t', 'title'],
          questionHtml: ['q', 'question'],
          explanation: ['e', 'explanation'],
          _id: ['i', 'id'],
        },
        cols: {
          title: 'Title',
          questionHtml: 'Question',
          explanation: 'Explanation',
        },
      },
    });
  }

  searchProgress(query, limit) {
    return this.subquery('progress', null, { query, limit });
  }
}

export default new CasestudyModel();
