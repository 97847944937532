import ModelFactory from 'app/model/_factory';

class ProgressTestModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-test',
      permType: 'progressTest',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  override(id, isGradeOverridden) {
    return this.execute('override', null, { id, isGradeOverridden });
  }

  flagfail(id, isProctorPending) {
    return this.execute('flagfail', null, { id, isProctorPending });
  }
}

export default new ProgressTestModel();
