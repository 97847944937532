import ModelFactory from 'app/model/_factory';
import Api from '../lib/api';

class CertificatesMonitorModel extends ModelFactory {
  constructor() {
    super({
      type: 'certificates-monitor',
      permType: 'certificatesMonitor',
      isAgnostic: true,
      defaultList: {
        _id: '',
        certificateId: '',
        certificateName: '',
        brandId: '',
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: '_id',
        keys: {
          _id: '_id',
        },
      },
    });
  }

  async getMonitorErrors(id, tp) {
    const self = this;
    return Api.Service.call(`certificates-monitor/${id}/errors/${tp}`, 'GET').then((res) => self.transform(res));
  }

  async resetMonitorErrors(id, ids) {
    const data = { ids };
    const pkg = this.wrapData(data);
    return Api.Service.call(`certificates-monitor/${id}/errors`, 'POST', pkg);
  }
}
export default new CertificatesMonitorModel();
