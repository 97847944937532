<template>
  <VDialog v-model="visible" max-width="500" @click:outside="cancel">
    <VCard>
      <VToolbar dark color="primary">
        <VToolbarTitle data-test="cloneTitle"> Clone Object </VToolbarTitle>
        <VSpacer />
        <VToolbarItems>
          <VBtn icon dark @click.stop="cancel">
            <VIcon medium> fa-times </VIcon>
          </VBtn>
        </VToolbarItems>
      </VToolbar>

      <VContainer grid-list-lg fluid>
        <VForm ref="form" lazy-validation>
          <VLayout row fluid>
            <VFlex v-if="['course'].includes(objectType)">
              <VTextField v-model="customKey" label="Parent Key" description="Optional parent key of clone" />
            </VFlex>
            <VFlex v-else> You can edit the values once page reloads to cloned object. </VFlex>
            <VFlex v-if="objectType === 'coupon'">
              <VTextField v-model="customCode" label="Coupon Code" description="Optional coupon code of clone" />
            </VFlex>
            <VFlex v-if="isAdminRole">
              <VTextField v-model="customName" label="Name*" :rules="[rules.required]" description="Required name of clone" />
            </VFlex>
            <VFlex v-if="hasLinks" xs-4>
              <VSwitch v-model="isBatch" dense inset :label="'With Children'" color="primary" hide-details />
            </VFlex>
            <VFlex v-else xs-4>Any secondary or child relationships will be linked, not cloned.</VFlex>
          </VLayout>

          <VLayout row>
            <VFlex>
              <VBtn dark color="warning" block :loading="loading" @click="clone">
                <VIcon left> fa fa-copy </VIcon>
                Click here once you're ready to clone
              </VBtn>
            </VFlex>
          </VLayout>
        </VForm>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  name: 'Clone',
  props: {
    visible: { type: Boolean, default: false },
    cloneKey: { type: String, default: '' },
    model: { type: Object, default: () => undefined },
    hasLinks: { type: Boolean, default: false },
    callback: { type: Function, default: () => undefined },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      customKey: '',
      customName: '',
      customCode: '',
      isBatch: false,
      rules: {
        required: (v) => (v != null && v !== '') || 'Field is required.',
      },
    };
  },
  computed: {
    isAdminRole() {
      return this.model?._type === 'admin-role';
    },
    objectType() {
      return this.model?._type;
    },
  },
  methods: {
    async clone() {
      const validated = this.$refs.form.validate();
      if (validated) {
        this.loading = true;
        const cloneKey = this.customKey || this.cloneKey;
        await this.callback({ isBatch: this.isBatch, key: cloneKey, name: this.customName, code: this.customCode });
        this.loading = false;
        await this.cancel();
      }
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped lang="less">
.v-input--selection-controls {
  padding-top: 0;
}
.v-card {
  overflow: auto;
}

.table-diff {
  width: 100%;
  margin-top: 0.5rem;
  border-collapse: collapse;

  th,
  td {
    padding: 8px 16px;
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid #eee;
  }
  th {
    border-bottom: 1px solid rgb(128, 128, 239);
  }

  td {
    div {
      max-height: 15em;
      overflow-y: scroll;
    }

    &.nowrap {
      white-space: nowrap;
    }
    &.table-diff--code {
      font-family: monospace;
      overflow-x: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      width: auto;
    }
  }
}
</style>
