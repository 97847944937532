import Util from 'app/lib/util';
import { last } from 'lodash-es';
import getters, { getSortedScreenList } from './getters';

export default {
  namespaced: true,
  state: {
    screenList: [],
  },

  mutations: {
    replace(state, newState) {
      Object.assign(state, newState);
    },
    editScreen(state, data) {
      // find screen in list with id, then edit
      function match(screen) {
        return screen._id === data.editedFormBuilderObj._id;
      }
      const indexToEdit = state.screenList.findIndex(match);
      state.screenList[indexToEdit] = data.editedFormBuilderObj;
    },

    /**
     * Updates the trigger for a screen.  We then need to re-sort the list since a new trigger could cause a screen to move.
     */
    screenTriggerUpdated(state, { screenIdx, trigger }) {
      if (state.screenList[screenIdx]) {
        state.screenList[screenIdx].trigger = trigger;
        state.screenList = getSortedScreenList(state.screenList);
      }
    },
    /**
     * Updates a screen's fields with info from the form.
     */
    screenUpdated(state, { screenIdx, fields }) {
      state.screenList[screenIdx] = { ...state.screenList[screenIdx], ...fields };
      state.screenList = [...state.screenList]; // This triggers a rerender, vue can't always tell when new fields are added to an object.
    },
    /**
     * Toggles the trigger details collapsible card section.
     */
    screenTriggerToggled(state, { screenIdx, value = null }) {
      state.screenList[screenIdx] = {
        ...state.screenList[screenIdx],
        showTrigger: value === null ? !state.screenList[screenIdx].showTrigger : value,
      };
      state.screenList = [...state.screenList];
    },
    replaceScreenList(state, screenList) {
      state.screenList = [...screenList];
    },
    addScreen(state, { type, trigger = null }) {
      state.screenList = [
        ...state.screenList,
        {
          type,
          _id: Util.randomId(),
          trigger: trigger || (last(state.screenList) || { trigger: '0.0.0' }).trigger,
          groupList: type === 'PROFILE.FORM_BUILDER' ? [{ type: 'PROFILE.FORM_BUILDER_DEFAULT', fieldList: [] }] : null,
          contractUrlList: type === 'PROFILE.CONTRACTS_SCREEN' ? [] : null,
          isSignatureStudent: type === 'PROFILE.CONTRACTS_SCREEN' ? false : null,
          isSignatureParent: type === 'PROFILE.CONTRACTS_SCREEN' ? false : null,
          enabledGroupFieldOverrideList: { version: 'V2' },
        },
      ];
    },
    deleteScreen(state, { screenIdx }) {
      state.screenList = [...state.screenList.slice(0, screenIdx), ...state.screenList.slice(screenIdx + 1)];
    },
    deleteScreen2(state, { screenId }) {
      const screenIndx = state.screenList.findIndex((s) => s._id === screenId);
      if (state.screenList[screenIndx]) {
        state.screenList = [...state.screenList.slice(0, screenIndx), ...state.screenList.slice(screenIndx + 1)];
      }
    },
    screenTriggerUpdated2(state, { screenId, trigger }) {
      const screenIndx = state.screenList.findIndex((s) => s._id === screenId);
      if (state.screenList[screenIndx]) {
        state.screenList[screenIndx].trigger = trigger;
        state.screenList = getSortedScreenList(state.screenList);
      }
    },
  },

  actions: {
    /**
     * Though it looks simple in the UI, moving a screen is actually kind of a handful. Where a screen is rendered depends on its position in the screen list array
     * as well as the `trigger` of that screen.  This method is further complicated by the fact that multiple screens could be moving at once.
     *
     * The screenIdx arg is the index of the screen whose arrow was clicked, and direction is the way it should be moved! (up/down : earlier/later)
     *
     * I know this is pretty gross, I have some ideas about how to make it cleaner... but I'm not sure cleaner actually makes it any more readable.  A paradox!
     */
    moveScreen({ state: { screenList, course }, commit, getters: { screenListsByTriggerPoint } }, { screenIdx, direction }) {
      if (screenList.some((s) => s.selected) && !screenList[screenIdx].selected) return; // If there are any screens selected, and the one you're moving isn't one of them. Bail!
      if (direction === 'DOWN') {
        for (let i = screenList.length - 1; i >= 0; i -= 1) {
          // When moving down, we start at the end of the list and work backwards, moving each `selected` screen forward.
          const screen = screenList[i];

          if (!(screen.selected || i === screenIdx)) continue; // If the screen isn't selected and or isn't the one we're moving. Also bail!
          const triggerGroup = screenListsByTriggerPoint.find((tg) => tg.screenList.find((s) => s._id === screen._id)); // Find the trigger group this screen belongs to,
          const tgScreenIdx = triggerGroup.screenList.findIndex((s) => s._id === screen._id); // Find WHERE in that trigger group this screen is.

          // From there, we have two possible futures.  If there is a place for this screen to move forward in this trigger group, we just swap it with its neighbor
          if (tgScreenIdx < triggerGroup.screenList.length - 1) {
            if (!screenList[i + 1].selected) {
              screenList.splice(i + 1, 0, screenList.splice(i, 1)[0]);
            }
          } else {
            // If there's not any any where for this screen to move forward in this group, we move it to the NEXT group.
            // Note this doesn't actually move its position in the array, just changes its trigger.  Fancy!
            switch (screen.trigger) {
              case 'SIDEBAR':
                screen.trigger = '0.0.0';
                break;
              case '0.0.0':
                screen.trigger = 'POST_PURCHASE';
                break;
              case 'POST_PURCHASE':
                screen.trigger = '1.*.*';
                break;
              default:
                screen.trigger = `${Math.min(Number.parseInt(screen.trigger.split('.')[0], 10) + 1, course.levelList.length)}.*.*`;
            }
          }
        }
      } else {
        // This is for moving screens the other direction!  it's basically the same, except all the loops, indices, and triggers are inverted.
        for (let i = 0; i < screenList.length; i += 1) {
          const screen = screenList[i];
          if (!(screen.selected || i === screenIdx)) continue;
          const triggerGroup = screenListsByTriggerPoint.find((tg) => tg.screenList.find((s) => s._id === screen._id));
          const tgScreenIdx = triggerGroup.screenList.findIndex((s) => s._id === screen._id);
          if (tgScreenIdx > 0) {
            if (!screenList[i - 1].selected) {
              screenList.splice(i - 1, 0, screenList.splice(i, 1)[0]);
            }
          } else {
            switch (screen.trigger) {
              case 'SIDEBAR':
                break;
              case '0.0.0':
                screen.trigger = 'SIDEBAR';
                break;
              case 'POST_PURCHASE':
                screen.trigger = '0.0.0';
                break;
              default: {
                const newLevel = Number.parseInt(screen.trigger.split('.')[0], 10) - 1;
                screen.trigger = newLevel === 0 ? 'POST_PURCHASE' : `${newLevel}.*.*`;
              }
            }
          }
        }
      }
      // After we've moved all the screens, we update our list so everything re-renders. 🚀
      commit('replaceScreenList', screenList);
    },
    moveScreen2({ state: { screenList }, commit, getters: { screenListsByTriggerPoint } }, { screenId, direction }) {
      const triggerGroup = screenListsByTriggerPoint.find((tg) => tg.screenList.find((s) => s._id === screenId)); // Find the trigger group this screen belongs to,
      const tgScreenIdx = triggerGroup.screenList.findIndex((s) => s._id === screenId);
      const screenIndx = screenList.findIndex((s) => s._id === screenId);

      if (screenList[screenIndx]) {
        if (direction === 'DOWN') {
          if (tgScreenIdx < triggerGroup.screenList.length - 1) {
            screenList.splice(screenIndx + 1, 0, screenList.splice(screenIndx, 1)[0]);
          }
        } else if (tgScreenIdx > 0) {
          screenList.splice(screenIndx - 1, 0, screenList.splice(screenIndx, 1)[0]);
        }
        // After we've moved all the screens, we update our list so everything re-renders. 🚀
        commit('replaceScreenList', screenList);
      }
    },
  },
  getters,
};
