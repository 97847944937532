import ModelFactory from 'app/model/_factory';

class ClassDetailModel extends ModelFactory {
  constructor() {
    super({
      type: 'class-detail',
      permType: 'classDetail',
      isAgnostic: true,
      defaultList: {
        courseId: '',
        classNumber: '',
        hourCount: 0,
        appTitle: '',
        regulatoryTitle: '',
        internalDescription: '',
        appNoteHtml: '',
        marketingCopyHtml: '',
        classStaff: [],
        isSessionOrdered: true,
        isActive: false,
        sessionList: [],
        sessionHourCount: 0,
        maxAbsenceMinutes: 0,
        filter: {},
        metadata: {},
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'regulatoryTitle',
        keys: {
          _id: ['i', 'id'],
          roomType: ['r', 'room'],
          nickname: ['n', 'nickname'],
          webinarUrl: ['w', 'webinarurl'],
        },
        cols: {
          regulatoryTitle: 'Title',
          maxAbsenceMinutes: 'Max Permitted Absence',
          sessionHourCount: 'Session Hours',
        },
      },
    });
  }
}

export default new ClassDetailModel();
