import ModelFactory from 'app/model/_factory';

class JourneyModel extends ModelFactory {
  constructor() {
    super({
      type: 'journey',
      isAgnostic: true,
      defaultList: {
        _id: '',
        courseId: '',
        courseKey: '',
        stepList: [],
      },
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
          courseKey: ['ck', 'courseKey'],
        },
        cols: {
          _id: 'ID',
          courseKey: 'courseKey',
        },
      },
    });
  }

  getEnum() {
    return this.subquery('enum', null, {});
  }
}

export default new JourneyModel();
