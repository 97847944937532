import ModelFactory from 'app/model/_factory';

class ProductLineModel extends ModelFactory {
  constructor() {
    super({
      type: 'productLine',
      keys: {
        child: 'ProductLine',
        childList: 'productLineList',
      },
      isAgnostic: true,
      defaultList: {
        code: '',
        name: '',
      },
      so: {
        def: 'code',
        keys: {
          _id: ['i', 'id'],
          code: ['c', 'code'],
          name: ['n', 'name'],
        },
        cols: {
          code: 'Code',
          name: 'Name',
        },
      },
    });
  }
}

export default new ProductLineModel();
