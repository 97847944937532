export default {
  namespaced: true,
  state: {
    isFormDirty: false,
  },
  mutations: {
    alterFormDirtyState(state, dirty) {
      state.isFormDirty = dirty;
    },
  },
  actions: {},
  getters: {
    isFormDirty: (state) => state.isFormDirty,
  },
};
