import Session from 'app/lib/session';
import Util from 'app/lib/util';

const Nav = {
  getKey(parent = {}, name = '') {
    if (parent._type === 'chapter' && ['test', 'question', 'blankitivity'].includes(name)) {
      return parent._id ? `${parent.key ? `${parent.key}.` : ''}${parent.nodeList.length + 1}` : '';
    }
    if (parent._type === 'test') {
      return parent._id ? `${parent.key ? `${parent.key}.` : ''}${parent.objectiveList.length + 1}` : '';
    }
    if (parent._type === 'course' && ['test'].includes(name)) {
      return parent._id ? `${parent.key ? `${parent.key}.` : ''}${parent.testTemplateIdList.length + 1}` : '';
    }
    return parent._id ? `${parent.key ? `${parent.key}.` : ''}${parent[`${name}List`].length + 1}` : '';
  },

  getContentPagePreviewMobile() {
    return Session.get('content.page.previewMobile') || false;
  },

  getListType(type, parentType) {
    const lowerType = type.toLowerCase();
    return Util.match(`${lowerType}List`, {
      testobjectiveList: 'objectiveList',
      productList: ({ check }) => (parentType === 'coupon' ? 'productIdList' : check),
      _: ({ check }) => check,
    });
  },

  getRouteBase(type = '') {
    return Util.match(type.toLowerCase(), {
      test: 'learning',
      testobjective: 'learning',
      question: 'learning',
      casestudy: 'learning',
      bucketivity: 'learning',
      blankitivity: 'learning',
      product: 'platform',
      coupon: 'platform',
      voucherrequest: 'platform',
      voucherpartner: 'platform',
      voucher: 'platform',
      admin: 'settings',
      _: 'content',
    });
  },

  setContentPagePreviewMobile(value) {
    return Session.set('content.page.previewMobile', value);
  },

  /**
   * parse a parent name with full lineage where left most is newest parent
   * For example, if in a question, it might be `testObjective.test.level.course`
   *
   * @return {{name: String, params: Object, query: Object}|{}}
   */
  gotoParent(parentTypeOverride = undefined) {
    const parentName = parentTypeOverride || Nav.parentName;
    const parts = parentName.split('.');
    let parent = parts.shift();
    const grandParents = parts.join('.');

    const set = Nav.splitParentSet();
    if (!parent || !set.id) {
      return {};
    }

    const base = Nav.getRouteBase(parent);
    let method = 'edit';
    if (parent === 'focus') {
      // Note that chapter exists as chapter/edit and chapter/focus so we need to support both edge cases
      parent = 'chapter';
      method = 'focus';
    }
    return { name: `${base}.${parent}.${method}`, params: { id: set.id }, query: { parent: set.rest, parenttype: grandParents } };
  },

  /**
   * @param parent
   * @param parentSet
   * @param id
   * @param name
   * @param parentName
   * @returns object
   */
  build(parent, parentSet, id, name, parentName) {
    let editedName = name;
    if (name === 'testObjective') {
      editedName = 'objective';
    }
    const list = parent && parent[`${editedName}List`];
    const currentIdx = list && list.findIndex((r) => r[name === 'node' ? 'linkId' : '_id'] === id);

    // now bundle up the three values in an object and export it to the Nav
    Nav.name = name;
    Nav.parentName = parentName;
    Nav.parentSet = parentSet;
    Nav.parentId = parent && parent._id;

    if (name === 'node') {
      Nav.nextId = ((list && list[currentIdx + 1]) || {}).linkId;
      Nav.nextType = ((list && list[currentIdx + 1]) || {}).type;
      Nav.prevId = ((list && list[currentIdx - 1]) || {}).linkId;
      Nav.prevType = ((list && list[currentIdx - 1]) || {}).type;
    } else {
      Nav.nextId = ((list && list[currentIdx + 1]) || {})._id;
      Nav.nextType = undefined;
      Nav.prevId = ((list && list[currentIdx - 1]) || {})._id;
      Nav.prevType = undefined;
    }
    return Nav;
  },

  splitParentSet() {
    let parentId = [];
    let list = [];
    if (Nav.parentSet) {
      list = Nav.parentSet.slice(0).split('.');
      parentId = list.splice(0, 1);
    }

    return {
      id: parentId.length ? parentId[0] : null,
      rest: list.join('.'),
    };
  },
};

export default Nav;
