import moment from 'moment';
import ModelFactory from 'app/model/_factory';
import Util from 'app/lib/util';

class CertificateModel extends ModelFactory {
  constructor() {
    super({
      type: 'certificate',
      isAgnostic: true,
      defaultList: {
        _id: '',
        courseHours: 0,
        courseId: '',
        // type: '',
        isMandatory: false,
        instructor: null,
        instructorNumber: null,
        iterableCampaignId: '',
        marketingDelayFrom: 'NONE',
        marketingDelayMinutes: 0,
        orderCount: null,
        messageShorthand: '',
        template: '',
        govCourseKey: '',
        option: {
          isEfiled: false,
          isExpeditable: false,
          isPurchasable: false,
          isPurchased: false,
          isUpload: false,
        },
        content: {
          title: '',
        },
        contentList: [
          {
            _id: Util.randomId(16),
            key: 'DEFAULT',
            title: '',
            description: '',
          },
        ],
        trigger: {
          // type: '',
          delayMinutes: 0,
          delayFrom: 'NONE',
          pseudoId: '',
          grandfatherDate: moment.utc().startOf('day').toDate(),
        },
        controlCodeType: 'NONE',
      },
      so: {
        def: 'content.title',
        keys: {
          'content.title': ['t', 'title'],
          courseId: ['c', 'courseId'],
        },
        cols: {
          'content.title': 'Certificate Label',
          template: 'Template',
          'trigger.type': 'Trigger Type',
        },
      },
    });
  }
}

export default new CertificateModel();
