import ModelFactory from 'app/model/_factory';

class AdminPermissionModel extends ModelFactory {
  constructor() {
    super({
      type: 'admin-permission',
      permType: 'adminPermission',
      keys: {
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {
        _id: '',
        name: '',
        description: '',
        category: '',
      },
      so: {
        def: 'name',
        keys: {
          name: ['n', 'name'],
          _id: ['i', 'id'],
        },
        cols: {
          _id: 'ID',
          name: 'Name',
        },
      },
    });
  }
}

export default new AdminPermissionModel();
