import ModelFactory from 'app/model/_factory';

class StyleguideModel extends ModelFactory {
  constructor() {
    super({
      type: 'styleguide',
      permType: 'admin',
      isAgnostic: true,
      keys: {
        child: '',
        childList: '',
      },
      defaultList: {},
      so: {
        def: 'name',
        keys: {},
        cols: {},
      },
    });
  }

  search() {
    return [
      {
        name: 'Styleguide',
        description: 'This is an example of a list view',
        _id: 'ExampleID',
      },
    ];
  }

  count() {
    return new Promise((resolve) => {
      resolve(1);
    });
  }
}

export default new StyleguideModel();
