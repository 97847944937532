<template>
  <VDialog v-model="visible" max-width="80%" @click:outside="cancel">
    <VCard style="overflow: auto">
      <VToolbar id="promoteToolbar" color="primary" dark>
        <VToolbarTitle> Promote Object </VToolbarTitle>
        <VSpacer />
        <VToolbarItems>
          <VBtn icon dark @click="cancel">
            <VIcon medium> fa-times </VIcon>
          </VBtn>
        </VToolbarItems>
      </VToolbar>

      <VContainer grid-list-lg fluid>
        <VLayout row fluid>
          <VFlex xs8>
            <VSelect v-model="target" :items="targetList" label="Select Environment" item-text="label" return-object solo hide-details @change="changeTarget()" />
          </VFlex>
          <VFlex v-if="hasLinks" xs-4>
            <VSwitch v-model="isBatch" dense inset :label="'With Links'" color="primary" hide-details />
          </VFlex>
          <VFlex v-if="isCourse && isBatch" xs2>
            <VSwitch v-model="promoteChildProducts" dense inset label="Promote Child Products" color="primary" hide-details />
          </VFlex>
          <VFlex v-if="promotionPermissions.infoMessage" xs12>
            <p>{{ promotionPermissions.infoMessage }}</p>
          </VFlex>
        </VLayout>

        <VLayout class="table_container" row fluid>
          <table v-if="diffs.length" class="table-diff">
            <thead>
              <tr>
                <th>Key</th>
                <th>Diff</th>
                <th>Source ({{ apiComp }})</th>
                <th>Destination ({{ target.label }})</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in diffData" :key="index">
                <td class="nowrap" nowrap="nowrap">
                  <strong>{{ row.path }}</strong>
                </td>
                <td :title="row.info">
                  {{ row.type }}
                </td>
                <td class="table-diff--code">
                  <div>{{ row.source }}</div>
                </td>
                <td class="table-diff--code">
                  <div>{{ row.destination }}</div>
                </td>
                <td>{{ row.type === 'Brand New' ? '' : row.typeof }}</td>
              </tr>
            </tbody>
          </table>
          <table v-if="target && !diffs.length" class="table-diff">
            <tbody>
              <tr>
                <td class="warning--text text-center">No differences between current parent data and {{ target.label }}. You may still promote "with links" if you wish.</td>
              </tr>
            </tbody>
          </table>
        </VLayout>

        <VLayout v-if="diffs.length || isBatch" row>
          <VFlex>
            <VBtn dark color="warning" block @click="promote">
              <VIcon left> fa fa-cloud-upload </VIcon>
              &nbsp; Approve & Promote to {{ target.label }}{{ isBatch ? ' With Links' : ' Without Links' }}
            </VBtn>
          </VFlex>
        </VLayout>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
import { omit } from 'lodash-es';
import { diff } from 'deep-diff';
import Util from 'app/lib/util';

export default {
  name: 'Promote',
  props: {
    visible: { type: Boolean, default: false },
    obj: { type: Object, default: () => undefined },
    model: { type: Object, default: () => undefined },
    hasLinks: { type: Boolean, default: false },
    /**
     * Promotion status is used to determine which environments can be selected from a list for promotion based on various criteria.
     * @property {Object} isEnvDisabled - An object that specifies the environments that are disabled for promotion.
     */
    promotionPermissions: {
      type: Object,
      default: () => ({
        infoMessage: '',
        isEnvDisabled: {
          production: false,
          staging: false,
          sandbox: false,
        },
      }),
    },
  },
  data() {
    return {
      diff: false,
      isBatch: false,
      target: '',
      actualObj: {},
      targetObj: {},
      diffs: {},
      promoteChildProducts: false,
    };
  },
  computed: {
    targetList() {
      const list = [];
      if (this.apiComp === 'Local' || this.apiComp === 'Staging') list.push({ label: 'Sandbox', value: 'ace-cxi-snd', disabled: this.promotionPermissions?.isEnvDisabled?.sandbox });

      if (this.apiComp === 'Government')
        list.push(
          { label: 'Production', value: 'ace-cxi-prd', disabled: this.promotionPermissions?.isEnvDisabled?.production },
          { label: 'Staging', value: 'ace-cxi-stg', disabled: this.promotionPermissions?.isEnvDisabled?.staging },
        );

      return list;
    },

    type() {
      return `${this.obj._type.charAt(0).toUpperCase()}${this.obj._type.slice(1)}`;
    },
    diffData() {
      return this.diffs;
    },
    isCourse() {
      return this.model._type === 'course';
    },
    apiComp() {
      return Util.getCurrentEnvironment();
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
    async showDiffs() {
      const id = this.obj._id;
      const target = this.target.value;
      /*
        Added 'actualObj' because if the comparison is with 'obj' it shows parameters that will not remain in db,
        that's why it was showing changes right after promotion.
      */
      try {
        this.actualObj = await this.model.get(id);
      } catch {
        this.actualObj = {};
      }
      try {
        this.targetObj = await this.model.get(id, target);
      } catch {
        this.targetObj = {};
      }
      this.diffs = await this.assessDiffs(this.actualObj, this.targetObj);
    },
    changeTarget() {
      if (this.target) {
        this.showDiffs();
      }
    },
    titleCase(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    camelCaseToWords(text) {
      const result = String(text).replace(/([A-Z])/g, ' $1');
      return this.titleCase(result);
    },
    assessDiffs(currentEnvObj = {}, targetEnvObj = {}) {
      const source = omit(currentEnvObj, ['_safe', '_class', 'updatedAt', 'createdAt']);
      const target = omit(targetEnvObj, ['_safe', '_class', 'updatedAt', 'createdAt']);
      const diffs = diff(target, source) || [];

      return diffs
        .reduce((result, row) => {
          const path = row.path.map((r) => this.camelCaseToWords(r)).join(' > ');
          /*
            when there are differences in an array of objects or strings, the lhs, rhs and kind properties are placed
            inside a property called 'item'. if that property does not exist, these properties are found directly in row.
          */
          const item = row.item || row;
          let type = '';
          let info = '';
          if (!Object.keys(targetEnvObj).length) {
            type = 'Brand New';
            info = "Maybe the best band ever? Also means that this view doesn't exist on the destination yet.";
          } else if (!item.lhs && !item.rhs) {
            // If both are falsy, do not add entry (simple type conversion issue)
            return result;
          } else {
            type = { N: 'Added', D: 'Removed', E: 'Edited', A: 'Array' }[item.kind];
            info = 'Either Added to destination, Removed from destination, Edited on source, or Array (list) has changed.';
          }

          return result.concat([{ path, type, info, typeof: this.titleCase(typeof item.lhs), source: item.rhs, destination: item.lhs }]);
        }, [])
        .sort((a, b) => (a.path < b.path ? -1 : 1));
    },

    async promote() {
      const self = this;
      this.$dialog.confirm({
        title: 'Promote?',
        text: `Are you sure you want to promote ${this.obj._id} to ${this.target.label} ${this.isBatch ? 'with links' : 'without links'}?`,
        confirmText: 'Yes, promote',
        cancelText: 'No, Cancel',
        async callback() {
          if (self.promoteChildProducts) {
            self.model.promote(self.obj._id, self.target.value, self.isBatch, ['products'], self.obj.parentId).then((response) => {
              self.$dialog.toast({ text: response.msg });

              self.cancel();
            });
          } else {
            self.model.promote(self.obj._id, self.target.value, self.isBatch, [], self.obj.parentId).then((response) => {
              self.$dialog.toast({ text: response.msg });

              self.cancel();
            });
          }
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
#promoteToolbar {
  background: var(--v-primary-base);
}
.v-input--selection-controls {
  padding-top: 0;
}
.v-card {
  overflow: scroll;
}

.table_container {
  max-height: calc(100vh - 305px);
  overflow: auto;
}

.table-diff {
  width: 100%;
  margin-top: 0.5rem;
  border-collapse: collapse;

  th,
  td {
    padding: 8px 16px;
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid #eee;
  }
  th {
    border-bottom: var(--v-primary-base);
    top: 0;
    position: sticky;
    background: white;
  }

  td {
    div {
      max-height: 15em;
      overflow-y: auto;
    }

    &.nowrap {
      white-space: nowrap;
    }
    &.table-diff--code {
      font-family: monospace;
      overflow-x: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      width: auto;
    }
  }
}
</style>
