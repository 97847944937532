import ModelFactory from 'app/model/_factory';

class StudyTestModel extends ModelFactory {
  constructor() {
    super({
      type: 'study-test',
      permType: 'studyTest',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }

  getListByUser(userId) {
    return this.query({ userId });
  }

  getListByUserProgress(userProgressId) {
    return this.query({ userProgressId });
  }
}

export default new StudyTestModel();
