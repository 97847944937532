export default function (Router) {
  Router.abstract('learning');

  Router.vueIndex('learning');

  Router.vueCrud('learning', 'test', true);

  Router.vueCrud('learning', 'testObjective', true);

  Router.vueCrud('learning', 'question', true);

  Router.vueCrud('learning', 'casestudy', true);

  Router.vueCrud('learning', 'bucketivity', true);

  Router.vueCrud('learning', 'blankitivity', true);

  Router.vueCrud('learning', 'keyTerm', true);

  Router.vueCrud('learning', 'activityCategory', true);

  return Router;
}
