export default function (Router, Permissions) {
  Router.abstract('ops');

  Router.vueIndex('ops');

  Router.vueCrud('ops', 'county-court', true);

  Router.vueCrud('ops', 'county', true);

  Router.vueCrud('ops', 'company', true);

  Router.vueCrud('ops', 'store', true);

  Router.vueCrud('ops', 'certificate', true);

  Router.vueCrud('ops', 'printManifest', true);

  Router.vueCrud('ops', 'stateReport', true);

  Router.vue('ops.stateReport.recordVerify', '/:id', Permissions.ops.read, 'ops.stateReport', 'ops.stateReport.record');
  Router.vue('ops.stateReport.record', '', Permissions.ops.read, 'ops.stateReport', 'ops.stateReport.record');

  Router.vueCrud('ops', 'report', true);
  Router.vue('ops.report.run', '/:id', Permissions.ops.read, 'ops.report', 'ops.report');

  Router.vue('ops.shipping', '', Permissions.ops.read, 'ops.index', 'ops.shipping');

  Router.vueCrud('ops', 'certificatesMonitor', true);

  Router.vueCrud('ops', 'controlCode', true);

  Router.vueCrud('ops', 'credentials', true);

  return Router;
}
