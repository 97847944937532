import ModelFactory from 'app/model/_factory';

class BucketivityModel extends ModelFactory {
  constructor() {
    super({
      type: 'bucketivity',
      keys: {
        parent: 'chapter',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {},
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
          title: ['t', 'title'],
          'categoryList.title': ['c', 'category'],
          'categoryList.descriptorList.description': ['d', 'descriptor'],
        },
        cols: {
          title: 'Title',
        },
      },
    });
  }
}

export default new BucketivityModel();
