import ModelFactory from 'app/model/_factory';
import Auth from 'app/lib/auth';
import Api from 'app/lib/api';

class StateReportModel extends ModelFactory {
  constructor() {
    super({
      type: 'state-report',
      permType: 'stateReportRecord',
      isAgnostic: true,
      defaultList: {
        _id: '',
        name: '',
        brandId: '',
        reportScript: '',
        cronTime: '',
        method: '',
        data: {},
        needIndCertVerification: false,
        needVerificationMark: false,
        canStoreDocuments: false,
        lastRecordId: '',
        lastExecuted: '',
        lastExecutionAdmin: '',
        lastExecutionSuccess: '',
        lastExecutionCount: '',
        lastExecutionStartDate: '',
        lastExecutionEndDate: '',
        nextExecution: '',
        isActive: false,
        _updated_at: '',
        sendByEmail: {
          _to: [],
          _cc: [],
          _bcc: [],
        },
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'name',
        keys: {
          name: ['n', 'name'],
        },
        cols: {
          name: 'Name',
        },
      },
    });
  }

  async execReReport(reportId, data) {
    const self = this;
    const jrvUser = Auth.get();
    const inpData = { ...data, requestedBy: jrvUser.email, _created_at: new Date() };
    const pkg = this.wrapData(inpData);
    return Api.Service.call(`state-report/${reportId}/re-report`, 'POST', pkg).then((res) => self.transform(res));
  }

  async removeReReport(record) {
    const self = this;
    return Api.Service.call(`state-report/${record.reportId}/re-report/${record._id}`, 'DELETE').then((res) => self.transform(res));
  }
}
export default new StateReportModel();
