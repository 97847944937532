import Api from 'app/lib/api';
import ModelFactory from 'app/model/_factory';

class PrintManifestModel extends ModelFactory {
  constructor() {
    super({
      type: 'print-manifest',
      permType: 'printManifest',
      isAgnostic: true,
      defaultList: {
        _id: '',
        stage: '',
        certificateId: '',
        certificateName: '',
        brandId: '',
        printJobList: {
          progressCertificateId: '',
          isComplete: '',
          errorData: '',
          trackingId: '',
          controlCode: '',
          firstName: '',
          lastName: '',
          userId: '',
          shippingType: '',
          _updated_at: '',
        },
        _created_at: '',
        _updated_at: '',
        note: '',
        _completed_at: '',
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: '_id',
        keys: {
          _id: '_id',
        },
      },
    });
  }

  async exportManifest(_id, data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.call(`print-manifest/${_id}/export`, 'POST', pkg, null, 5 * 60000).then((res) => self.transform(res));
  }

  async addProgCertToPrintManifest(progressCertificateId, data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.call(`print-manifest/add-progresscertificate`, 'POST', pkg, null).then((res) => self.transform(res));
  }

  async findByProgressCertificate(progressCertificateId) {
    const self = this;
    return Api.Service.call(`print-manifest/${progressCertificateId}/find-by-progresscertificate`, 'GET').then((res) => self.transform(res));
  }

  async fetchPrintJobs() {
    const self = this;
    return Api.Service.call(`print-jobs/fetch-print-jobs`, 'GET').then((res) => self.transform(res));
  }

  async togglePrintJobs(data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.call(`print-jobs/toggle-print-jobs`, 'PUT', pkg).then((res) => self.transform(res));
  }
}
export default new PrintManifestModel();
