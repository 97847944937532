import ModelFactory from 'app/model/_factory';

class EmailModel extends ModelFactory {
  constructor() {
    super({
      type: 'email',
      isAgnostic: true,
      defaultList: {
        _id: '',
        eventKey: '',
        courseType: '',
        state: '',
        emailKey: null,
        smsContent: '',
        smsServiceId: null,
      },
      so: {
        def: 'key',
        keys: {
          courseType: ['c', 'course'],
          eventID: ['e', 'event'],
          emailKey: ['m', 'email'],
          state: ['s', 'state'],
        },
        cols: {
          mode: 'Mode',
          type: 'Type',
          title: 'Title',
        },
      },
    });
  }

  get courseTypeList() {
    return {
      DD: 'DD',
      DE: 'DE',
      RE: 'RE',
      MTG: 'MTG',
      INS: 'INS',
      PTDE: 'PTDE',
      AMI: 'AMI',
    };
  }
}

export default new EmailModel();
