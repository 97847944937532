<template>
  <VDialog v-model="visible" width="500" @click:outside="cancel">
    <VCard>
      <VToolbar color="warning" dark>
        <VToolbarTitle data-test="modalTitle">
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <div v-if="html" class="pa-5" v-html="html"></div>
      <div v-else data-test="modalBodyText" class="pa-5">{{ text }}</div>
      <VCardActions>
        <VSpacer xs9 />
        <VBtn data-test="acknowledgeButton" color="warning" text @click.stop="acknowledge">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Alert' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Alert!' },
    cancelText: { type: String, default: 'Understood' },
    callback: { type: Function, default: () => undefined },
  },
  methods: {
    acknowledge() {
      this.callback();
      this.cancel();
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped></style>
