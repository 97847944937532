import ModelFactory from 'app/model/_factory';

class BreadcrumbModel extends ModelFactory {
  constructor() {
    super({
      type: 'breadcrumb',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  realtime({ linkId, mode = 'NODE' }) {
    return super.subquery('realtime', null, { linkId, mode });
  }

  impact({ linkIdList, mode = 'NODE' }) {
    return super.subquery('impact', null, { linkIdList, mode });
  }

  query(params) {
    return super.query({
      limit: 200,
      ...params,
    });
  }
}

export default new BreadcrumbModel();
