import Api from 'app/lib/api';
import { cloneDeep, isArray, map } from 'lodash-es';

import ModelAbstract from './_abstract';

// These model types are either too high volume or have no value in returning count size
// See the usage below for additional context and links
const RESTRICTED_COUNT_TYPES = ['user', 'user-legacy', 'voucher', 'page', 'stateReport', 'class-event'];

/**
 * ⚠️ FIXME: async is not allowed in models with still. DO NOT USE ASYNC
 */
export default class ModelFactory {
  /**
   *
   * @param {object} args
   * @param {string} args.type // the model type like class-staff
   * @param {string} args.permType // Used if route and permission type differs from type like class-staff vs classStaff
   * @param {string} args.defQuery // Allow us to set a default query on a list view on page load in power search bar
   */
  constructor({ type = '', permType = undefined, keys = {}, isAgnostic = false, defaultList = {}, so = {} } = {}) {
    const fnArgs = arguments;
    if (typeof fnArgs[0] === 'string') {
      [type, isAgnostic] = fnArgs;
    }

    this.keys = keys;
    this._type = type;
    this.permType = permType ?? type;
    this._isAgnostic = isAgnostic;
    this.defaultList = defaultList;
    /**
     * @type {object} so - short for searchObject
     * @property {string} def - The default search key
     * @property {Record<string, string[]} keys - the object.key is the database object to search, then you can supply multiple nicknames to search for that object
     *
     *   Example: username: ['e', 'email', 'username'] } searches DB.username if user enters `e:email@test.com` or `email:***` or `username:***` in the search bar
     *
     * @property {Record<string, string | { header: string; formatValue: (value: string) => string }} cols - The header labels to display
     *  Can either take a simple string or an object with a header and formatValue function
     *  The formatValue function is used to format the values in the table rows a certain way during search picker
     *  Note that your columns can be nested, like 'is.hidden' in the example below
     *
     *  Example: 'is.hidden': { header: 'Visibility', formatValue: (value) => (value ? 'Hidden' : 'Visible') }
     */
    this.so = so;
  }

  wrapData(data) {
    const pkg = {};
    let objName = 'document';

    if (!this._isAgnostic) {
      objName = this._type.replace(/-([a-z])/g, (m, w) => w.toUpperCase());
    }

    pkg[objName] = data;
    return pkg;
  }

  /**
   *
   * @param _id
   * @param data
   * @returns {*}
   */
  create(_id, data) {
    return new ModelAbstract(_id, this._type, data);
  }

  /**
   *
   * @param data
   * @returns {*}
   */
  transform(data) {
    const self = this;
    if (isArray(data)) {
      return map(data, (row) => new ModelAbstract(row._id, self._type, row));
    }
    if (typeof data === 'string') {
      data = {
        _id: 'XXXXXX',
        body: data,
      };
    }
    return new ModelAbstract(data._id, self._type, data);
  }

  /**
   *
   * @param _id
   * @param [env]
   * @returns {*}
   */
  // Get will only return one data object. If you want more than one, use query.
  get(_id, env = undefined) {
    const self = this;
    return Api.Service.get(this._type, _id, env).then((res) => self.transform(res));
  }

  /**
   *
   * @param params
   * @returns {*}
   */
  // Query will return multiple data objects. If you want to limit the return to one obj, use get.
  query(params) {
    const self = this;
    return Api.Service.query(this._type, params).then((res) => self.transform(res));
  }

  search(params) {
    return this.query(this.attachDefaultSearchValues(params));
  }

  /**
   * This is useful if you have hidden query params you want auto enforced
   * Like ensuring all content has o:0 to exclude orphans
   * However, once a user sets a param to - it will be removed
   * @param {Record<string, string>} params
   * @returns {Record<string, string>}
   */
  attachDefaultSearchValues(params) {
    if (this.so?.defValues) {
      Object.keys(this.so.defValues).forEach((key) => {
        if (!params.criteria[key]) {
          params.criteria[key] = this.so.defValues[key];
        } else if (params.criteria[key] === '-') {
          delete params.criteria[key];
        }
      });
    }

    return params;
  }

  update(_id, data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.update(this._type, _id, pkg).then((res) => self.transform(res));
  }

  /**
   *
   * @param action
   * @param _id
   * @param data
   * @returns {*}
   */
  // Use updateAction to perform PUT requests to update object with an action
  updateAction(action, _id, data) {
    return Api.Service.updateAction(this._type, action, _id, data).then((res) => this.transform(res));
  }

  /**
   *
   * @param data
   * @returns {*}
   */
  save(data) {
    const self = this;
    const pkg = this.wrapData(data);
    return Api.Service.save(this._type, pkg).then((res) => self.transform(res));
  }

  /**
   *
   * @param action
   * @param _id
   * @param data
   * @returns {*}
   */
  // Use subquery to perform GET requests that are not strictly RESTful w/ some sort of extra or special part of the query
  subquery(action, _id, data) {
    const self = this;
    return Api.Service.subquery(this._type, action, _id, data).then((res) => self.transform(res));
  }

  /**
   *
   * @param action
   * @param _id
   * @param data
   * @returns {*}
   */
  // Use execute to perform POST requests that are not strictly RESTful w/ some sort of tweak/edit
  execute(action, _id, data) {
    return Api.Service.execute(this._type, action, _id, data).then((res) => this.transform(res));
  }

  delete(_id) {
    const self = this;
    return Api.Service.delete(this._type, _id).then((res) => self.transform(res));
  }

  /**
   * @param params
   */
  count(params) {
    // These model types are either too high volume or have no value in returning count size
    // @see app/components/content/list.vue:runQuery() on how -1 is handled in pagination
    if (RESTRICTED_COUNT_TYPES.includes(this._type)) {
      return new Promise((resolve) => {
        resolve(-1);
      });
    }

    return Api.Service.count(this._type, params);
  }

  print(_id, data) {
    return Api.Service.print(this._type, _id, data);
  }

  promote(id, target, isBatch, optionalPromotions) {
    return Api.Service.execute(this._type, 'promote', id, { target, isBatch, optionalPromotions });
  }

  populate(obj) {
    return Object.assign(cloneDeep(this.defaultList), obj);
  }
}
