import Html from 'app/lib/html';
import { truncate as _truncate } from 'lodash-es';
import moment from 'moment-timezone';

function truncate(s, length = 50, omission = '...', separator = null) {
  const str = typeof s === 'object' ? JSON.stringify(s) : (s || '').toString();
  return _truncate(str, { length, omission, separator });
}

const Filters = {
  exclamation: (s) => (s ? '❗' : ' '),
  masteryTracking: (s) => (s ? '⚡' : '❌ '),

  length: (s) => s.length || '',

  phone: (s) => (s || '').toString().replace(/1?(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),

  thumbsup: (s) => (s ? '✅' : ' '),

  titlecase(s) {
    const regex = /(progressID|progressTestId|Progress Test)/gi;
    if (regex.test(s)) {
      return s;
    }
    return (s || '')
      .toString()
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/[_.]/g, ' ')
      .replace(/\b([a-z])/g, (ch) => ch.toUpperCase());
  },

  truncate,

  fixed25: (s) => truncate(s, 30),

  currency: (s) => `$${s.toFixed(2)}`,

  yesno: (s) => (s ? 'Yes' : 'No'),

  status: (s) => (s ? 'Active' : 'Cancelled'),

  showZero: (s) => (s === 0 ? '0' : ''),

  plainText: (s) => Html.toPlainText(s),

  /**
   * @param {String} date         An unformatted date parameter ready to be converted
   * @param {String} timezone     A timezone that defaults to CX timezone if one isn't provided
   * @param {String} format       An alternate date format if the default isn't sufficient
   * @return {string}
   */
  datetime(date, timezone = 'Us/Central', format = 'MM/DD/YY h:mm A', noTimezone = false) {
    if (!date) return '';

    const shorthands = {
      medium: 'MMM D, Y h:mm:ss a',
      short: 'M/D/YY h:mm a',
      fullDate: 'EEEE, MMMM D, Y',
      longDate: 'MMMM D, Y',
      mediumDate: 'MMM D, Y',
      shortDate: 'M/D/YY',
      mediumTime: 'h:mm:ss a',
      shortTime: 'h:mm a',
      dayDateHours: 'dddd, MM/DD/YYYY hh:mmA',
    };
    const dateFormatted = moment.tz(new Date(date), timezone);
    return `${dateFormatted.format(shorthands[format] || format)} ${noTimezone ? '' : timezone}`;
  },

  /**
   * @param message
   * @return {string}
   */
  timespan(timeInSec) {
    if (!timeInSec && timeInSec !== 0) return '';
    const dateObj = new Date(timeInSec * 1000);

    const hh = dateObj.getUTCHours().toString().padStart(2, '0');

    const mm = dateObj.getUTCMinutes().toString().padStart(2, '0');

    const ss = dateObj.getSeconds().toString().padStart(2, '0');

    return `${hh}:${mm}:${ss}`;
  },

  defaultIfMissing: (s, defTxt = '') => s || defTxt,
};

export default Filters;
