export default {
  namespaced: true,
  state: {
    isStale: false,
  },
  mutations: {
    isStale(state, isStale) {
      state.isStale = isStale;
    },
  },
  getters: {
    isStale: (state) => state.isStale,
  },
};
