import ModelFactory from 'app/model/_factory';

class ClassLocationModel extends ModelFactory {
  constructor() {
    super({
      type: 'class-location',
      permType: 'classLocation',
      isAgnostic: true,
      defaultList: {
        roomType: '',
        nickname: '',
        isWebinarAutoGenerated: false,
        webinarUrl: '',
        address: {
          street: '',
          apt: '',
          room: '',
          city: '',
          state: '',
          zip: '',
        },
        maxCapacity: 0,
        isActive: true,
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'nickname',
        keys: {
          _id: ['i', 'id'],
          roomType: ['r', 'room'],
          nickname: ['n', 'nickname'],
          webinarUrl: ['w', 'webinarurl'],
        },
        cols: {
          nickname: 'Nickname',
          roomType: 'Room',
          maxCapacity: 'Capacity',
        },
      },
    });
  }
}

export default new ClassLocationModel();
