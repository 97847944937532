import moment from 'moment';
import ModelFactory from 'app/model/_factory';

class ClassEventModel extends ModelFactory {
  constructor() {
    super({
      type: 'class-event',
      permType: 'classEvent',
      isAgnostic: true,
      defaultList: {
        classDetailId: '',
        classSessionId: '',
        appTitle: '',
        regulatoryTitle: '',
        startDateTime: new Date().toISOString(),
        endDateTime: undefined,
        classStaffList: [],
        classLocationId: '',
        classWebinarUrl: '',
        notes: '',
        eventTimeList: [
          {
            startDateTime: new Date().toISOString(),
            endDateTime: undefined,
          },
        ],
        isActive: false,
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'startDateTime',
        defaultQuery: `sd:${moment().format('MM/DD/YYYY')}`,
        keys: {
          _id: ['i', 'id'],
          appTitle: ['t', 'title'],
          startDateTime: ['sd', 'start'],
          endDateTime: ['ed', 'end'],
          classDetailId: ['d', 'detail'],
          classSessionId: ['s', 'session'],
          'classStaffList.classStaffId': ['f', 'staff'],
          isStaffList: ['isstaff'],
        },
        cols: {
          appTitle: 'Session Name',
          startDateTime: 'Start Date',
          endDateTime: 'End Date',
        },
      },
    });
  }
  /**
   * Exports a report based on the provided report type.
   * @param {string} id - The identifier for the report.
   * @param {Object} eventInfo - Information about the event (e.g. title, startDate, endDate).
   * @param {string} reportType - The type of report to be generated, can be 'attended' or 'scheduled'.
   */
  export(id, eventInfo, reportType) {
    return this.subquery(`reports/${reportType}`, id, { eventInfo });
  }
}

export default new ClassEventModel();
