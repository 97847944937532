import ModelFactory from 'app/model/_factory';
import Api from '../lib/api';

/**
 * Override the default Abstract.search() function
 * Perform an intelligent search to match against full emails and phone numbers
 * If CX has pasted a full version of either, ask API for an anchored search
 * This allows copy/paste searches to be 10 times faster
 *
 * @param params
 */
class UserDocumentModel extends ModelFactory {
  constructor() {
    super({
      type: 'userDocument',
      isAgnostic: true,
      defaultList: {
        isTestLocked: false,
        isSpecialRequest: false,
      },
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      qo: {
        order: 'userId',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'userId',
        keys: {},
        hints: {},
        cols: {},
      },
    });
  }

  setUpload(tempKey, contentType, fullUserName, docType) {
    return this.subquery('set-upload', null, { tempKey, contentType, fullUserName, docType });
  }

  bulkCreate(list) {
    return this.execute('bulk-create', null, { list });
  }

  remove(_id, key) {
    const self = this;
    return Api.Service.call(`${this._type}/${_id}`, 'DELETE', { key }).then((res) => self.transform(res));
  }
}

export default new UserDocumentModel();
