import {
  Alert,
  AnswerabilityList,
  Breadcrumbs,
  ClassEventTime,
  Clone,
  Confirm,
  LivePage,
  LiveQuestion,
  Picker,
  Promote,
  Prompt,
  SearchHelp,
  SubscriptionAARPHelp,
  SubscriptionDetails,
  SubscriptionHelp,
  TestPicker,
  Toast,
} from 'app/components/dialogs';

let jsDialog;

function open(Vue, options, component, props) {
  const ComponentClass = Vue.extend({ ...options, ...component });
  const instance = new ComponentClass({
    propsData: { visible: true, ...props },
  });
  return instance.$mount();
}

const Dialog = {
  install(Vue, options = {}) {
    // creates a instance method that can be used inside of a Vue component
    Vue.prototype.$dialog = {
      alert: async ({ title, html, text, cancelText, callback } = {}) => {
        return open(Vue, options, Alert, { title, html, text, cancelText, callback });
      },
      breadcrumbs: async ({ node } = {}) => {
        return open(Vue, options, Breadcrumbs, { node });
      },
      answerabilityList: async ({ state, label, model, headers, parent, parentSet, parentKey, parentType, childListKey, obj, onUpdate, answerabilityOf, callback, autoSave } = {}) => {
        return open(Vue, options, AnswerabilityList, {
          state,
          label,
          model,
          headers,
          parent,
          parentSet,
          parentKey,
          parentType,
          childListKey,
          obj,
          onUpdate,
          answerabilityOf,
          callback,
          autoSave,
        });
      },
      classEventTime: async ({ model, obj, callback } = {}) => {
        return open(Vue, options, ClassEventTime, { model, obj, callback });
      },
      clone: async ({ cloneKey, hasLinks, model, callback } = {}) => {
        return open(Vue, options, Clone, { cloneKey, hasLinks, model, callback });
      },
      confirm: async ({ title, html, text, confirmText, cancelText, color, callback, onCancel } = {}) => {
        return open(Vue, options, Confirm, { title, html, text, confirmText, cancelText, color, callback, onCancel });
      },
      livePage: async ({ node } = {}) => {
        return open(Vue, options, LivePage, { node });
      },
      liveQuestion: async ({ node } = {}) => {
        return open(Vue, options, LiveQuestion, { node });
      },
      picker: async ({ parent, subject, title, model, cloneable, linkable, multiselect, criteria, currentList, disabledItemList, tooltipForDisabledItems, callback } = {}) => {
        return open(Vue, options, Picker, { parent, subject, title, model, cloneable, linkable, multiselect, criteria, currentList, disabledItemList, tooltipForDisabledItems, callback });
      },
      promote: async ({ obj, model, hasLinks, promotionPermissions } = {}) => {
        return open(Vue, options, Promote, { obj, model, hasLinks, promotionPermissions });
      },
      prompt: async ({ title, html, text, placeholder, confirmText, cancelText, callback } = {}) => {
        return open(Vue, options, Prompt, { title, html, text, placeholder, confirmText, cancelText, callback });
      },
      searchHelp: async ({ model } = {}) => {
        return open(Vue, options, SearchHelp, { model });
      },
      toast({ text, timeout, color, canClose } = {}) {
        const ComponentClass = Vue.extend({ ...options, ...Toast });
        const instance = new ComponentClass({
          propsData: { text, timeout, color, canClose },
        });
        document.body.appendChild(instance.$mount().$el);
        instance.visible = true;
      },
      subscriptionHelp: async ({ title, html, text, confirmText, cancelText, color, callback, plans } = {}) => {
        return open(Vue, options, SubscriptionHelp, { title, html, text, confirmText, cancelText, color, callback, plans });
      },
      subscriptionAARPHelp: async ({ title, html, text, confirmText, cancelText, color, callback } = {}) => {
        return open(Vue, options, SubscriptionAARPHelp, { title, html, text, confirmText, cancelText, color, callback });
      },
      subscriptionDetails: async ({ title, html, text, confirmText, cancelText, color, callback, data } = {}) => {
        return open(Vue, options, SubscriptionDetails, { title, html, text, confirmText, cancelText, color, callback, data });
      },
      testPicker: async ({ testTemplates, onSearch, onClone } = {}) => {
        return open(Vue, options, TestPicker, { testTemplates, onSearch, onClone });
      },
    };
    jsDialog = Vue.prototype.$dialog;
  },
};

export { Dialog, jsDialog };
