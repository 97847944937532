<template>
  <span>
    <VMenu v-if="formatted !== 'Invalid date'" ref="isMenu" v-model="isMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
      <template #activator="{ on }">
        <VTextField
          v-model="formatted"
          :label="label"
          :readonly="readonly"
          :disabled="disabled"
          :prepend-inner-icon="calendarIcon"
          :hint="hint"
          persistent-hint
          :hide-details="!hint"
          outlined
          dense
          v-on="!readonly && on"
        ></VTextField>
      </template>
      <VDatePicker id="datePicker" v-model="displayDate" color="primary" :min="minDateSelection" :max="maxDateSelection" :disabled="disabled" @change="update"></VDatePicker>
      <VDialog v-model="showTimeSelection" max-width="290px">
        <VCard class="text-end">
          <VTimePicker v-model="time" class="rounded-0" @input="updateTime" />
          <VBtn dark color="primary" class="rounded-0 ma-2" @click="() => (showTimeSelection = false)">CONFIRM</VBtn>
        </VCard>
      </VDialog>
    </VMenu>
    <VMenu v-else ref="isMenu" v-model="isMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
      <template #activator="{ on }">
        <VTextField :label="label" :disabled="disabled" readonly :prepend-inner-icon="calendarIcon" :rules="[rules.required]" outlined dense v-on="on"></VTextField>
      </template>
      <VDatePicker id="datePicker" v-model="today" :min="minDateSelection" :max="maxDateSelection" color="primary" :disabled="disabled" @change="selectDate"></VDatePicker>
    </VMenu>
  </span>
</template>

<script lang="ts">
import Filters from 'app/lib/filters';
import moment from 'moment-timezone';

export default {
  name: 'Datepicker',
  props: {
    value: { type: String, default: '' },
    timezone: { type: String, default: 'America/Chicago' },
    label: { type: String, default: 'Date' },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    noHint: { type: Boolean, default: false },
    endOfDay: { type: Boolean, default: false },
    noTimezone: { type: Boolean, default: false },

    minDate: { type: String, default: '' },
    maxDate: { type: String, default: '' },
    inlineTime: { type: Boolean, default: false },

    // Use `no-time` to represent UTC dates that need no time
    // time will always default to UTC 00:00:00Z of date selected
    noTime: { type: Boolean, default: false },
    // Use `time-selection` to allow user to select time
    timeSelection: { type: Boolean, default: false },
  },

  data() {
    // If we show time, convert to timezone, otherwise assume a fixed UTC
    this.currentTimezone = this.noTime ? 'UTC' : this.timezone;
    const date = moment.tz(this.value, this.currentTimezone);
    return {
      date,
      minDateSelection: this.minDate ? moment.tz(this.minDate, this.currentTimezone).format().slice(0, 10) : '',
      maxDateSelection: this.maxDate ? moment.tz(this.maxDate, this.currentTimezone).format().slice(0, 10) : '',
      displayDate: date.format('YYYY-MM-DD'),
      isMenu: false,
      test: 'test',
      today: new Date().toISOString().substr(0, 10),
      time: date.format('HH:mm'),
      showTimeSelection: false,
      rules: {
        required: (v) => {
          if (this.required) {
            return (v != null && v !== '') || 'Field is required.';
          }
          return true;
        },
      },
    };
  },

  computed: {
    formatted() {
      if (!moment(this.displayDate).isValid()) {
        return 'Invalid date';
      }
      const date = this.inlineTime
        ? moment(this.displayDate).format('MM/DD/YY') + ' - ' + Filters.datetime(this.date, this.currentTimezone, 'shortTime', true).replace(/\s+/g, '') + ' (' + this.getTimezoneAbbreviation() + ')'
        : moment(this.displayDate).format('MM/DD/YYYY');
      return this.date ? date : '';
    },
    hint() {
      return this.noTime || this.noHint || this.inlineTime ? '' : Filters.datetime(this.date, this.currentTimezone, 'shortTime', this.noTimezone);
    },
    calendarIcon() {
      return this.inlineTime ? 'far fa-calendar' : 'fa fa-calendar-o';
    },
  },

  watch: {
    minDate() {
      this.minDateSelection = this.minDate ? moment.tz(this.minDate, this.currentTimezone).format().slice(0, 10) : '';
    },
    maxDate() {
      this.maxDateSelection = this.maxDate ? moment.tz(this.maxDate, this.currentTimezone).format().slice(0, 10) : '';
    },
    currentTimezone(value) {
      // If we show time, convert to timezone, otherwise assume a fixed UTC
      this.currentTimezone = this.noTime ? 'UTC' : this.currentTimezone;
      this.setDate(this.date, value);
    },
  },
  methods: {
    updateTime() {
      // Split the time into hour and minute
      const [hour, minute] = this.time.split(':');
      let finalDate = moment.tz(this.displayDate, this.currentTimezone);

      // Set the time on the date
      finalDate.set({ hour: parseInt(hour), minute: parseInt(minute) });
      finalDate = finalDate.toISOString(false);
      this.setDate(finalDate, this.currentTimezone);

      // Then we emit it back out so the proper UTC string is updated in core object
      this.$emit('input', finalDate);
    },
    update() {
      this.isMenu = false;
      // Because we are working with a timezoned date, but need to stitch back into the offset
      // This then means that we will convert it back up to a proper ISO after
      // If noTime is true, force time to always be 0 hour UTC
      const utcTime = this.endOfDay ? 'T23:59:59Z' : 'T00:00:00Z';
      const stitchedDate = this.displayDate + (this.noTime ? utcTime : this.date.toISOString(true).substr(10));
      const finalDate = moment(stitchedDate).toISOString(false);

      this.setDate(finalDate, this.currentTimezone);

      if (!this.noTime && this.timeSelection && this.time === 'Invalid date') {
        // If we are showing time and the time is invalid, we need to set it to the current time
        this.time = moment(this.date).format('HH:mm');
      }

      // Then we emit it back out so the proper UTC string is updated in core object
      this.$emit('input', finalDate);
      this.showTimeSelection = !this.noTime && this.timeSelection;
    },

    // If the field had been blank until this moment, we've gotta update extra things
    selectDate() {
      const nowUntrimmed = new Date().toISOString();
      const userDate = new Date(this.today).toISOString();
      const now = userDate.substr(0, 10);
      this.displayDate = moment(now).format('YYYY-MM-DD');
      this.date = moment.tz(nowUntrimmed, this.currentTimezone);
      this.update();
    },

    setDate(date, timezone) {
      this.date = moment.tz(date, timezone);
    },
    getTimezoneAbbreviation() {
      const now = moment.tz(this.timezone);
      return now.format('z');
    },
  },
};
</script>

<style lang="less" scoped>
/*TODO-AK 01-11-21 FYI this concept is deprecated in Vue 3 in favor of "::v-deep()" syntax*/
::v-deep(.v-text-field__details) {
  margin-top: -11px;
  z-index: 1;

  .v-messages {
    background: #fff;
    padding: 0 2px;
    flex: 0 1 auto;
  }
}
</style>
