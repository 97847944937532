import ModelFactory from 'app/model/_factory';
import Api from 'app/lib/api';

/**
 * Override the default Abstract.search() function
 * Perform an intelligent search to match against full emails and phone numbers
 * If CX has pasted a full version of either, ask API for an anchored search
 * This allows copy/paste searches to be 10 times faster
 *
 *
 * @param params
 */
class UserLegacyModel extends ModelFactory {
  constructor() {
    super({
      type: 'user-legacy',
      isAgnostic: true,
      defaultList: {},
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      so: {
        def: 'username',
        keys: {
          _id: ['i', 'id'],
          email: ['e', 'email', 'username'],
          firstName: ['f', 'first', 'firstname'],
          lastName: ['l', 'last', 'lastname'],
          phone: ['p', 'phone'],
          driversLicense: ['dl', 'license'],
          parentEmail: ['pe', 'parentemail'],
        },
        hints: {
          username:
            'A user search will search for the first characters in a username. To search for a username that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their username.',
          firstName:
            'A user search will search for the first characters in a first name. To search for a first name that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their first name.',
          lastName:
            'A user search will search for the first characters in a last name. To search for a last name that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *aceable will ' +
            'return all users with "aceable" anywhere in their last name.',
          phone:
            'A user search will search for the first characters in a phone number. To search for a phone number that ' +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *7563 will ' +
            'return all users with "7563" anywhere in their phone number.',
          driversLicense:
            "A user search will search for the first characters in a driver's license. To search for a driver's license that " +
            'contains certain characters, add an asterisk (*) to the front of the search term. Example, *7563 will ' +
            'return all users with "7563" anywhere in their driver\'s license.',
        },
        cols: {
          username: 'Email',
          firstName: 'First',
          lastName: 'Last',
          phoneNumber: 'Phone',
          productTitle: 'Course',
        },
      },
    });
  }

  search(params) {
    if (params.criteria.power) {
      const check = params.criteria.power;
      params.criteria.power = undefined;
      const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regPhone = /1?[( ./_-]{0,2}\d{3}[) ./_-]{0,2}\d{3}[) ./_-]?\d{4}/;
      if (regEmail.test(check)) {
        params.criteria.username = `^${check}`;
      } else if (regPhone.test(check)) {
        params.criteria.phone = `^${check.replace(/\D/g, '')}`;
      } else {
        params.criteria.username = check;
      }
    }
    return this.query(params);
  }

  searchProgress(query, limit) {
    return this.subquery('progress', null, { query, limit });
  }

  getDash(_id) {
    return this.subquery('dash', _id);
  }

  getStudents(_id) {
    return this.subquery('students', _id);
  }

  getStatus(_id, progressId) {
    return this.subquery('status', _id, { progressId });
  }

  generateCertificate(certId, obj) {
    return Api.Service.execute(this._type, 'generate-certificate', obj._id, { certId });
  }

  getTests(_id) {
    return this.subquery('tests', _id);
  }

  getPvqs(_id) {
    return this.subquery('pvqs', _id);
  }
}

export default new UserLegacyModel();
