import ModelFactory from 'app/model/_factory';

class ClassStaffModel extends ModelFactory {
  constructor() {
    super({
      type: 'class-staff',
      permType: 'classStaff',
      isAgnostic: true,
      defaultList: {
        firstName: '',
        lastName: '',
        contactEmail: '',
        paymentEmail: '',
        descriptionHtml: '',
        avatarUrl: '',
        roleList: [],
        licenseNumber: '',
        scheduleList: [],
        timeOffList: [],
        courseIdList: [],
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: 'contactEmail',
        keys: {
          _id: ['i', 'id'],
          firstName: ['f', 'first'],
          lastName: ['l', 'last'],
          contactEmail: ['c', 'contact'],
          paymentEmail: ['p', 'payment'],
        },
        cols: {
          firstName: 'First',
          lastName: 'Last',
          contactEmail: 'Contact',
          paymentEmail: 'Payment',
        },
      },
    });
  }
}

export default new ClassStaffModel();
