import ModelFactory from 'app/model/_factory';

class ProgressVerifyModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-verify',
      permType: 'userProgress',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }

  override(progressId) {
    return this.execute('override', null, { progressId });
  }

  getListByUserProgress(progressId) {
    return this.query({ criteria: { progressId, 'is.active': false }, sort: [['createdAt', -1]] });
  }
}

export default new ProgressVerifyModel();
