import App from 'app/lib/app';
import Auth from 'app/lib/auth';
import Permissions from 'app/lib/permissions';
import Search from 'app/lib/search';
import Util from 'app/lib/util';
import { countBy, get } from 'lodash-es';
import store from '../store';

const components = import.meta.glob(`../controller/**/*.vue`);

const Router = {
  routes: {},
  vueRouter: null,
  provider: null,
  state: {},
  $dirty: false,
  $willSave: false,

  setDocumentTitle({ params, name }) {
    const setId = params.id || '';
    const section = name.replace('_', '').split('.').map(Util.toTitleCase).join(' ');
    document.title = `JRV ${section} ${setId}`;
  },

  /**
   * @param {Id} brandId
   * @param initializer
   * @param VueRouter
   * @return {VueRouter}
   */
  build(brandId, initializer, VueRouter) {
    initializer(Router, Permissions);

    const vueRoutes = [];
    const keys = Object.keys(this.routes);
    const topKeys = keys.filter((key) => !key.includes('.'));
    const subKeys = keys.filter((key) => countBy(key)['.'] === 1);
    const finKeys = keys.filter((key) => countBy(key)['.'] === 2);
    let nextRoute = null;

    // Build all the individual routes
    // @see app/route
    topKeys.forEach((key) => {
      const match = this.routes[key];
      const path = match.url.replace('^/', '');
      const children = subKeys
        .filter((sub) => sub.startsWith(`${key}.`))
        .map((sub) => {
          const subMatch = this.routes[sub];
          const subPath = subMatch.url.replace(`^/${path}/`, '').split('?')[0];
          const finChildren = finKeys
            .filter((fin) => fin.startsWith(`${sub}.`))
            .map((fin) => {
              return {
                path: this.routes[fin].url.replace(`^/${path}/${subPath}/`, '').split('?')[0],
                name: fin,
                meta: this.routes[fin].meta,
                component: () => components[`../controller/${this.routes[fin].template}.vue`](),
                props: (route) => ({ ...this.routes[fin].props, ...route.query, context: sub }),
              };
            });

          return {
            path: subPath,
            name: sub,
            meta: subMatch.meta,
            component: () => (subMatch.template ? components[`../controller/${subMatch.template}.vue`]() : import('../components/layout/ace-router.vue')),
            children: finChildren.length ? finChildren : undefined,
            props: (route) => ({ ...subMatch.props, ...route.query, context: key }),
          };
        });

      vueRoutes.push({
        path,
        name: key,
        meta: match.meta,
        component: () => import('../components/layout/ace-router.vue'),
        children: children.length ? children : undefined,
      });
    });

    // Initialize the vue router
    this.vueRouter = new VueRouter({
      mode: 'history',
      base: `/@${brandId.toLowerCase()}/`,
      routes: [
        {
          path: '/',
          name: 'layout',
          component: () => import('../components/layout/index.vue'),
          props: true,
          children: [...vueRoutes, { path: '*', name: 'catchall', component: () => import('../controller/_home/dashboard.vue') }],
        },
      ],
    });

    // Handle Vite preload errors
    // These occur when you push a new deploy and the asset hashes update
    // The user won't be able to use SPA route navigation until they refresh the page
    // This tracks a route change started but also failed and forces a redirect
    // See: https://vite.dev/guide/build.html#load-error-handling
    // See this.vueRouter.beforeEach() for the nextRoute assignment
    window.addEventListener('vite:preloadError', () => {
      if (nextRoute) {
        window.location.assign(nextRoute);
      } else {
        // If we don't know route, set them up as stale for next click
        store.commit('version/isStale', true);
      }
    });

    /**
     * The critical loop called on each new route entry
     */
    this.vueRouter.beforeEach((to, from, next) => {
      // Used in case of vite:preloadError to force a redirect
      nextRoute = to.fullPath;
      if (store.getters['version/isStale']) {
        store.commit('version/isStale', false);
        window.location.assign(to.fullPath);
        next(false);
      } else if (to.fullPath.startsWith('/#!')) {
        // We must intentionally modify hash to support transition from Angular to Vue
        window.location.assign(to.fullPath.replace('#!', '@ace'));
        next(false);
      } else if (Auth.isAuthenticated()) {
        // An admin session exists, so compare the permission data with the route
        // Route permission are setup in the routes/ folder on each Router.vue() call
        const permission = get(to, 'meta.permission', '');

        if (to.name === 'catchall' || !permission || !Auth.isAuthorized(permission)) {
          if (to.name === 'catchall') {
            // eslint-disable-next-line no-console
            console.error('Missing route for', to.path);
          } else {
            // eslint-disable-next-line no-console
            console.error('Missing permission for route', to.name, permission ?? 'missing');
          }

          // Any bad routes while authenticated must be pushed to dashboard (within SAME brand)
          next(`/_home/dashboard`);
        } else {
          // Otherwise lets set the search context and let them go to route
          // TODO: Kevin - review whether this.state is still needed
          Search.setContext(to.name);
          App.syncVersion();
          this.state.current = to;
          this.state.params = to.params;
          Router.setDocumentTitle(to);
          next();
        }
      } else if (to.name !== '_home.signin' && to.name !== '_home.reset') {
        // All signed out users must be forced back to signin
        // as long as it isn't the base URL, we will offer a redirect to enter site on signin
        const redirect = window.location.href.replace(window.location.origin, '');
        window.location.assign(`/@/_home/signin${to.path !== '/' ? `?redirect=${redirect}` : ''}`);
      } else {
        // Everything looks good so lets continue to load requested page
        next();
      }
    });

    this.state = {
      go: ({ name, params, query }) => {
        this.vueRouter.push({ name, query, params });
      },
    };

    return this.vueRouter;
  },

  /**
   * A special passthru route used exclusively to connect a grandparent with its grandchild
   * For example, the top level 'learning' section needs a 'learning.bucketivity.list' and 'learning.bucketivity.edit'
   * This means an abstract route of 'learning.bucketivity' is needed (but it has no controller or template use cases)
   *
   * @param key
   */
  abstract(key) {
    this.routes[key] = { url: `^/${key.replace(/\./g, '/')}`, abstract: true };
  },

  /**
   * Use for high level sidebar sections that define the subnav bar of each category
   * @param key
   */
  subnav(key) {
    this.abstract(key);
  },

  /**
   * Used for all standard CRUDs where both List and Edit are VUE files
   * @param {string} parent 'learning'
   * @param {string} key 'bucketivity'
   * @param {void} [_ignore] @deprecated
   * @param {string} [customPermission] Use a different permission key than the key
   */
  // eslint-disable-next-line no-unused-vars
  vueCrud(parent, key, _ignore = false, customPermission = undefined) {
    const context = `${parent ? `${parent}.` : ''}${key}`; // 'learning.bucketivity'
    this.abstract(context);
    // In the case of voucherPartner, we need to use the same permissions that in voucherRequest
    const permissionKey = customPermission ?? key;
    this.vue(`${context}.list`, '?query&page&order&lp', `${permissionKey}.read`, `${parent || key}.index`, context);
    this.vue(`${context}.edit`, '/:id?parent&prefill&parenttype', `${permissionKey}.read`, `${context}.list`, context);
  },

  /**
   * The key must translate exactly to an existing file
   * TODO: Make sure you called Router.abstract() for any parents before setting these vue routes (IE: Router.abstract('learning.bucketivity'))
   * TODO: The Angular properties in URL become self.state.items which become $state.items in VUE
   *
   * @param key the dot notation file structure learning.bucketivity.edit for example becomes /controller/learning/bucketivitiy/edit
   * @param url additional router parameters to add to a key route (IE: '/:id?parent&prefill&parenttype')
   * @param permission
   * @param back Where to go when clicking back (IE: during an edit, go back to 'learning.bucketivity.list')
   * @param section the abstract section defining the groups of actions (IE: 'learning.bucketivity')
   * @param props
   * @returns {CombinedVueInstance<V extends Vue, Object, Object, Object, Record<never, any>>}
   */
  vue(key, url = '', permission = Permissions.all, back = undefined, section = undefined, props = {}) {
    const path = key.replace(/\./g, '/');
    this.routes[key] = {
      url: `^/${path}${url}`,
      template: path,
      props: {
        ...props,
        onFormUpdate: () => undefined,
        context: section,
      },
      meta: { permission, back, section },
    };
    return this;
  },

  vueIndex(key) {
    this.routes[`${key}.index`] = {
      url: `^/${key}/index`,

      template: `${key}/index`,
      props: {
        parent: key,
      },
      meta: { permission: Permissions[key].read, back: '_home.dashboard', section: key },
    };
  },

  getRouteByCollection(key) {
    return Object.keys(this.routes).find((route) => route.includes(key));
  },
};

export default Router;
