import ModelFactory from 'app/model/_factory';

class AddendumModel extends ModelFactory {
  constructor() {
    super({
      type: 'addendum',
      isAgnostic: true,
      defaultList: {},
      _id: '',
      courseId: '',
      _created_at: '',
      _updated_at: '',
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
        },
      },
    });
  }
}

export default new AddendumModel();
