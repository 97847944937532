import { findKey, indexOf, trim } from 'lodash-es';

const Search = {
  context: null,
  /**
   * @memberOf Search
   * @param query
   * @param keyList
   * @param defKey
   */
  parse(query, keyList, defKey) {
    // split query up by spaces, and if the first bit is and variation of 'qualityType:' convert second half to all caps to match DB and re-stringify
    const searchArray = query.split(' ');
    if (searchArray[0] === 'u:' || searchArray[0] === 'quality:' || searchArray[0] === 'qualityType:') {
      for (let i = 1; i < searchArray.length; i += 1) {
        searchArray[i] = searchArray[i].toUpperCase();
      }
      query = searchArray.join(' ');
    }
    const queryObj = {};
    if (query.indexOf(':') !== -1) {
      const re = /(?:([a-z]+): *)?(?:'([^']+)'|"([^"]+)"|([^ "']+))(?: *|$)/gi;
      let match = re.exec(query);

      while (match) {
        if (match[4] || match[3] || match[2]) {
          const search = trim(match[4] || match[3] || match[2]);

          if (match[1]) {
            const key = match[1].toLowerCase();
            const queryOp = findKey(keyList, (list) => indexOf(list, key) !== -1);

            if (queryOp) {
              if (queryObj[queryOp]) {
                // If key already exists, convert it to an array of values
                queryObj[queryOp] = [...(Array.isArray(queryObj[queryOp]) ? queryObj[queryOp] : [queryObj[queryOp]]), search];
              } else {
                queryObj[queryOp] = search;
              }
            }
          } else {
            queryObj[defKey] = search;
          }
        }

        match = re.exec(query);
      }
    } else if (query) {
      queryObj[defKey] = query;
    }

    return queryObj;
  },

  setContext(data) {
    Search.context = data;
  },
};

export default Search;
