import ModelFactory from 'app/model/_factory';

class BrandModel extends ModelFactory {
  constructor() {
    super({
      type: 'brand',
      keys: {
        parent: '',
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {},
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
          title: ['t', 'title'],
        },
        cols: {
          title: 'Title',
        },
      },
    });
  }
}

export default new BrandModel();
