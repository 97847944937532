import ModelFactory from 'app/model/_factory';

class VoucherPartnerModel extends ModelFactory {
  constructor() {
    super({
      type: 'voucherPartner',
      permType: 'voucherRequest', // TODO: Change to voucherPartner after permission is created
      isAgnostic: true,
      defaultList: {
        name: '',
      },
      so: {
        def: 'name',
        keys: {
          _id: ['i', 'id'],
          name: ['n', 'name'],
        },
        cols: {
          name: 'Name',
        },
      },
    });
  }
}
export default new VoucherPartnerModel();
