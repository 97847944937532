import ModelFactory from 'app/model/_factory';
// import Api from '../lib/api';

class ProgressModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress',
      isAgnostic: true,
      defaultList: {
        purchaseOptions: {
          voiceOver: false,
          studyTest: false,
          creditAssist: false,
          instructor: false,
        },
        isRandomAccess: false,
        piiMasked: {
          ssn: '',
        },
      },
      so: {},
    });
  }

  addControlCode(_id, progressCertificateId) {
    return this.execute('add-control-code', _id, { progressCertificateId });
  }

  addCourse(userId, courseId, accountType) {
    return this.execute('add-course', null, {
      userId,
      courseId,
      accountType,
    });
  }

  lock(_id, lockReason) {
    return this.execute('lock', _id, { lockReason });
  }

  setActive(_id, isActive) {
    return this.execute('active', _id, { isActive });
  }

  setComplete(_id, isComplete) {
    return this.execute('set-complete', _id, { isComplete });
  }

  toggleStatusCode(_id, type) {
    return this.execute('toggle-status-code', _id, {
      type,
    });
  }

  unlock(_id) {
    return this.execute('unlock', _id, {});
  }

  updateExternal(_id, isSpecialRequest) {
    return this.execute('update-external', _id, { isSpecialRequest });
  }

  // NS temporary comment
  completeCourse(progress, req) {
    const document = progress;
    document.progressId = progress._id;
    document.brandId = req.brandId;
    // The following line exists because CXI is looking for a property called "isComplete" and it wants it to be equal to what it is being switched to, rather than what progress.isComplete is currently equal to. Hence the reassignment here.
    document.isComplete = !(progress.isComplete && progress.isComplete === true);
    return this.execute('complete-course', null, { document });
  }

  submitGrades(userId, progress, req) {
    const document = progress;
    document.xgritUserId = userId;
    document.progressId = progress._id;
    document.brandId = req.brandId;
    return this.execute('send-grades', null, { document });
  }

  resetBiometrics(_id) {
    return this.execute('reset-biometrics', _id);
  }
}

export default new ProgressModel();
