import ModelFactory from 'app/model/_factory';
import Api from 'app/lib/api';

class AdminModel extends ModelFactory {
  constructor() {
    super({
      type: 'admin',
      isAgnostic: true,
      keys: {
        child: '',
        childList: '',
      },
      defaultList: {
        email: '',
        firstName: '',
        lastName: '',
        adminRole: {},
        isActive: true,
      },
      so: {
        def: 'email',
        keys: {
          defBrandId: ['b', 'brand'],
          email: ['e', 'email'],
          firstName: ['f', 'first'],
          lastName: ['l', 'last'],
          'adminRole.name': ['r', 'role'],
          isActive: ['i', 'enabled'],
        },
        cols: {
          email: 'Email',
          firstName: 'First Name',
          lastName: 'Last Name',
        },
      },
    });
  }

  signIn(email, password, brandId) {
    return this.execute('signin', null, { email, password, brandId });
  }

  getPermissionList() {
    const self = this;
    return Api.Service.call(`admin/request-permission`, 'GET').then((res) => self.transform(res));
  }

  requestPassword(email, brandId) {
    return this.execute('request-password', null, { email, brandId });
  }

  setPassword(_id, code, password, brandId) {
    return this.execute('set-password', _id, { code, password, brandId });
  }
}

export default new AdminModel();
