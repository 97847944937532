import ModelFactory from 'app/model/_factory';

class ExamModel extends ModelFactory {
  constructor() {
    super({
      type: 'exam',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  getListByUserProgress(progressId) {
    return this.query({ criteria: { progressId }, sort: [['startDate', -1]] });
  }

  override(userId, examId) {
    return this.execute('override', null, {
      userId,
      examId,
    });
  }

  refresh(userId, examId) {
    return this.execute('refresh', examId, {
      userId,
      examId,
    });
  }
}

export default new ExamModel();
