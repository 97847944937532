import ModelFactory from 'app/model/_factory';

class ClassAttendanceModel extends ModelFactory {
  constructor() {
    super({
      type: 'class-attendance',
      permType: 'classAttendance',
      isAgnostic: true,
      defaultList: {
        isProcessed: false,
        isVoided: false,
      },
      qo: {
        order: '_created_at',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
        },
        cols: {
          _updated_at: 'Uploaded At',
          webinarTitles: 'Webinar(s)',
          studentCount: 'Students',
          isProcessed: 'Processed',
          webinarTimes: 'Webinar Time(s)',
        },
      },
    });
  }
}

export default new ClassAttendanceModel();
