<template>
  <VDialog v-model="visible" max-width="1024px" @click:outside="cancel">
    <LiveQuestion :obj="node" />
  </VDialog>
</template>

<script lang="ts">
import LiveQuestion from 'app/components/content/liveQuestion.vue';

export default {
  name: 'LivePageDialog',
  components: {
    LiveQuestion,
  },
  props: {
    visible: { type: Boolean, default: false },
    node: { type: Object, default: () => undefined },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>

<style scoped></style>
