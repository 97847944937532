import BrandLib from 'app/lib/brand';
import Util from 'app/lib/util';
import axios from 'axios';
import { get } from 'lodash-es';

export interface EndpointApp {
  _id: string;
  app: string;
  env: string;
  order: number;
  repo: string;
  title: string;
  url: string;
  shareUrl?: string;
}

const ApiEndpoint = {
  /**
   * Look for available API endpoints along with pull requests
   * Then display a list so the client in Localhost, Sandbox, or Staging can access pull requests
   * @param host
   * @returns {*}
   */
  getEnv(host: string): string {
    let env: string;

    // support BRAND.ENV.jarvishq.com BRAND.jarvishq.com
    // Default legacy jarvis.aceable.com and jarvishq.com to ACE/PRD
    if (host.includes('jarvishq')) {
      const parts = host.split('.');
      env = parts.shift() as string;

      if (!['loc', 'stg', 'gvt', 'prd'].includes(env)) {
        env = 'prd';
      }

      return env;
    }

    if (host.includes('localhost')) {
      return 'loc';
    }
    if (host.includes('jarvis')) {
      return 'prd';
    }
    if (host.includes('gvtjarvis')) {
      return 'gvt';
    }

    env = host.replace(/^ace-cxi-(.*?).herokuapp.com$/, '$1');
    return env;
  },

  CONFIG: {
    basicAuth: 'Basic NjQ2MUZIMlRXODY1dnVzOXB4eDlvdzdCSzQ4Mlk3WFc6',
  },

  getBrandTopLevelDomain(brandId) {
    return Util.match(brandId, {
      AARP: 'aarpdriversafety.org',
      ACE: 'aceable.com',
      DEC: 'driversed.com',
      FLVS: 'flvs-driversed.com',
      IDS: 'idrivesafely.com',
      _: '',
    });
  },

  getWebApp(host) {
    const env = ApiEndpoint.getEnv(host);
    const brandId = BrandLib.getCurrentTabBrandId();
    const tld = ApiEndpoint.getBrandTopLevelDomain(brandId);
    return Util.match(env, {
      prd: `https://app.${tld}/`,
      gvt: `https://app-gvt.${tld}/`,
      loc: `https://localhost.${tld}:3030/`,
      _: `https://app-stg.${tld}/`,
    });
  },

  getServer({ env, title, api }): EndpointApp {
    return {
      _id: `DEFAULT${env}`,
      app: `ace-cxi-${env}`,
      env,
      order: 0,
      repo: 'cxi',
      title,
      url: api.baseUrl,
    };
  },

  async getReviewApps(repo = 'cxi'): Promise<EndpointApp[]> {
    const res = await axios.get('https://ace-cxi-stg.herokuapp.com/api/server', {
      params: {
        aceauth: 'Basic NjQ2MUZIMlRXODY1dnVzOXB4eDlvdzdCSzQ4Mlk3WFc6',
        _json: JSON.stringify({ criteria: { repo } }),
      },
    });

    return get(res, 'data.body', []);
  },

  async getServerList(env, conf): Promise<EndpointApp[]> {
    const defServer = ApiEndpoint.getServer(conf);

    try {
      let apiList = await ApiEndpoint.getReviewApps('cxi');
      apiList.unshift(defServer);

      if (env !== 'loc') {
        apiList = apiList.filter((a) => !['gvt', 'prd'].includes(a.env));
      }

      apiList = apiList.map((a) => ({ ...a, shareUrl: `${a.url}_home/signin?pr=${a.app}` })).sort((a, b) => (a.order >= b.order ? 1 : -1));
      return apiList;
    } catch {
      return [defServer];
    }
  },

  getCurrentApi: (env, list, pr) => (pr && list.find((r) => r.app === pr)) || list.find((r) => r.env === env) || list[0],
};

export default ApiEndpoint;
