import { isUndefined } from 'lodash-es';
import Store from 'store';

// FYI: This is setup as a tab persistent LocalStorage (NOT SessionStorage)
// See lib/brand.js for our per tab brandId SessionStorage usage
const Session = {
  data: [],

  get(key) {
    const root = Session.data[key];
    if (!isUndefined(root)) {
      return root;
    }

    Session.data[key] = Store.get(`session.${key}`);
    return Session.data[key];
  },

  set(key, value) {
    Session.data[key] = value;
    Store.set(`session.${key}`, value);
    return value;
  },

  destroyAll() {
    Session.data = [];
    Store.clearAll();
    return true;
  },
};

export default Session;
