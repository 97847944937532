import ModelFactory from 'app/model/_factory';

class PermissionsModel extends ModelFactory {
  constructor() {
    super({
      type: 'permissions',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }
}

export default new PermissionsModel();
