<template>
  <VDialog v-model="visible" max-width="1024px" @click:outside="cancel()">
    <AnswerabilityList
      :state="state"
      :label="label"
      :model="model"
      :headers="headers"
      :parent="parent"
      :parent-set="parentSet"
      :parent-key="parentKey"
      :parent-type="parentType"
      :child-list-key="childListKey"
      :obj="obj"
      :on-update="onUpdate"
      :answerability-of="answerabilityOf"
      :auto-save="autoSave"
    />
  </VDialog>
</template>

<script lang="ts">
import AnswerabilityList from 'app/components/content/answerability-list.vue';

export default {
  name: 'AnswerabilityListDialog',
  components: {
    AnswerabilityList,
  },
  props: {
    visible: { type: Boolean, default: false },
    state: { type: String, default: '' },
    label: { type: String, default: '' },
    model: { type: Object, default: () => undefined },
    headers: { type: Array, default: () => [] },
    parent: { type: String, default: '' },
    parentSet: { type: String, default: '' },
    parentKey: { type: String, default: '' },
    parentType: { type: String, default: '' },
    childListKey: { type: String, default: '' },
    obj: { type: Object, default: () => undefined },
    onUpdate: { type: Function, default: () => undefined },
    answerabilityOf: { type: String, default: '' },
    autoSave: { type: Boolean, default: false },
    callback: { type: Function, default: () => undefined },
  },
  watch: {
    $route() {
      this.cancel();
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.callback();
      this.$destroy();
    },
  },
};
</script>

<style scoped></style>
