import ModelFactory from 'app/model/_factory';

class AdminRoleModel extends ModelFactory {
  constructor() {
    super({
      type: 'admin-role',
      permType: 'adminRole',
      keys: {
        child: '',
        childList: '',
      },
      isAgnostic: true,
      defaultList: {
        _id: '',
        name: '',
        isConusUserViewingBlocked: false,
        permissionList: [],
      },
      so: {
        def: 'name',
        keys: {
          name: ['n', 'name'],
          _id: ['i', 'id'],
        },
        cols: {
          _id: 'ID',
          name: 'Name',
        },
      },
    });
  }

  clone(id, name) {
    return this.execute('clone', id, { name });
  }
}

export default new AdminRoleModel();
