<template>
  <VSnackbar v-model="visible" :color="`var(--v-${color}-base)`" bottom right :timeout="timeout" multi-line>
    {{ text }}
    <VBtn v-if="canClose" text @click="cancel">Close</VBtn>
  </VSnackbar>
</template>

<script lang="ts">
export default {
  name: 'Toast',
  props: {
    visible: { type: Boolean, default: false },
    timeout: { type: Number, default: 6000 },
    text: { type: String, default: '' },
    color: { type: String, default: 'text' },
    canClose: { type: Boolean, default: true },
  },
  methods: {
    cancel() {
      this.visible = false;
    },
  },
};
</script>
