import ModelFactory from 'app/model/_factory';

class ActivityCategoryModel extends ModelFactory {
  constructor() {
    super({
      type: 'activity-category',
      permType: 'activityCategory',
      isAgnostic: true,
      defaultList: {
        _id: '',
        title: '',
        description: '',
        requiredMinutes: 0,
        maxDailyMinutes: 0,
        subcategoryIdList: [],
        subcategoryList: [],
      },
      so: {
        def: '_id',
        keys: {
          _id: ['i', 'id'],
          title: ['t', 'title'],
        },
        cols: {
          _id: 'ID',
          title: 'Title',
        },
      },
    });
  }
}

export default new ActivityCategoryModel();
