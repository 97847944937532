import ModelFactory from 'app/model/_factory';

class ReportModel extends ModelFactory {
  constructor() {
    super({
      type: 'report',
      isAgnostic: true,
      defaultList: {
        criteria: {
          courseList: [],
          certificateList: [],
        },
        columnList: [],
      },
      so: {},
    });
  }

  run(id) {
    return this.subquery('run', id, {});
  }
}

export default new ReportModel();
