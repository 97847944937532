import ModelFactory from 'app/model/_factory';

class ProgressCasestudyModel extends ModelFactory {
  constructor() {
    super({
      type: 'progressCasestudy',
      isAgnostic: true,
      defaultList: {
        answer: '',
        gradePercent: 0,
      },
      so: {
        def: 'casestudyId',
        keys: {
          title: ['t', 'title'],
          questionHtml: ['q', 'question'],
          explanation: ['e', 'explanation'],
        },
        cols: {
          title: 'Title',
          question: 'Question',
          explanation: 'Explanation',
        },
      },
    });
  }
}

export default new ProgressCasestudyModel();
