<template>
  <VDialog v-model="visible" max-width="90%" @click:outside="cancel">
    <VCard>
      <VToolbar id="searchToolBar" :data-test="'link' + subject + 'Toolbar'" color="primary" dark>
        <VToolbarTitle>Link {{ title || subject }}</VToolbarTitle>
        <VSpacer />
        <VBtn icon @click="cancel">
          <VIcon>fa-times</VIcon>
        </VBtn>
      </VToolbar>
      <VFlex class="headerFlex">
        <VCombobox
          :items="resList"
          :search-input.sync="query"
          append-icon=""
          append-outer-icon="fa fa-info-circle"
          prepend-inner-icon="fa fa-search"
          background-color="background"
          clearable
          flat
          solo
          hide-details
          hide-selected
          item-text="label"
          item-value="key"
          autofocus
          data-test="searchField"
          :placeholder="`Search ${pluralPlaceholder}`"
          @click:append-outer="callSearchHelp"
          @keyup.enter="runQuery()"
        />
        <b v-if="Boolean(typeof total === 'number')" id="results"> {{ total }} Results </b>
      </VFlex>
      <VFlex id="resTable">
        <Loading />
        <a v-if="selected.length" class="clearResults" @click="clearSelected()"> Clear All ({{ selected.length }})</a>
        <VDataTable
          v-if="list.length"
          v-model="selected"
          data-test="dataTable"
          :headers="headers"
          :items="list"
          item-key="_id"
          single-select
          show-select
          :options.sync="options"
          :headers-length="headers.length"
          hide-default-footer
          class="resultsTable"
        >
          <!--TODO-VUE Come back and refactor these slot templates to be in their own components-->
          <template #header.data-table-select="{}">
            <VTooltip right>
              <template #activator="{ on }">
                <div v-on="on">
                  <VCheckbox v-model="isAllSelected" class="pl-0 mt-3 mr-2" dense color="primary" @change="selectAllCurrentPage($event)" />
                </div>
              </template>
              <span>Select All On Page</span>
            </VTooltip>
          </template>
          <template slot="item" slot-scope="{ isSelected, item }">
            <VTooltip left>
              <template #activator="{ on }">
                <tr v-on="tooltipForDisabledItems && item.disabledSelection && on">
                  <td>
                    <VSimpleCheckbox :value="isSelected" primary hide-details data-test="resultCheckbox" :disabled="item.disabledSelection" @input="selectItem(item)" />
                  </td>
                  <td v-for="(obj, index) in headers" :key="index" class="ace-data" :class="{ 'grey--text': item.disabledSelection }">
                    <span>
                      {{ getValue(item, obj.value, obj.formatValue) }}
                    </span>
                  </td>
                </tr>
              </template>
              <span data-test="tooltipForDisabledItems">{{ tooltipForDisabledItems }} </span>
            </VTooltip>
          </template>
        </VDataTable>
        <VLayout v-if="list.length" class="pa-3 justify-center align-center fill-height" row>
          <VPagination :value="pageInteger" :total-visible="8" color="primary" :length="paginationLength" @input="next" />

          <i>{{ paginationRange }} of {{ list.length }} items</i>
        </VLayout>
      </VFlex>
      <VFlex v-if="Boolean(selected.length)" id="footerContainer" class="align-center">
        <VLayout id="footer" align-center justify-end>
          <div id="cancelContainer">
            <h3 @click="cancel">Cancel</h3>
          </div>
          <div v-if="cloneable" class="linkContainer">
            <VBtn depressed color="primary" class="linkButton" :disabled="Boolean(!selected.length)" data-test="cloneButton" @click="linkOrClone(true)">
              <VIcon left>fa fa-clone</VIcon>&nbsp; Clone {{ selected.length }} to {{ parentType }}
            </VBtn>
          </div>
          <div v-if="linkable" class="linkContainer">
            <VBtn depressed color="primary" class="linkButton" :disabled="Boolean(!selected.length)" data-test="linkButton" @click="linkOrClone(false)">
              <VIcon left>fa fa-link</VIcon>&nbsp; Link {{ selected.length }} to {{ parentType }}
            </VBtn>
          </div>
        </VLayout>
      </VFlex>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
import Loading from 'app/components/layout/loading.vue';
import Filters from 'app/lib/filters';
import Html from 'app/lib/html';
import Search from 'app/lib/search';
import { findIndex, get } from 'lodash-es';

export default {
  components: { Loading },
  props: {
    visible: { type: Boolean, default: false },
    parent: { type: String, default: '' },
    subject: { type: String, default: 'Page' },
    title: { type: String, default: '' },
    model: { type: Object, default: () => undefined },
    cloneable: { type: Boolean, default: true },
    linkable: { type: Boolean, default: true },
    multiselect: { type: Boolean, default: true },
    criteria: { type: Object, default: () => undefined },
    currentList: { type: Array, default: () => [] },
    disabledItemList: { type: Array, default: () => [] },
    tooltipForDisabledItems: { type: String, default: '' },
    callback: { type: Function, default: () => undefined },
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      selected: [],
      pageSelection: {}, // This will store the pages that have all items selected
      query: '',
      resList: [],
      list: [],
      total: '',
      context: Search.context,
      multilink: false,
      objList: [],
      searchObj: {},
      linksToAdd: [],
      loading: false,
    };
  },
  computed: {
    paginationRange() {
      const start = (this.options.page - 1) * 10 + 1;
      let end;
      if (parseInt(this.options.page, 10) === Math.ceil(this.list.length / 10)) {
        end = this.list.length;
      } else {
        end = start + 9;
      }
      return `${start} - ${end}`;
    },
    headers() {
      // This function grabs the header values for all columns in the picker, and appends the ID column
      const headers = [
        {
          text: 'ID',
          value: '_id',
        },
      ];
      Object.entries(this.model.so.cols).map(([key, value]) => {
        const formattedValue = typeof value === 'object' ? value?.header : value;
        // Include a custom formatValue function if it exists
        const header = { text: formattedValue, value: key, ...(value?.formatValue && { formatValue: value.formatValue }) };
        headers.push(header);
        return header;
      });
      return headers;
    },
    parentType() {
      if (this.parent === 'testObjective') {
        return 'Test Objective';
      }
      if (this.parent === 'voucherRequest') {
        return 'Voucher';
      }
      return this.parent.charAt(0).toUpperCase() + this.parent.slice(1).toLowerCase();
    },
    pluralPlaceholder() {
      if (this.subject.charAt(this.subject.length - 1) === 'y') {
        return this.subject.replace('y', 'ies');
      }
      return `${this.subject}s`;
    },
    pageInteger() {
      return parseInt(this.options.page, 10);
    },
    paginationLength() {
      return Math.ceil(this.list.length / 10);
    },
    isAllSelected: {
      get() {
        return this.pageSelection[this.options.page] ? this.pageSelection[this.options.page] : false;
      },
      set(value) {
        this.$set(this.pageSelection, this.options.page, value);
      },
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
    next(e) {
      this.options.page = e;
    },
    clearSelected() {
      this.selected = [];
      this.pageSelection = {};
    },
    callSearchHelp() {
      this.$dialog.searchHelp({ model: this.model });
    },

    linkOrClone(isClone) {
      this.callback({
        isSuccess: true,
        selected: this.selected,
        isClone,
      });
      this.cancel();
    },

    /**
     * Renders the display in the search picker of top X results
     * Some data requires additional formatting before it is outputted with formatValue
     *
     * @param {Object} obj - The object from which the value is retrieved.
     * @param {string} key - The key used to access the value in the object.
     * @param {Function} [formatValue] - An optional function to format the retrieved value.
     * @returns {string} - The formatted or raw value from the object.
     */
    getValue(obj, key, formatValue) {
      const value = get(obj, key, '');
      if (key === 'is.active') return Filters.yesno(get(obj, key));
      if (key === 'createdAt') return Filters.datetime(value);
      if (formatValue) return formatValue(value);
      return Filters.truncate(value, 400);
    },
    async runQuery() {
      this.loading = true;
      if (this.query === null || this.query === undefined) {
        this.query = '';
      }
      const queryStr = this.query;
      const queryObj = Search.parse(queryStr, this.model.so.keys, this.model.so.def);

      this.list = await this.model.search({
        criteria: { ...queryObj, ...this.criteria },
        limit: 500,
      });
      await this.createMarkdown();
      this.list = this.list.filter((item) => this.currentList.findIndex((d) => (this.parent === 'Chapter' ? d.linkId === item._id : d._id === item._id)) === -1);
      this.list = this.list.map((item) => {
        if (this.disabledItemList.indexOf(item._id) > -1) {
          return { ...item, disabledSelection: true };
        }
        return item;
      });
      this.total = this.list.length;
      this.selected = [];
      this.pageSelection = {};
      this.loading = false;
      return this.list;
    },
    createMarkdown() {
      for (let i = 0; i < this.list.length; i += 1) {
        const page = this.list[i];
        this.list[i].markdown = page.html ? Html.toMarkdown(page.html, page.title) : this.list[i].markdown;
      }
    },
    getCurrentPageItems() {
      const start = (this.options.page - 1) * this.options.itemsPerPage;
      const end = start + this.options.itemsPerPage;
      return this.list.slice(start, end);
    },
    selectItem(item) {
      const itemIndex = findIndex(this.selected, ['_id', item._id]);
      if (itemIndex > -1) {
        this.selected.splice(itemIndex, 1);
      } else {
        if (!this.multiselect) {
          this.clearSelected();
        }
        this.selected.push(item);
      }
      const currentPageItems = this.getCurrentPageItems();
      const allSelected = currentPageItems.every((item) => this.selected.includes(item));
      this.isAllSelected = allSelected;
    },
    selectAllCurrentPage(event) {
      const currentPageItems = this.getCurrentPageItems();
      if (event) {
        currentPageItems.forEach((item) => {
          if (!item.disabledSelection && !this.selected.includes(item)) {
            this.selected.push(item);
          }
        });
      } else {
        this.selected = this.selected.filter((item) => !currentPageItems.includes(item));
      }
    },
  },
};
// TODO-VUE add multilink function when we add chapter CRUD with pages
</script>
<style scoped type="less">
#searchToolBar {
  position: sticky;
  top: 0;
  z-index: 10;
}
#results {
  color: var(--v-text-base);
}
#clearSelected {
  padding: 0;
}
#resTable {
  overflow-y: scroll;
  width: 100%;
  background-color: var(--v-white-base);
}
.resultsTable {
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
}
#footerContainer {
  background-color: var(--v-white-base);
  position: sticky;
  z-index: 11;
  bottom: 0;
  width: 100%;
}
#footer {
  width: 90%;
  margin: 0 auto;
  padding: 25px 0;
  background-color: var(--v-white-base);
}
#cancelContainer {
  padding-right: 1.5em;
  cursor: pointer;
}
.linkContainer {
  padding-left: 1.5em;
}
.linkButton.v-btn.theme--light {
  margin: 0;
  border-radius: 2px;
  background-color: var(--v-primary-base);
  color: var(--v-white-base);
}
.v-text-field.v-text-field--enclosed {
  background-color: var(--v-white-base);
}
.v-input--selection-controls {
  margin-top: 0;
}
.headerFlex {
  padding: 2em 0 1em 0;
  width: 90%;
  margin: 0 auto;
}
.clearResults {
  margin-left: calc(5% + 16px);
  font-weight: bold;
  font-size: 12px;
}
</style>
