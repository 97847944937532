import { flatMap, flow, groupBy, isEqual, omit } from 'lodash-es';

/**
 * TriggerMap maps our db/enum trigger values to human readable strings;
 */
export const triggerMap = new Proxy(
  {
    SIDEBAR: 'Sidebar',
    '0.0.0': 'Post Signup',
    POST_PURCHASE: 'Post Purchase',
  },
  {
    get: (target, name) => {
      if (target[name]) return target[name];
      let [level] = name.split('.');
      try {
        level = Number.parseInt(level, 10);
        return `End of Level ${level}`;
      } catch {
        return `Yikes. Bad trigger ${target.name}`;
      }
    },
  },
);

/**
 * Given a flat list of screens, this func groups it by screens that are triggered at the same point. You end up
 * with an array of objs, each of which have a `trigger`, and a sublist of screens.  For rendering!
 */
export const screenListsByTriggerPointUnsorted = (screenList = []) =>
  screenList.reduce((acc, screen) => {
    if (acc.some((triggerGroup) => triggerGroup.trigger === triggerMap[screen.trigger])) return acc;

    return [
      ...acc,
      {
        trigger: triggerMap[screen.trigger],
        screenList: screenList
          .filter((s) => s.trigger === screen.trigger)
          .map((s, byTriggerIdx, byTriggerArr) => ({
            ...s,
            screenIdx: screenList.findIndex((ss) => isEqual(ss, s)),
            position: `(${byTriggerIdx + 1} of ${byTriggerArr.length})`,
            byTriggerIdx: byTriggerIdx + 1,
          })),
      },
    ];
  }, []);

/**
 * Trigger groups are great and all, but they make a lot more sense if they're shown in the order they're rendered in the
 * app.  This func takes the list of trigger groups from 👆 and orders them in 'app order' by comparing the trigger of
 * the first screen of each group. (That's all we need since all the screens in each group have the same trigger.)
 */
export const sortTriggerGroupList = (triggerGroupList = []) =>
  [...triggerGroupList].sort(({ screenList: [{ trigger: triggerA }] }, { screenList: [{ trigger: triggerB }] }) => {
    if (triggerA === triggerB) return 0;
    switch (triggerA) {
      case 'SIDEBAR':
        return -1;
      case '0.0.0':
        return 0;
      case 'POST_PURCHASE':
        return triggerB === '0.0.0' ? 1 : -1;
      default:
        return triggerA.localeCompare(triggerB, undefined, { numeric: true, sensitivity: 'base' });
    }
  });

/**
 * Given a screen list, this func composes the two above together and runs it through the gauntlet!
 */
export const screenListsByTriggerPoint = (screenList = []) => sortTriggerGroupList(screenListsByTriggerPointUnsorted(screenList));

/**
 * This func uses everything we've written so far reproduce a flat list of screens, sorted by idx + triggerGroup.  This
 * is what is actually persisted to the DB.
 */
export const getSortedScreenList = flow(screenListsByTriggerPointUnsorted, sortTriggerGroupList, (tgl) => flatMap(tgl, 'screenList'));

export default {
  screensByTriggerPoint: (state) => groupBy(state.screenList, 'trigger'),
  screenListsByTriggerPointUnsorted: ({ screenList }) => screenListsByTriggerPointUnsorted(screenList),
  screenListsByTriggerPoint: ({ screenList }) => screenListsByTriggerPoint(screenList),
  sortedScreens: ({ screenList = [] }) => getSortedScreenList(screenList),
  anySelected: (state) => state.screenList.some((s) => s.selected),
  screenListForSave: (state) => state.screenList.map((screen) => omit(screen, ['selected', 'screenIdx', 'position', 'byTriggerIdx'])),
};
