<template>
  <VCard class="content" data-test="preview">
    <div id="course" class="template-vue">
      <div v-if="previewMobile" class="mobile">
        <VLayout class="topBar" row align-center>
          <VIcon> fa fa-signal </VIcon>
          Yeet Wireless
          <VSpacer />
          <span />
          <VSpacer />
          60% &nbsp;
          <VIcon> fa fa-battery-full </VIcon>
        </VLayout>
        <VLayout class="courseNavBar" row align-center>
          <VIcon> fa fa-volume-off </VIcon>
          &nbsp; &nbsp;
          <VIcon> fa fa-font </VIcon>
          <VSpacer />
          <VIcon> fa-times </VIcon>
        </VLayout>
        <div class="template-blk-vue" :class="{ [templateStyle]: true }">
          <div class="headImage-vue">
            <video v-if="isRichMedia" ref="Player" class="video-video-vue video-js" :options="videoOptions" :poster="formatPoster(obj)" data-setup="{}">
              <track kind="captions" :src="subtitleUrl" srclang="en" label="English" />
            </video>
            <a v-if="isChart && obj.fileUrl" :href="obj.fileUrl" target="_blank" class="template-bg-vue">
              <img :src="formatImage(obj.imageUrl, obj.image2xUrl)" alt="chart" style="width: auto; max-width: 100%" />
            </a>
            <div v-if="!obj.fileUrl && !isRichMedia" class="template-bg-vue" :style="{ 'background-image': 'url(' + formatImage(obj.imageUrl, obj.image2xUrl) + ')' }" />
          </div>
          <div class="template-content-vue">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="obj.html" />
          </div>
        </div>
      </div>

      <!-- DESKTOP VIEW ^^ -->
      <!-- MOBILE VIEW vv -->

      <div v-if="!previewMobile" class="web">
        <div class="template-blk-vue" :class="{ [templateStyle]: true }">
          <div class="aspectRatio">
            <video v-if="isRichMedia" ref="Player" class="video-video-vue video-js" :options="videoOptions" :poster="formatPoster(obj)" data-setup="{}">
              <track kind="captions" :src="subtitleUrl" srclang="en" label="English" />
            </video>
            <a v-if="isChart && obj.fileUrl" :href="obj.fileUrl" target="_blank" class="template-bg-vue">
              <img :src="formatImage(obj.imageUrl, obj.image2xUrl)" alt="chart" style="width: auto; max-width: 100%" />
            </a>
            <div v-if="!obj.fileUrl && !isRichMedia" class="template-bg-vue" :style="{ 'background-image': 'url(' + formatImage(obj.imageUrl, obj.image2xUrl) + ')' }" />
            <div class="template-content-vue">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="obj.html" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script lang="ts">
import { DESIGN_URL_PREFIX } from 'app/lib/mediaConfig';
import Nav from 'app/lib/nav';
import VideoJs from 'video.js';

export default {
  name: 'LivePage',
  props: {
    obj: { type: Object, default: () => undefined },
    onUpdate: { type: Function, default: () => undefined },
  },
  data() {
    return {
      backgroundImage: '',
      templateClassList: ['template-1', 'template-2', 'template-3', 'template-4', 'template-5', 'template-6'],
      previewMobile: Nav.getContentPagePreviewMobile(),
    };
  },
  computed: {
    fileUrl() {
      return this.obj.fileUrl ? this.obj.fileUrl.replace('http://', 'https://') : null;
    },
    templateIndex() {
      return this.obj.template.replace('SCREEN_TEMPLATE_', '');
    },
    templateStyle() {
      return `template-${this.templateIndex.toLowerCase()}`;
    },
    isChart() {
      return ['6'].includes(this.templateIndex);
    },
    isRichMedia() {
      return ['5', 'AUDIO'].includes(this.templateIndex);
    },
    videoOptions() {
      let streamUrl = this.obj.streamUrl || 'https://dj83lk1nwmqdc.cloudfront.net/no-video/';
      if (!streamUrl.endsWith('/')) {
        streamUrl = streamUrl.concat('/');
      }
      return {
        controls: true,
        sources: [
          {
            src: `${streamUrl}index.m3u8`,
            type: 'application/x-mpegurl',
          },
          {
            src: `${streamUrl}index.webm`,
            type: 'video/webm',
          },
          {
            src: `${streamUrl}mpegdash.mpd`,
            type: 'application/dash+xml',
          },
          {
            src: `${streamUrl}index.mp4`,
            type: 'video/mp4',
          },
        ],
      };
    },
    subtitleUrl() {
      let subtitleUrl;
      if (this.obj.subtitleUrl) {
        const regex = /\.srt/gi;
        subtitleUrl = this.obj.subtitleUrl.replace(regex, '.vtt');
      }
      return subtitleUrl || null;
    },
  },
  mounted() {
    if (this.isRichMedia) {
      VideoJs(this.$refs.Player, this.videoOptions);
    }
  },
  methods: {
    formatImage(imageUrl, image2xUrl) {
      const url = image2xUrl || imageUrl;
      return url ? url.replace('http://', 'https://') : null;
    },
    formatPoster({ imageUrl, image2xUrl }) {
      return this.formatImage(imageUrl, image2xUrl) || `${DESIGN_URL_PREFIX}ace-video.png`;
    },
    async togglePreview(isMobile) {
      this.previewMobile = isMobile;
      Nav.setContentPagePreviewMobile(this.previewMobile);
    },
  },
};
</script>

<style lang="less" scoped>
div.tableWrapper table {
  border: 1px solid var(--v-text-base);
}
.content {
  margin: 10px;
  position: relative;
}

.ace-icon--medium {
  font-size: 1.4286em;
}

.ace-approval {
  height: 0;
  opacity: 0.5;
  border-right: 4em solid var(--v-warning-base);
  border-bottom: 4em solid transparent;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;

  i.v-icon {
    position: absolute;
    font-size: 2em;
    margin-top: 2px;
    margin-right: -25px;
    right: 0;
    color: var(--v-white-base);
    z-index: 6;
  }
}

.video-js ::v-deep(.vjs-big-play-button) {
  font-size: 5em;
  width: 80px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
  border: 0.03em solid;
}
</style>
