<template>
  <VDialog v-model="visible" width="500" @click:outside="cancel">
    <VCard>
      <VToolbar :color="color" dark>
        <VToolbarTitle>
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <div class="pa-5">
        {{ text }}
        <br />
        <br />
        <VTextField id="memberId" v-model="memberId" data-test="memberId" label="Member ID" />

        <input id="isVerified" v-model="isVerified" type="checkbox" :value="isVerified" :checked="isVerified" />
        <label for="isVerified"> Verified </label>
      </div>
      <VCardActions>
        <VSpacer xs9 />
        <VBtn color="warning" text data-test="confirmButton" @click.stop="confirm">
          {{ confirmText }}
        </VBtn>
        <VBtn color="readonly" text @click.stop="cancel">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: 'Please Confirm' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Do you approve?' },
    confirmText: { type: String, default: 'Yes' },
    cancelText: { type: String, default: 'No, Cancel' },
    color: { type: String, default: 'warning' },
    memberId: { type: String, default: '' },
    isVerified: { type: Boolean, default: false },
    callback: { type: Function, default: () => undefined },
  },
  methods: {
    confirm() {
      this.callback({ memberId: this.memberId, isVerified: this.isVerified });
      this.visible = false;
      this.$destroy();
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped></style>
