<template>
  <VDialog v-model="visible" max-width="1024px" @click:outside="cancel()">
    <Breadcrumbs :obj="node" />
  </VDialog>
</template>

<script lang="ts">
import Breadcrumbs from 'app/components/content/breadcrumbs.vue';

export default {
  name: 'BreadcrumbsDialog',
  components: {
    Breadcrumbs,
  },
  props: {
    visible: { type: Boolean, default: false },
    node: { type: Object, default: () => undefined },
  },
  watch: {
    $route() {
      this.cancel();
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>

<style scoped></style>
