import ModelFactory from 'app/model/_factory';

class CredentialsModel extends ModelFactory {
  constructor() {
    super({
      type: 'credentials',
      isAgnostic: true,
      defaultList: {
        _id: '',
        key: '',
        title: '',
        value: {
          username: '',
          // do not include password in the model, send it when necessary separately.
        },
        nextPasswordChangedDate: '',
        lastPasswordChangedDate: '',
        lastPasswordChangedUser: '',
        passwordChangePeriod: {
          period: 0,
          measure: '',
        },
        passwordChangeReminderDaysBefore: 0,
        passwordChangeReminderList: [],
      },
      qo: {
        order: '_id',
        limit: 50,
        limitOpts: [50],
        page: 1,
        total: 0,
      },
      so: {
        def: '_id',
        keys: {
          _id: '_id',
        },
      },
    });
  }
}
export default new CredentialsModel();
