import ModelFactory from 'app/model/_factory';

class TemplateModel extends ModelFactory {
  constructor() {
    super({
      type: 'template',
      isAgnostic: true,
      defaultList: {
        type: 'WELCOME',
        content: {
          title: '',
          description: '',
        },
        asset: {
          logo: '',
        },
        linkList: [],
        order: 0,
        filter: {
          appType: 'DE',
          mode: 'ONBOARD_WELCOME',
          key: '',
        },
        is: {
          active: true,
        },
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          type: ['t', 'type'],
          'filter.type': ['c', 'course'],
          'filter.appType': ['a', 'appType'],
          'filter.state': ['s', 'state'],
          'filter.variant': ['v', 'variant'],
          'filter.key': ['k', 'key'],
          'content.title': ['l', 'title'],
          'filter.mode': ['m', 'mode'],
        },
        cols: {
          mode: 'Mode',
          type: 'Type',
          title: 'Title',
        },
      },
    });
  }
}

export default new TemplateModel();
