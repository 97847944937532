export default function (Router) {
  Router.abstract('platform');

  Router.vueIndex('platform');

  Router.vueCrud('platform', 'product', true);

  Router.vueCrud('platform', 'productLine', true);

  Router.vueCrud('platform', 'template', true);

  Router.vueCrud('platform', 'email', true);

  Router.vueCrud('platform', 'journey', true);

  Router.vueCrud('platform', 'coupon', true);

  Router.vueCrud('platform', 'voucherPartner', true, 'voucherRequest');

  Router.vueCrud('platform', 'voucherRequest', true);

  Router.vueCrud('platform', 'voucher', true);

  Router.vueCrud('platform', 'productTag', true);
}
