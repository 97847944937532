import ModelFactory from 'app/model/_factory';

class PurchaseModel extends ModelFactory {
  constructor() {
    super({
      type: 'purchase',
      isAgnostic: false,
      defaultList: {},

      so: {},
    });
  }

  addProduct(productId, userProgressId) {
    return this.execute('add-product', null, {
      productId,
      userProgressId,
    });
  }

  getListByUser(userId) {
    return this.query({ userId });
  }

  getListByUserProgres(userProgressId) {
    return this.query({ userProgressId });
  }

  toggle(_id, isActive) {
    return this.execute('toggle', _id, { isActive });
  }
}

export default new PurchaseModel();
