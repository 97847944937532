export default {
  namespaced: true,
  state: {
    progressId: null,
  },
  mutations: {
    setProgressId(state, progressId) {
      this.state.progressId = progressId;
    },
  },
};
