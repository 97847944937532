export default {
  namespaced: true,
  state: {
    list: [],
    nodeIndex: -1,
    nextId: '',
    prevId: '',
    parentId: '',
    parentName: '',
    parentSet: '',
  },
  mutations: {
    setNav(state, Nav = {}) {
      state.nextId = Nav.nextId;
      state.prevId = Nav.prevId;
      state.parentId = Nav.parentId;
      state.parentName = Nav.parentName;
      state.parentSet = Nav.parentSet;
    },

    updateLinkList(state, { listItem, operation }) {
      if (operation === 'link') {
        state.list = state.list.push(listItem);
      } else if (operation === 'unlink') {
        state.list = state.list.splice(listItem, 1);
      }
    },
    selectNodeIndex(state, idx) {
      state.nodeIndex = idx;
    },
  },
  actions: {},
  getters: {
    obj: (state) => state.obj,
    list: (state) => state.list,
    nodeIndex: (state) => state.nodeIndex,
    nextId: (state) => state.nextId,
    prevId: (state) => state.prevId,
    parentId: (state) => state.parentId,
    parentName: (state) => state.parentName,
    parentSet: (state) => state.parentSet,
  },
};
