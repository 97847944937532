import ModelFactory from 'app/model/_factory';

class ProgressCourseModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-course',
      permType: 'progressCourse',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  override(id) {
    return this.execute('override', null, { id });
  }
}

export default new ProgressCourseModel();
