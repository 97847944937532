export default function (Router) {
  Router.abstract('class');

  Router.vueIndex('class');

  Router.vueCrud('class', 'classEvent', true);

  Router.vueCrud('class', 'classCalendar', true);

  Router.vueCrud('class', 'classDetail', true);

  Router.vueCrud('class', 'classStaff', true);

  Router.vueCrud('class', 'classLocation', true);

  Router.vueCrud('class', 'classAttendance', true);
}
