import ModelFactory from 'app/model/_factory';

/**
 * @deprecated
 */
class LevelTestModel extends ModelFactory {
  constructor() {
    super({
      type: 'level-test',
      permType: 'levelTest',
      isAgnostic: false,
      defaultList: {},
      so: {},
    });
  }

  getListByUser(userId) {
    return this.query({ userId });
  }

  getListByUserProgress(userProgressId) {
    return this.query({ userProgressId });
  }

  override(userId, levelTestId) {
    return this.execute('override', null, {
      userId,
      levelTestId,
    });
  }
}

export default new LevelTestModel();
