import _home from './_home';
import classes from './class';
import content from './content';
import learning from './learning';
import ops from './ops';
import platform from './platform';
import settings from './settings';
import user from './user';

export default function (Router, Permissions) {
  _home(Router);
  content(Router, Permissions);
  learning(Router, Permissions);
  ops(Router, Permissions);
  platform(Router);
  classes(Router, Permissions);
  settings(Router, Permissions);
  user(Router, Permissions);
  return Router;
}
