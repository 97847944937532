import ModelFactory from 'app/model/_factory';

class ProgressBlankitivityModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-blankitivity',
      permType: 'progressBlankitivity',
      isAgnostic: true,
      defaultList: {
        answer: '',
        gradePercent: 0,
      },
      so: {
        def: 'blankitivityId',
        keys: {
          title: ['t', 'title'],
          questionHtml: ['q', 'question'],
          explanation: ['e', 'explanation'],
        },
        cols: {
          title: 'Title',
          question: 'Question',
          explanation: 'Explanation',
        },
      },
    });
  }
}

export default new ProgressBlankitivityModel();
