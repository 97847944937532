<template>
  <VDialog v-model="visible" width="500" @click:outside="cancel">
    <VCard>
      <VToolbar :color="color" dark>
        <VToolbarTitle>
          {{ title }}
        </VToolbarTitle>
      </VToolbar>
      <div class="pa-5">
        {{ text }}
        <VContainer>
          <VRow no-gutters>
            <VCol class="mt-6">
              <div v-for="(p, index) in plans" :key="p.planId">
                <template v-if="p.planName">
                  <input :id="p.planId" v-model="planId" type="radio" :value="p.planId" :checked="index === 0" />
                  <label :for="p.planId"> {{ p.planName }} </label>
                  <br />
                </template>
              </div>
            </VCol>
            <VCol>
              <VContainer class="container--fluid">
                <VRow no-gutters>
                  <VCol class="col-12">
                    <input id="refund-chk" v-model="isRefundActive" type="checkbox" class="mt-6" />
                    <label for="refund-chk"> Give partial refund </label>
                  </VCol>
                </VRow>
              </VContainer>
            </VCol>
          </VRow>
        </VContainer>
      </div>
      <VCardActions>
        <VSpacer xs9 />
        <VBtn color="warning" text data-test="confirmButton" :disabled="!planId" @click.stop="confirm">
          {{ confirmText }}
        </VBtn>
        <VBtn color="readonly" text @click.stop="cancel">
          {{ cancelText }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script lang="ts">
export default {
  props: {
    visible: { type: Boolean, default: false },
    planId: { type: String, default: '' },
    title: { type: String, default: 'Please Confirm' },
    html: { type: String, default: '' },
    text: { type: String, default: 'Do you approve?' },
    confirmText: { type: String, default: 'Yes' },
    cancelText: { type: String, default: 'No, Cancel' },
    color: { type: String, default: 'warning' },
    plans: { type: Array, required: true },
    callback: { type: Function, default: () => undefined },
    isRefundActive: { type: Boolean, default: false },
  },
  methods: {
    confirm() {
      if (!this.planId) return;
      this.callback(this.planId, this.isRefundActive);
      this.visible = false;
      this.$destroy();
    },
    cancel() {
      this.visible = false;
      this.$destroy();
    },
  },
};
</script>
<style scoped></style>
