import ModelFactory from 'app/model/_factory';
import { IMAGE_URL_PREFIX } from 'app/lib/mediaConfig';
import Util from 'app/lib/util';

class QuestionModel extends ModelFactory {
  constructor() {
    super({
      type: 'question',
      isAgnostic: true,
      keys: {
        parent: 'chapter',
        child: '',
        childList: '',
      },
      defaultList: {
        key: '',
        template: 'QUESTION_TEMPLATE_7',
        question: '',
        explanation: '',
        answerList: [],
        choiceList: [
          { _id: Util.randomId(16), text: '', isCorrect: true, isSelected: false },
          { _id: Util.randomId(16), text: '', isCorrect: false, isSelected: false },
          { _id: Util.randomId(16), text: '', isCorrect: false, isSelected: false },
          { _id: Util.randomId(16), text: '', isCorrect: false, isSelected: false },
        ],
        imageUrl: IMAGE_URL_PREFIX,
        image2xUrl: IMAGE_URL_PREFIX,
        answerSeconds: 0,
        bloomType: '',
        answerCount: 0,
        sectionList: [],
        tagList: [],
        answerKey: '',
        correctKey: '',
        _id: '-1',
        altImageText: '',
        imageMeta: {},
        video: {},
        attemptCount: 1,
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          question: ['q', 'question'],
          'choiceList.text': ['a', 'answer', 'answers'],
          explanation: ['e', 'explanation'],
          sectionList: ['s', 'sections', 'sectionlist'],
          tagList: ['l', 'tags', 'taglist'],
          bloomType: ['b', 'bloom', 'bloomtype'],
          imageUrl: ['u', 'img', 'imageurl'],
          altImageText: ['x', 'alt', 'altimagetext'],
          objectiveId: ['o', 'objectiveId'],
        },
        cols: {
          key: 'Key',
          question: 'Question',
        },
      },
    });
  }

  get templateList() {
    return {
      QUESTION_TEMPLATE_1: {
        title: 'Four Grid',
        count: 4,
      },
      QUESTION_TEMPLATE_2: {
        title: 'True / False',
        count: 2,
      },
      QUESTION_TEMPLATE_3: {
        title: 'Four List',
        count: 4,
      },
      QUESTION_TEMPLATE_4: {
        title: 'Four + Image',
        count: 4,
      },
      QUESTION_TEMPLATE_5: {
        title: 'Three List',
        count: 3,
      },
      QUESTION_TEMPLATE_6: {
        title: 'Three + Image',
        count: 3,
      },
      QUESTION_TEMPLATE_7: {
        title: 'Full Text',
        count: 4,
      },
      QUESTION_CLICKABLE_IMAGE: {
        title: 'Clickable image',
        count: 4,
      },
      QUESTION_ORDERED_LIST: {
        title: 'Ordered list',
        count: 4,
      },
      QUESTION_VIDEO: {
        title: 'Video',
        count: 4,
      },
    };
  }
}

export default new QuestionModel();
