import Vue from 'vue';
import Vuex from 'vuex';
import content from 'app/store/modules/content';
import crud from 'app/store/modules/crud';
import profiler from 'app/store/modules/profiler';
import qa from 'app/store/modules/qa';
import version from 'app/store/modules/version';
import keyboard from 'app/store/modules/keyboard';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    content,
    crud,
    profiler,
    qa,
    version,
    keyboard,
  },
});
