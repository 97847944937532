import ModelFactory from 'app/model/_factory';
import { jsDialog } from '../plugins/dialog';

class ProgressCertificateModel extends ModelFactory {
  constructor() {
    super({
      type: 'progress-certificate',
      permType: 'progressCertificate',
      isAgnostic: true,
      defaultList: {},
      so: {},
    });
  }

  complete(id, template) {
    return this.execute('complete', id, {
      template,
    });
  }

  efile(id, template) {
    return this.execute('efile', id, {
      template,
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let res = re.test(String(email).toLowerCase());
    if (!email) {
      res = true;
    }
    return res;
  }

  email(id, template, manualEmail) {
    // check that it's a valid email
    if (this.validateEmail(manualEmail)) {
      return this.execute('email', id, {
        template,
        manualEmail,
      });
    }
    return jsDialog.alert({
      text: 'You did not enter a valid email address; no email will be sent.',
    });
  }

  generate(id, template, isBlock = false) {
    return this.execute('generate', id, {
      template,
      isBlock,
    });
  }

  getListByProgressId(progressId) {
    return this.query({ criteria: { progressId } });
  }

  override(userId, userCertificateProgressId) {
    return this.execute('override', null, {
      userId,
      userCertificateProgressId,
    });
  }

  sendUpload({ id, tempKey }) {
    return this.execute('send-upload', id, { tempKey });
  }

  validateBeforeTransfer(id) {
    return this.subquery('validate-before-transfer', id);
  }
}

export default new ProgressCertificateModel();
