import ModelFactory from 'app/model/_factory';

class VoucherModel extends ModelFactory {
  constructor() {
    super({
      type: 'voucher',
      isAgnostic: true,
      keys: {
        parent: 'voucherRequest',
      },
      defaultList: {},
      so: {
        // For performance reasons, regex searches are not allowed on Vouchers
        def: 'code',
        keys: {
          _id: ['i', 'id'],
          code: ['c', 'code'],
        },
        cols: {
          key: 'ID',
          code: 'Code',
        },
      },
    });
  }

  getStoreVouchers(storeId, usedVouchers = null, projection = {}, limit = 50, sort = null, skip = null) {
    return this.subquery('partner/stats', null, { storeId, usedVouchers, projection, limit, sort, skip });
  }

  getByTrackId({ trackId }) {
    return this.query({ criteria: { trackId } });
  }
}

export default new VoucherModel();
