import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default {
  build(brandId) {
    const validBrandId = brandId.toUpperCase();
    let light;
    const baseColors = {
      success: '#2BD0A0',
      error: '#EC5164',
      warning: '#faa020',
      textBright: '#3a3c3e',
      text: '#616365',
      textLight: '#898a8b',
      textDisabled: '#b0b1b2',
      readonly: '#777',
      info: '#12BDCD',
      white: '#fff',
      background: '#f5f5f5',
    };

    if (validBrandId === 'IDS') {
      light = {
        primary: '#01a7ce',
        lighten: '#7bd1e6',
        ...baseColors,
      };
    } else if (validBrandId === 'DEC') {
      light = {
        primary: '#e97c10',
        lighten: '#f4bd87',
        ...baseColors,
      };
    } else if (validBrandId === 'FLVS') {
      light = {
        primary: '#e97c10',
        lighten: '#f4bd87',
        ...baseColors,
      };
    } else if (validBrandId === 'ACE') {
      light = {
        primary: '#12bdcd',
        lighten: '#88dee6',
        ...baseColors,
      };
    } else if (validBrandId === 'AARP') {
      light = {
        primary: '#C23934',
        lighten: '#e19c9a',
        ...baseColors,
      };
    } else {
      light = {
        primary: '#12bdcd',
        lighten: '#777',
        ...baseColors,
      };
    }

    return new Vuetify({
      theme: {
        themes: { light },
        options: {
          customProperties: true,
        },
      },
      icons: {
        iconfont: 'fa',
      },
    });
  },
};
