import ModelFactory from 'app/model/_factory';

class ChapterModel extends ModelFactory {
  constructor() {
    super({
      type: 'chapter',
      keys: {
        parent: 'level',
        child: 'node',
        childList: 'nodeList',
      },
      isAgnostic: true,
      defaultList: {
        template: 'SCREEN_TEMPLATE_1',
        title: '',
        key: '',
        screenSeconds: 0,
        imageUrl: '',
        image2xUrl: '',
        videoUrl: '',
        fileUrl: '',
        questionList: [],
        nodeList: [],
        hydratedNodeList: [],

        // Search
        context: '',
        tagList: [],
        metadata: { nodeCount: 0, wordCount: 0, fixedEducationalSeconds: 0, updatedAt: null, goalSeconds: 0 },
      },
      so: {
        def: 'key',
        keys: {
          _id: ['i', 'id'],
          key: ['k', 'key'],
          title: ['t', 'title'],
          context: ['c', 'context'],
          tagList: ['l', 'tags', 'taglist'],
          isOrphaned: ['o', 'orphaned'],
        },
        defValues: {
          isOrphaned: '0',
        },
        cols: {
          '#': '#',
          _id: 'ID',
          key: 'Key',
          title: 'Title',
        },
      },
    });
  }
}

export default new ChapterModel();
