import ModelFactory from 'app/model/_factory';
import { jsDialog } from '../plugins/dialog';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let res = re.test(String(email).toLowerCase());
  if (!email) {
    res = true;
  }
  return res;
}

class StudentCertificateModel extends ModelFactory {
  constructor() {
    super({
      type: 'student-certificate',
      permType: 'studentCertificate',
      isAgnostic: false,
      defaultList: {},

      so: {},
    });
  }

  email(certificateId, userId, type, manualEmail) {
    // check that it's a valid email
    if (validateEmail(manualEmail)) {
      return this.execute('email', certificateId, {
        userId,
        type,
        manualEmail,
      });
    }
    return jsDialog.alert({
      text: 'You did not enter a valid email address; no email will be sent.',
    });
  }
}

export default new StudentCertificateModel();
