<template>
  <AceCard :title="'Answerability Linked ' + label + 's'">
    <div slot="card-actions">
      <VBtn icon class="ma-0" text color="primary" @click="Util.link(self)">
        <VIcon aria-label="Replace with Clone" medium> fa fa-link </VIcon>
      </VBtn>
    </div>
    <div slot="card-content">
      <VDataTable
        v-if="isMounted"
        :id="'table-' + label.toLowerCase().replace(/\s+/g, '')"
        :headers="headers"
        :items="list"
        :items-per-page="500"
        hide-default-header
        hide-default-footer
        item-key="id"
      >
        <template slot="header" slot-scope="{}">
          <tr>
            <th v-for="header in headers" :key="header.value" class="text-left ace-header">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="item" slot-scope="{ index, item }">
          <tr :class="{ 'font-weight-bold': !!answerabilityOf, 'row-with-border-top': !!answerabilityOf }">
            <td v-for="(tdObj, idx) in headers" :key="idx" class="text-left" :class="{ emoji: tdObj.filter && tdObj.filter !== 'length' }">
              <div
                v-if="tdObj.value === answerabilityAttribute"
                class="item-id"
                :class="{ 'font-lighter': !!answerabilityOf }"
                @click="Util.copyIdLink(Util.getValue(item, answerabilityAttribute, tdObj.customFilter, index), $dialog.toast)"
                data-test="linkedId"
              >
                {{ Util.getValue(item, tdObj.value, tdObj.customFilter, index) }}
              </div>
              <div
                v-else
                class="ace-table-data"
                :class="{ 'two-lines-max': tdObj.value === 'page.html' }"
                :data-test="tdObj.value === 'page.html' || tdObj.value === 'question.question' ? 'linkedBodyText' : 'linkedIndex'"
              >
                {{ Util.getValue(item, tdObj.value, tdObj.customFilter, index) }}
              </div>
            </td>
            <td>
              <div class="justify-end layout">
                <VBtn icon class="ma-0" text color="primary" :to="Util.goto(item[answerabilityAttribute], 'edit', state, parentSet, parentType)" :target="answerabilityOf && '_blank'">
                  <VIcon aria-label="Edit" medium> fas fa-eye </VIcon>
                </VBtn>
                <VBtn icon class="ma-0" text color="warning" @click="Util.unlink($event, list[index][answerabilityAttribute], self)">
                  <VIcon aria-label="Unlink" medium> fa fa-unlink </VIcon>
                </VBtn>
              </div>
            </td>
          </tr>
          <tr v-for="course in item.courseList" :key="course._id + item.pageId + item.questionId">
            <td colspan="1"></td>
            <td colspan="3" data-test="linkedCourseTitle">{{ course.title }}</td>
          </tr>
        </template>
      </VDataTable>
    </div>
  </AceCard>
</template>

<script lang="ts">
import Model from 'app/model/index';
import Util from 'app/lib/util';
import AceCard from 'app/components/layout/ace-card.vue';

export default {
  components: {
    AceCard,
  },
  props: {
    state: { type: String, default: '' },
    label: { type: String, default: '' },
    model: { type: Object, default: () => undefined },
    headers: { type: Array, default: () => [] },
    parent: { type: String, default: '' },
    parentSet: { type: String, default: '' },
    parentKey: { type: String, default: '' },
    parentType: { type: String, default: '' },
    childListKey: { type: String, default: '' },
    obj: { type: Object, default: () => undefined },
    onUpdate: { type: Function, default: () => undefined },
    answerabilityOf: { type: String, default: '' },
    autoSave: { type: Boolean, default: false },
    refreshList: { type: Number, default: 0 },
    linkable: { type: Boolean, default: true },
  },

  data() {
    return {
      Util,
      answerabilityModel: Model.Answerability,
      pageModel: Model.Page,
      questionModel: Model.Question,
      list: [],
      isMounted: false,
      self: this,
    };
  },

  computed: {
    answerabilityAttribute() {
      return this.answerabilityOf === 'PAGE' ? 'questionId' : 'pageId';
    },
  },

  watch: {
    refreshList() {
      this.fillList();
    },
  },

  async mounted() {
    await this.fillList();
    this.isMounted = true;
  },

  methods: {
    async fillList() {
      // We get the answerabilities of DB
      this.list = await this.answerabilityModel.get({ nodeId: this.obj._id, nodeType: this.answerabilityOf });
      const orphanNodesCount = this.list.filter((item) => !item.courseList).length;
      if (orphanNodesCount) {
        this.list = this.list.filter((item) => item.courseList);
        this.obj[this.answerabilityOf === 'PAGE' ? 'linkedQuestions' : 'linkedPages'] = this.list.map((answ) => answ[this.answerabilityAttribute]);
        await this[this.answerabilityOf === 'PAGE' ? 'pageModel' : 'questionModel'].update(this.obj._id, this.obj);
        await this.$dialog.toast({ text: `You can't link orphaned nodes` });
      } else {
        this.obj[this.answerabilityOf === 'PAGE' ? 'linkedQuestions' : 'linkedPages'] = this.list.map((answ) => answ[this.answerabilityAttribute]);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.row-with-border-top td {
  border-top: 1px solid black;
}
table tr th {
  font-size: 1rem;

  &:not(:first-child) {
    padding: 0 1rem 0 0;
  }
}
table tbody tr td:not(:first-child) {
  padding: 0 1rem 0 0;
}

.ace-header,
.ace-data {
  font-size: 14px;
}
.ace-table-data {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.item-id {
  color: var(--v-primary-base);
  cursor: pointer;
  user-select: all;
  &.font-lighter {
    font-size: 9px;
    font-weight: lighter;
  }
}
.emoji {
  font-size: 1rem;
}
.two-lines-max {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
</style>
