import engine from 'store/src/store-engine';
import storages from 'store/storages/sessionStorage';
import json2 from 'store/plugins/json2';

// Intentionally building a Session only Storage so brand only persists per tab
// Admin authorization and everything else is saved in Local Storage as persistant across tabs
const SessionStorage = engine.createStore(storages, [json2]);

const BrandLib = {
  brandId: '',

  setCurrentTabBrandId(brandId = '') {
    const sanitizedBrandId = brandId.toUpperCase().trim();
    BrandLib.brandId = sanitizedBrandId;
    SessionStorage.set('ace.brandId', sanitizedBrandId);
    return sanitizedBrandId;
  },

  getCurrentTabBrandId() {
    return SessionStorage.get('ace.brandId') || '';
  },

  build(Auth) {
    // This is called during main.ts on every force refresh
    // It attempts to find the brand in the URL then check against Auth for access
    // Sets URLs to be jarvishq.com/@ace/content/course/list where "@ace" is the brand
    // gets the proper brandId info to setup logos, themes, titles, api access
    // the only way a brandId should change is through a refresh on sign in, reset, or brand switch
    let brandId = '';
    if (Auth.isAuthenticated()) {
      const path = window.location.pathname;
      brandId = path.split('/')[1].substring(1).toUpperCase().trim();

      const admin = Auth.get() || {};
      const brandIdList = admin.brandIdList || [];
      if (!brandIdList.includes(brandId)) {
        // The order of operations here is very important
        // We first get the admin data then get the session brand (only in THIS chrome tab)
        // For legacy support we try the two ways to get the auth brand then validate the two
        const localTabBrandId = BrandLib.getCurrentTabBrandId();
        const authSessionBrandId = admin.brandId || admin.defBrandId;
        const currentBrandId = localTabBrandId || authSessionBrandId;
        const isValidBrandId = currentBrandId && admin.brandIdList && admin.brandIdList.includes(currentBrandId);
        brandId = (isValidBrandId ? currentBrandId : 'ACE').toLowerCase();
      }
    }

    return brandId;
  },

  setBrandUrl(brandId, route, path, isSignIn = false) {
    const formattedBrandId = brandId.toLowerCase();
    let newPath = path || route.fullPath || '';

    if (!isSignIn && newPath.includes('edit')) {
      // When dealing specifically with brand switching (not sign in)
      // If on an edit page, try to redirect back to the list parent above it
      newPath = `${newPath.substr(0, newPath.indexOf('edit'))}list`;
    } else if (!newPath || (!path && route.params && route.params.id)) {
      // If we could not get a newPath from override or default route
      // Or there is no override, but we are on a specific object edit page
      // Then we need to redirect back to dashboard
      newPath = '/_home/dashboard';
    }

    window.location.assign(`/@${formattedBrandId}${newPath}`);
  },
};

export default BrandLib;
